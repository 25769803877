<template>
  <div>
    <b-modal id="copymodal" title="Copy pelatihan">
      <b-form-group label="Title pelatihan:" label-for="titlepelatihan">
        <b-form-input id="titlepelatihan" v-model="copyTitle" placeholder="Title" v-validate="'required'"
          :state="validateState('title')"></b-form-input>
        <b-form-invalid-feedback>
          Title pelatihan wajib diisi.
        </b-form-invalid-feedback>
      </b-form-group>
      <template #modal-footer>
        <div class="float-right">
          <b-button @click="$bvModal.hide('copymodal')" variant="secondary" class="mr-2">
            Cancel
          </b-button>
          <b-button variant="success" @click="doCopy">
            <b-spinner v-if="isLoadingCopy" small label="Spinning" class="mx-auto text-white"></b-spinner>
            <span v-else class="align-middle ml-2">Confirm</span>
          </b-button>
        </div>
      </template>
    </b-modal>
    <BannerSmall title="Manajemen Pelatihan" :button="isAdmin" buttonText="Arsip" location="/courses/archive"
      backButton="true" backButtonTitle="Halaman Utama" backButtonHref="/" />

    <div class="bg-primary">
      <b-container class="py-5">
        <div class="row mb-5">
          <div class="col-6 text-left">
            <b-button variant="light" size="lg" class="text-center font-open-sans font-weight-bold"
              v-b-modal.createCourseModal>
              Buat Pelatihan Baru
              <font-awesome-icon icon="plus" class="ml-2"></font-awesome-icon>
            </b-button>
          </div>
          <div class="col-6 text-right">
            <b-button v-if="isAdmin" variant="light" size="lg" to="/categories"
              class="text-center font-open-sans font-weight-bold">
              Kelola Kategori Pelatihan
            </b-button>
          </div>
        </div>
        <b-spinner v-if="loading" label="Spinning" class="d-block mx-auto text-white"></b-spinner>

        <h4 v-else-if="emptyResults" class="text-white">
          Belum ada pelatihan pada portal.
        </h4>

        <div v-else>
          <div class="row mb-4">
            <div class="col-1">
              <b-form-select v-model="perPage" @change="toTheCoursePage" :options="perPageOptions"></b-form-select>
            </div>
            <div class="col-4 offset-7 text-right">
              <b-input-group class="w-100">
                <b-form-input type="search" v-model="search" @input="debounceInput" placeholder="Cari ..." />
              </b-input-group>
            </div>
          </div>
          <b-row align-v="stretch">
            <b-col cols="6" sm="6" md="4" lg="3" v-for="(course, index) in results" :key="index" class="mb-5">
              <b-card :img-src="require('@/assets/img/lppi-simple-thumbnail.png')" img-alt="Image" img-top tag="article"
                style="max-width: 17rem;" class="h-100 font-open-sans position-relative">
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret right class="position-absolute"
                  style="top: 10px; right: 10px">
                  <template #button-content>
                    <font-awesome-icon icon="ellipsis-v"></font-awesome-icon>
                  </template>
                  <b-dropdown-item @click="editCourse(course)" class="text-right">
                    Edit Pelatihan
                    <font-awesome-icon icon="edit" class="text-secondary ml-1"></font-awesome-icon>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="isAdmin" @click="archiveCourse(course)" class="text-right">
                    Arsip Pelatihan
                    <font-awesome-icon icon="trash" class="text-danger ml-1"></font-awesome-icon>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="isAdmin" @click="copyCourse(course)" class="text-right">
                    Copy Pelatihan
                    <font-awesome-icon icon="file" class="text-secondary ml-1"></font-awesome-icon>
                  </b-dropdown-item>
                </b-dropdown>
                <b-badge variant="primary" v-if="course.private" class="mr-3">Private</b-badge>
                <b-card-text class="font-weight-bold">
                  <p class="mb-1">{{ course.title }}</p>
                  <span class="badge-course" v-if="categoryName(course)">{{ categoryName(course) }}</span>
                </b-card-text>
                <div class="d-flex align-items-center mb-2" v-for="(teacher, index) in course.teachers.slice(0, 2)"
                  :key="index">
                  <font-awesome-icon icon="user-circle" size="lg" class="mr-2"></font-awesome-icon>
                  <span class="small text-left">{{ teacher.firstname }} {{ teacher.lastname }}</span>
                </div>
                <template #footer>
                  <b-button @click="viewCourse(course)" variant="outline-primary">Lihat Kelas</b-button>
                </template>
              </b-card>
            </b-col>
          </b-row>
          <div class="row">
            <b-col cols="12">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @page-click="toTheCourse"
                aria-controls="my-table" class="my-auto float-right"></b-pagination>
            </b-col>
          </div>
        </div>
      </b-container>
    </div>

    <createCourseModal :categories="categories" @refresh-courses="getCourses" />
    <editCourseModal :categories="categories" :courseData="courseData" @refresh-courses="getCourses" />
    <deleteCourseModal :courseData="courseData" @refresh-courses="getCourses" />
  </div>
</template>
<style scoped>
.badge-course {
  color: #fff;
  background-color: #6c757d;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
<script>
import _ from "lodash"
import BannerSmall from "@/components/BannerSmall.vue";
import CreateCourseModal from "@/components/courses/CreateCourseModal.vue";
import EditCourseModal from "@/components/courses/EditCourseModal.vue";
import DeleteCourseModal from "@/components/courses/DeleteCourseModal.vue";

export default {
  components: {
    BannerSmall,
    CreateCourseModal,
    EditCourseModal,
    DeleteCourseModal,
  },

  data() {
    return {
      isLoadingCopy: false,
      selectedId: null,
      copyTitle: '',
      results: [],
      courses: [],
      categories: [{ value: "", text: "Tanpa Kategori" }],
      courseData: {},
      search: "",
      loading: false,
      emptyResults: false,
      perPageOptions: [8, 15, 20, 30],
      currentPage: 1,
      totalRows: 0,
      rows: null,
      perPage: 8
    };
  },

  computed: {
    fuseOptions() {
      return {
        keys: [
          {
            name: "title",
            weight: 2,
          },
          "category.name",
        ],
        minMatchCharLength: 2
      };
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },

  mounted() {
    this.getCourses();
    this.categoriesList();
    window.scrollTo(0, 0);
  },

  methods: {
    debounceInput: _.debounce(function () {
      this.getCourses()
    }, 500),
    toTheCoursePage(e) {
      this.perPage = e
      this.getCourses();
    },
    toTheCourse(e, page) {
      this.currentPage = page
      this.getCourses()
    },
    async doCopy() {
      try {
        this.isLoadingCopy = true
        const res = await this.axios.post(
          process.env.VUE_APP_API_URL + '/api/course/copy',
          {
            course_id: this.selectedId,
            title: this.copyTitle
          }
        );
        this.$bvModal.hide('copymodal')
        if (res.status == 200) {
          this.$bvToast.toast(res.data.message, {
            title: res.data.success ? "Success" : "Danger",
            autoHideDelay: 5000,
            variant: res.data.success ? "success" : "danger"
          });
        }
        this.isLoadingCopy = false
      } catch (error) {
        this.isLoadingCopy = false
        console.log(error)
      }
    },
    copyCourse(item) {
      this.selectedId = item.id
      this.copyTitle = item.title
      this.$bvModal.show('copymodal')
    },
    handleResults(r) {
      this.results = r;
    },

    viewCourse(course) {
      this.$router.push({
        name: "CoursePage",
        params: {
          id: course.id,
        },
      });
    },

    categoryName(course) {
      return course?.category?.name;
    },

    editCourse(course) {
      this.courseData = course;
      this.courseData.privasi = course.private ? true : false
      this.$bvModal.show("editCourseModal");
    },

    archiveCourse(course) {
      this.courseData = course;
      this.$bvModal.show("deleteCourseModal");
    },

    categoriesList() {
      const categories = this.$store.state.courseCategories;

      categories.forEach((category) => {
        this.categories.push({ value: category.code, text: category.name });
      });
    },

    async getCourses() {
      this.loading = true;
      try {
        const endpointURL = this.isAdmin
          ? "/api/course/catalog/ssp"
          : "/api/course/mine/ssp";
        const response = await this.axios.get(
          `${process.env.VUE_APP_API_URL}${endpointURL}?page=${this.currentPage}&pageLength=${this.perPage}&search=${this.search}`
        );
        const data = response.data;

        if (data.success) {
          this.emptyResults = false;

          if (!this.isAdmin) {
            this.courses = data.courses.filter(
              (course) => course.status == "admin"
            );
          } else {
            this.totalRows = data.total
            this.courses = data.courses;
            this.results = data.courses
          }
        } else {
          this.emptyResults = true;
        }
      } catch (errors) {
        console.error(errors);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
