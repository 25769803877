<template>
  <b-modal id="delete-assessment-modal" title="Hapus Item Penilaian">
    <div class="text-center">
      <p>
        Apakah Anda yakin untuk menghapus item
        <span class="h5 d-block font-weight-bold my-1">{{
          assessmentItem.name
        }}</span>
        dari penilaian?
      </p>
      <p class="mb-0">
        Jumlah Bobot penilaian akan menjadi:
        <span class="font-weight-bold">
          {{ computedWeight - assessmentItem.weight }}
        </span>
      </p>
    </div>
    <template #modal-footer>
      <b-button
        variant="danger"
        @click="deleteAssessmentItem"
        class="float-right"
        ><b-spinner small v-if="loading"></b-spinner
        ><span v-else>Hapus</span></b-button
      >
    </template>
  </b-modal>
</template>

<script>
export default {
  props: ["assessmentItem", "computedWeight"],

  data() {
    return {
      loading: false,
      toastCount: 0,
    };
  },

  computed: {
    isEvaluationItem() {
      if (this.assessmentItem.type == "E") return true;
      return false;
    },
  },

  methods: {
    async deleteAssessmentItem() {
      this.loading = true;

      const endpoint = this.isEvaluationItem
        ? "/api/assesment/delete/evaluation"
        : "/api/assesment/delete/link/";

      const itemKey = this.isEvaluationItem ? "evaluation_id" : "link_id";

      const requestBody = {
        course_id: Number(this.$route.params.id),
      };

      requestBody[itemKey] = Number(this.assessmentItem.id);

      try {
        const response = await this.axios.delete(
          process.env.VUE_APP_API_URL + endpoint,
          {
            data: requestBody,
          }
        );

        const data = response.data;
        const status = data.success ? "Success" : "Failed";
        const variant = data.success ? "success" : "danger";
        const message = data.success
          ? "Penilaian berhasil dihapus."
          : "Penilaian gagal dihapus.";

        this.loading = false;
        this.$bvModal.hide("delete-assessment-modal");

        // Show Toast
        this.toastCount++;
        this.$bvToast.toast(message, {
          title: status,
          autoHideDelay: 5000,
          variant: variant,
        });

        if (data.success) {
          // Refresh table
          this.$emit("reload-assessments");
        }
      } catch (errors) {
        console.error(errors);
      }
    },
  },
};
</script>
