<template lang="">
  <div>
    <BannerSmall
      :title="courseTitle"
      backButton="true"
      backButtonTitle="Daftar Settings"
      backButtonHref="/"
    />
    <div class="bg-primary">
      <b-container class="py-5">
        <b-row no-gutters>
          <b-col cols="12" sm="12" md="12" lg="3">
            <b-list-group style="border-radius: 5px 0 0 5px !important">
              <b-list-group-item
                v-for="nav in sidebarNavs"
                :key="nav.text"
                :to="toLink(nav.to)"
                active-class="active"
                exact
                class="border border-right-0 border-white course-sidenav"
              >
                {{ nav.text }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="12" sm="12" md="12" lg="9" class="bg-white p-4 rounded-right rounded-bottom text-left">
            <router-view />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import BannerSmall from "@/components/BannerSmall.vue";
export default {
  components: { BannerSmall },
  props: ["courseId"],
  data() {
    return {
      courseTitle: "Manajement Setting",
      sidebarNavs: [
        // {
        //   text: "Banner",
        //   to: "Banner",
        // },
        // {
        //   text: "Testimoni",
        //   to: "Testimoni",
        // },
        {
          text: "Divisi",
          to: "Divisi",
        },
        {
          text: "Chart",
          to: "DataStudio",
        },
      ],
    };
  },

  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  methods: {
    toLink(link) {
      return {
        name: link,
        params: { id: this.courseId },
      };
    },
  },
};
</script>
