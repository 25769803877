<template>
  <b-container>
    <!--  -->
    <div class="d-flex w-100 justify-content-between align-items-center mb-3">
      <h4 class="font-open-sans-bold">Daftar Calon Peserta</h4>
      <h5 v-if="!loading" class="font-open-sans-bold mr-4">
        <ICountUp :endVal="totalRows" />
        Peserta
      </h5>
    </div>

    <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>

    <div v-else-if="emptyResults">
      Belum ada peserta yang mendaftar
    </div>

    <div v-else>
      <b-row class="w-100 justify-content-between ">
        <!-- Filter / Search -->
        <b-col cols="5">
          <b-form-group class="mb-3">
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Cari Peserta"
              ></b-form-input>

              <b-input-group-append>
                <b-button variant="primary" @click="filter = ''">
                  <font-awesome-icon icon="search" class="text-white" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- Add Student -->
        <b-col cols="5" class="text-right mb-3">
          <b-button size="" variant="primary" class="" @click="getStudents">
            <font-awesome-icon icon="sync"></font-awesome-icon>
          </b-button>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table
        striped
        responsive
        :fields="fields"
        :items="students"
        :total-rows="totalRows"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        class="font-roboto"
      >
        <!-- Select All -->
        <template #head(selected)>
          <b-form-checkbox
            v-model="allSelected"
            @change="toggleAll"
            :indeterminate="indeterminate"
          ></b-form-checkbox>
        </template>
        <!-- Checkbox -->
        <template #cell(selected)="row">
          <b-form-checkbox
            v-model="selected"
            :value="row.item"
          ></b-form-checkbox>
        </template>

        <template #cell(index)="row">
          {{ row.index + 1 }}
        </template>

        <template #cell(firstname)="{item}">
          {{ item.firstname }} {{ item.lastname }}
        </template>
        <!-- Edit and Delete Buttons -->
        <template #cell(actions)="row">
          <b-link
            v-b-tooltip.hover
            title="Setujui Pendaftaran"
            class="text-secondary"
            @click="approveStudent(row.item)"
          >
            <font-awesome-icon icon="check" class="mr-3" />
          </b-link>
          <b-link
            v-b-tooltip.hover
            title="Batalkan Pendaftaran"
            class="text-secondary"
            @click="disapproveStudent(row.item)"
          >
            <font-awesome-icon icon="trash" class="" />
          </b-link>
        </template>
      </b-table>
      <b-row>
        <b-col cols="3">
          <b-button
            variant="primary"
            @click="batchApprove"
            :disabled="!indeterminate && !allSelected"
          >
            <b-spinner small v-if="buttonLoading"></b-spinner>
            <span v-else>Setujui Massal</span>
          </b-button>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="6"
            label-cols-lg="6"
            label-align-sm="right"
            class="flex-grow-1 mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="6" class="text-right">
          <b-pagination
            class="ml-auto"
            align="right"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
    <approveStudentModal
      title="Setujui Pendaftaran Peserta"
      :userData="userData"
      @reload-table="getStudents"
    />
    <disapproveStudentModal
      title="Batalkan Pendaftaran Peserta"
      :userData="userData"
      @reload-table="getStudents"
    />
  </b-container>
</template>

<script>
import ApproveStudentModal from "@/components/courses/members/ApproveStudentModal.vue";
import DisapproveStudentModal from "@/components/courses/members/DisapproveStudentModal.vue";

export default {
  components: {
    ApproveStudentModal,
    DisapproveStudentModal,
  },

  data() {
    return {
      filePeserta: null,
      value: "",
      students: [],
      fields: [
        "selected",
        {
          key: "no",
          label: "No.",
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "firstname",
          label: "Nama Lengkap",
          sortable: true,
        },
        "actions",
      ],
      selected: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      userData: {},
      loading: false,
      buttonLoading: false,
      emptyResults: false,
      allSelected: false,
      indeterminate: false,
    };
  },

  computed: {
    courseId() {
      return parseInt(this.$route.params.id);
    },
  },

  mounted() {
    this.getStudents();
  },

  watch: {
    $route() {
      this.getStudents();
    },

    selected(newValue) {
      if (newValue.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newValue.length === this.students.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },
  },

  methods: {
    toggleAll(checked) {
      this.selected = checked ? this.students : [];
    },

    approveStudent(user) {
      this.userData = user;
      this.$bvModal.show("approveStudentModal");
    },

    disapproveStudent(user) {
      this.userData = user;
      this.$bvModal.show("disapproveStudentModal");
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async getStudents() {
      this.loading = true;

      // Empty table before fetching new data
      this.students = [];

      const requestBody = {
        course_id: this.courseId,
      };

      try {
        const response = await this.axios.post(
          process.env.VUE_APP_API_URL + "/api/course/student/unapproved",
          requestBody
        );

        if (response.data.success) {
          this.emptyResults = false;

          const data = response.data.students
          data.forEach((student,idx) => {
            student.user.no = idx+1
            this.students.push(student.user);
          });
          this.totalRows = this.students.length;
        } else {
          this.emptyResults = true;
          this.totalRows = 0;
        }
      } catch (errors) {
        console.error(errors);
      } finally {
        this.indeterminate = false;
        this.loading = false;
      }
    },

    async batchApprove() {
      this.buttonLoading = true;

      const studentIds = this.selected.map((student) => student.id);

      const requestBody = {
        course_id: this.courseId,
        students: studentIds,
      };

      try {
        const response = await this.axios.post(
          process.env.VUE_APP_API_URL + "/api/course/student/approve/batch",
          requestBody
        );

        const data = response.data;
        const status = data.success ? "Success" : "Failed";
        const variant = data.success ? "success" : "danger";
        const message = data.message;

        // Show Toast
        this.toastCount++;
        this.$bvToast.toast(message, {
          title: status,
          autoHideDelay: 5000,
          variant: variant,
        });

        this.getStudents();
      } catch (errors) {
        console.error(errors);
      } finally {
        this.buttonLoading = false;
      }
    },
  },
};
</script>
