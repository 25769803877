<template>
  <div>
    <BannerSmall title="Manajemen Keterampilan" backButton="true" backButtonTitle="Halaman Utama" backButtonHref="/" />
    <div class="bg-primary">
      <b-container class="py-5">
        <b-row>
          <!-- <b-col cols="12" md="6" lg="6" class="my-2">
            <b-input-group>
              <VueFuse
                :list="skills"
                :fuse-opts="fuseOptions"
                :search="search"
                :map-results="false"
                placeholder="Cari Keterampilan..."
                class="form-control form-control-lg"
                @fuse-results="handleResults"
              />
              <template #append>
                <b-input-group-text
                  ><font-awesome-icon
                    icon="search"
                    variant="primary"
                  ></font-awesome-icon>
                </b-input-group-text>
              </template>
            </b-input-group>
          </b-col> -->
          <div class="col-1">
            <b-form-select v-model="perPage" @change="toTheCoursePage" :options="perPageOptions"></b-form-select>
          </div>
          <div class="col-5">
            <b-input-group class="w-100">
              <b-form-input type="search" v-model="search" @input="debounceInput" placeholder="Cari ..." />
            </b-input-group>
          </div>
          <b-col cols="6" sm="6" lg="6" class="text-lg-right text-left">
            <b-button variant="light" size="lg" class="text-center font-open-sans font-weight-bold mb-5"
              v-b-modal.create-skill-modal>
              Buat Keterampilan Baru
              <font-awesome-icon icon="plus" class="ml-2"></font-awesome-icon>
            </b-button>
          </b-col>
        </b-row>

        <b-spinner v-if="loading" label="Spinning" class="d-block mx-auto text-white"></b-spinner>

        <div v-else-if="emptyResults">
          Belum ada keterampilan yang dibuat.
        </div>

        <div v-else>
          <b-row align-v="stretch">
            <b-col cols="6" sm="6" md="4" lg="3" v-for="(item, index) in skills" :key="index" class="mb-5">
              <SkillCard :skill="item" :disable-actions="!isAdmin" @show-skill="showSkill" @edit-skill="editSkill"
                @delete-skill="deleteSkill" />
            </b-col>
          </b-row>
          <div class="row">
            <b-col cols="12">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @page-click="toTheCourse"
                aria-controls="my-table" class="my-auto float-right"></b-pagination>
            </b-col>
          </div>
        </div>
      </b-container>
    </div>
    <SkillModal :skill="skillData" />
    <CreateSkillModal @refresh-skills="getSkills" />
    <EditSkillModal :skillData="skillData" @refresh-skills="getSkills" />
    <DeleteSkillModal :skillData="skillData" @refresh-skills="getSkills" />
  </div>
</template>

<script>
import _ from "lodash"
import BannerSmall from "@/components/BannerSmall.vue";
import SkillCard from "@/components/skills/SkillCard.vue";
import CreateSkillModal from "@/components/skills/CreateSkillModal.vue";
import EditSkillModal from "@/components/skills/EditSkillModal.vue";
import DeleteSkillModal from "@/components/skills/DeleteSkillModal.vue";
import SkillModal from "@/components/skills/SkillModal.vue";

export default {
  components: {
    BannerSmall,
    SkillCard,
    CreateSkillModal,
    EditSkillModal,
    DeleteSkillModal,
    SkillModal,
  },

  data() {
    return {
      skills: [],
      skillData: {},
      results: [],
      search: "",
      emptyResults: false,
      loading: false,
      perPageOptions: [8, 15, 20, 30],
      currentPage: 1,
      totalRows: 0,
      rows: null,
      perPage: 8
    };
  },

  computed: {
    fuseOptions() {
      return {
        keys: [
          {
            name: "name",
            weight: 2,
          },
          "category.name",
        ],
        minMatchCharLength: 2,
        // includeMatches: true,
        // threshold: 0.5,
      };
    },

    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },

  mounted() {
    this.getSkills();
    window.scrollTo(0, 0);
  },

  methods: {
    debounceInput: _.debounce(function () {
      this.getSkills()
    }, 500),
    toTheCoursePage(e) {
      this.perPage = e
      this.getSkills();
    },
    toTheCourse(e, page) {
      this.currentPage = page
      this.getSkills()
    },

    showSkill(skill) {
      this.skillData = skill;
      this.$bvModal.show("skill-modal");
    },

    editSkill(skill) {
      this.skillData = skill;
      this.$bvModal.show("edit-skill-modal");
    },

    deleteSkill(skill) {
      this.skillData = skill;
      this.$bvModal.show("delete-skill-modal");
    },

    async getSkills() {
      this.loading = true;

      try {
        const response = await this.axios.get(
          `${process.env.VUE_APP_API_URL}/api/skills/ssp?page=${this.currentPage}&pageLength=${this.perPage}&search=${this.search}`
        );

        const data = response.data;


        if (data.skills.length > 0) {
          this.skills = data.skills;
          this.totalRows = data.total;
          this.emptyResults = false;
        } else {
          this.emptyResults = true;
        }
      } catch (errors) {
        console.error(errors);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
