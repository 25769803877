<template>
  <div>
    <b-img src="@/assets/img/banner_new.jpg" alt="banner" fluid />
    <!-- <div>
      <b-carousel
        style="text-shadow: 0px 0px 2px #000"
        fade
        :interval="5000"
        indicators
      >
        <b-carousel-slide
          style="height:338px;"
          v-for="(item, idx) in bannerIn"
          :key="idx"
          :img-src="item.url_banner"
        ></b-carousel-slide>
      </b-carousel>
    </div> -->
    <Navigation />
  </div>
</template>

<script>
import Navigation from "@/components/home/Navigation.vue";

export default {
  name: "Home",
  components: {
    Navigation,
  },
  data() {
    return {
      bannerIn :[]
    }
  },
  async mounted() {
    try {
      const res = await this.axios.get(process.env.VUE_APP_API_URL +`/api/banner/display`);
      this.bannerIn = res.data
    } catch (e) {
      console.log(e)
    }
  },
};
</script>
