<template>
  <b-tabs content-class="mt-3">
    <b-tab title="Keterampilan" lazy>
      <CourseSkills :courseId="courseId" />
    </b-tab>
    <b-tab title="Skema Penilaian" active lazy>
      <Assessments :courseId="courseId" />
    </b-tab>
    <b-tab title="Daftar Nilai" lazy><Grades :courseId="courseId"/></b-tab>
    <b-tab title="Daftar Sertifikat" lazy
      ><PublishedCertificates :courseId="courseId"
    /></b-tab>
    <b-tab title="Template Sertifikat" lazy
      ><Certificates :courseId="courseId"
    /></b-tab>
  </b-tabs>
</template>

<script>
import Certificates from "@/components/courses/certificates/Index.vue";
import PublishedCertificates from "@/components/courses/certificates/PublishedCertificates.vue";
import Grades from "@/components/courses/certificates/Grades.vue";
import Assessments from "@/components/courses/certificates/Assessments.vue";
import CourseSkills from "@/components/courses/certificates/CourseSkills.vue";

export default {
  components: {
    Certificates,
    PublishedCertificates,
    Grades,
    Assessments,
    CourseSkills,
  },

  props: ["courseId"],

  data() {
    return {
      courseTitle: "",
      courseNotFound: true,
    };
  },

  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },

  methods: {
    //
  },
};
</script>
