<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab title="Calon Peserta" active lazy><EnrollingStudents /></b-tab>
      <b-tab title="Peserta" lazy><CourseStudents /></b-tab>
      <b-tab title="Pengajar" lazy><CourseTeachers /></b-tab>
      <b-tab v-if="isAdmin" title="Course Leader (CL)" lazy
        ><CourseLeaders
      /></b-tab>
    </b-tabs>
  </div>
</template>

<script>
import CourseTeachers from "@/components/courses/members/CourseTeachers.vue";
import CourseStudents from "@/components/courses/members/CourseStudents.vue";
import CourseLeaders from "@/components/courses/members/CourseLeaders.vue";
import EnrollingStudents from "@/components/courses/members/EnrollingStudents.vue";

export default {
  name: "ManageMembers",
  components: {
    CourseTeachers,
    CourseStudents,
    CourseLeaders,
    EnrollingStudents,
  },
  props: ["courseId"],

  data() {
    return {
      courseTitle: "",
      courseNotFound: true,
    };
  },

  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },

  methods: {
    //
  },
};
</script>
