<template>
  <b-container>
    <div class="d-flex w-100 justify-content-between align-items-center mb-3">
      <h4 class="font-open-sans-bold">Daftar Course Leader (CL)</h4>
      <h5 v-if="!loading" class="font-open-sans-bold mr-4">
        <ICountUp :endVal="totalRows" />
        Course Leader
      </h5>
    </div>

    <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>

    <div v-else-if="emptyResults">
      <p>Belum ada course leader yang terdaftar</p>
      <b-button
        size="sm"
        variant="primary"
        class="mr-2"
        v-b-modal.addCourseleaderModal
      >
        <span class="font-weight-bold font-open-sans mr-2"
          >Tambah Course Leader
        </span>
        <font-awesome-icon icon="plus"></font-awesome-icon>
      </b-button>
    </div>

    <div v-else>
      <b-row class="w-100 justify-content-between" no-gutters>
        <!-- Filter / Search -->
        <b-col cols="5">
          <b-form-group class="mb-3">
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Cari Course Leader"
              ></b-form-input>

              <b-input-group-append>
                <b-button variant="primary" @click="filter = ''">
                  <font-awesome-icon icon="search" class="text-white" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- Add Courseleader -->
        <b-col cols="5" class="text-right mb-3">
          <b-button
            size="sm"
            variant="primary"
            class="mr-2"
            v-b-modal.addCourseleaderModal
          >
            <span class="font-weight-bold font-open-sans mr-2"
              >Tambah Course Leader
            </span>
            <font-awesome-icon icon="plus"></font-awesome-icon>
          </b-button>
          <b-button size="sm" variant="primary" class="" @click="getData">
            <font-awesome-icon icon="sync"></font-awesome-icon>
          </b-button>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table
        striped
        responsive
        :fields="fields"
        :items="courseleaders"
        :per-page="perPage"
        :current-page="currentPage"
        :total-rows="totalRows"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        class="font-roboto"
      >
        <template #cell(index)="row">
          {{ row.index + 1 }}
        </template>

        <template #cell(firstname)="{item}">
          {{ item.firstname }} {{ item.lastname }}
        </template>
        <template #cell(divisi)="{item}">
          {{item.divisi}}
        </template>
        <!-- Remove Button -->
        <template #cell(actions)="row">
          <b-link
            v-b-tooltip.hover
            title="Keluarkan Course Leader"
            class="text-secondary"
            @click="removeCourseleader(row.item)"
          >
            <font-awesome-icon icon="trash" class="" />
          </b-link>
        </template>
      </b-table>
      <b-row class="justify-content-between">
        <b-col cols="4">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="5"
            label-cols-lg="4"
            label-align-sm="right"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-pagination
            class="ml-auto"
            align="right"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
    <addCourseleaderModal
      :unregisteredCourseleaders="unregisteredCourseleaders"
      title="Tambah Course Leader"
      @reload-table="getData"
    />
    <removeCourseleaderModal
      title="Keluarkan Course Leader"
      :userData="userData"
      @reload-table="getData"
    />
  </b-container>
</template>

<script>
import AddCourseleaderModal from "@/components/courses/members/AddCourseleaderModal.vue";
import RemoveCourseleaderModal from "@/components/courses/members/RemoveCourseleaderModal.vue";

export default {
  components: {
    AddCourseleaderModal,
    RemoveCourseleaderModal,
  },

  data() {
    return {
      value: "",
      courseleaders: [],
      unregisteredCourseleaders: [],
      fields: [
        {
          key: "no",
          label: "No.",
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "firstname",
          label: "Nama Lengkap",
          sortable: true,
        },
        {
          key: "divisi",
          label: "Divisi",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      userData: {},
      loading: true,
      emptyResults: false,
    };
  },

  mounted() {
    this.getData();
  },

  watch: {
    $route() {
      this.getData();
    },
  },

  methods: {
    editUser(user) {
      this.userData = user;
      this.userData.role = "student";
      this.$bvModal.show("editUserModal");
    },

    removeCourseleader(user) {
      this.userData = user;
      this.userData.role = "student";
      this.$bvModal.show("removeCourseleaderModal");
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getCourseleaders(courseId) {
      // Empty table before fetching new data
      this.courseleaders = [];

      return this.axios.post(
        process.env.VUE_APP_API_URL + "/api/course/courseleaders",
        courseId
      );
    },

    getUnregisteredCourseleaders(courseId) {
      // Refresh list of unregistered courseleaders
      this.unregisteredCourseleaders = [];

      return this.axios.post(
        process.env.VUE_APP_API_URL + "/api/course/courseleaders/unregistered",
        courseId
      );
    },

    getData() {
      this.loading = true;

      const courseId = {
        course_id: parseInt(this.$route.params.id),
      };

      Promise.all([
        this.getCourseleaders(courseId),
        this.getUnregisteredCourseleaders(courseId),
      ])
        .then((results) => {
          const courseleaders = results[0].data;
          const unregisteredCourseleaders = results[1].data;

          if (courseleaders.success) {
            this.emptyResults = false;

            const data = courseleaders.courseleaders;

            data.forEach((courseleader,idx) => {
              courseleader.user.no = idx+1
              this.courseleaders.push(courseleader.user);
            });
            this.totalRows = this.courseleaders.length;
          } else {
            this.emptyResults = true;
            this.totalRows = 0;
          }
          if(unregisteredCourseleaders.success) {
            unregisteredCourseleaders.courseleaders.forEach((courseleader) => {
              this.unregisteredCourseleaders.push({
                code: courseleader.id,
                label: `${courseleader.firstname} ${courseleader.lastname} (${courseleader.email})`,
              });
            });
          }

          this.loading = false;
        })
        .catch((errors) => console.error(errors));
    },
  },
};
</script>
