import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
// import axios from "axios";

import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Users from "../views/Users.vue";
import UsersArchive from "@/components/users/UsersArchive.vue";
import Courses from "../views/Courses.vue";
import Settings from "../views/Settings.vue";
// import Banner from "@/components/settings/Banner.vue";
// import Testimoni from "@/components/settings/Testimoni.vue";
import Divisi from "@/components/settings/Divisi.vue";
import DataStudio from "@/components/settings/DataStudio.vue";
import CoursesArchive from "@/components/courses/CoursesArchive.vue";
import CoursePage from "@/components/courses/CoursePage.vue";
import ManageMembers from "@/components/courses/members/ManageMembers.vue";
import ManageCertificates from "@/components/courses/certificates/ManageCertificates.vue";
import CourseCategories from "@/components/courses/categories/CourseCategories.vue";
import CoursesByCategory from "@/components/courses/categories/CoursesByCategory.vue";
import Skills from "../views/Skills.vue";
import MyProfile from "../views/MyProfile.vue"
import NotFound from "../views/404.vue";
import Unauthenticated from "../views/401.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/users",
    name: "UserManagement",
    component: Users,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) next();
      else next("Unauthenticated");
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    children: [
      // {
      //   path: "banner",
      //   name: "Banner",
      //   component: Banner,
      // },
      // {
      //   path: "testimoni",
      //   name: "Testimoni",
      //   component: Testimoni,
      // },
      {
        path: "divisi",
        name: "Divisi",
        component: Divisi,
      },
      {
        path: "data-studio",
        name: "DataStudio",
        component: DataStudio,
      },
    ],
  },
  {
    path: "/users/archive",
    name: "UsersArchive",
    component: UsersArchive,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) next();
      else next("Unauthenticated");
    },
  },
  {
    path: "/courses",
    name: "CourseManagement",
    component: Courses,
  },
  {
    path: "/courses/archive",
    name: "CoursesArchive",
    component: CoursesArchive,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) next();
      else next("Unauthenticated");
    },
  },
  {
    path: "/courses/:id",
    name: "CoursePage",
    redirect: "/courses/:id/members",
    component: CoursePage,
    props: ({ params }) => ({
      courseId: parseInt(params.id),
    }),
    children: [
      {
        path: "members",
        name: "ManageMembers",
        component: ManageMembers,
        props: ({ params }) => ({
          courseId: parseInt(params.id),
        }),
      },
      {
        path: "/courses/:id/certificates",
        name: "ManageCertificates",
        component: ManageCertificates,
        props: ({ params }) => ({
          courseId: parseInt(params.id),
        }),
      },
    ],
  },
  {
    path: "/categories",
    name: "CourseCategories",
    component: CourseCategories,
  },
  {
    path: "/categories/:id/courses",
    name: "CoursesByCategory",
    component: CoursesByCategory,
    props: ({ params }) => ({
      categoryId: parseInt(params.id),
    }),
  },
  {
    path: "/skills",
    name: "SkillsManagement",
    component: Skills,
  },
  {
    path: "/profile",
    name: "MyProfile",
    component: MyProfile
  },
  {
    path: "/unauthenticated",
    name: "Unauthenticated",
    component: Unauthenticated,
  },
  {
    path: "*",
    name: "404",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode:"history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name == "Login" && store.state.isAuthenticated) next("/");
  else next();

  if (to.name !== "Login" && store.state.isAuthenticated == false)
    next("/login");
  else next();
});

export default router;
