<template>
  <div>
    <BannerSmall
      title="Arsip Pengguna"
      button="true"
      buttonText="Daftar Pengguna"
      location="/users"
    />
    <div class="bg-primary">
      <div class="container py-5">
        <b-card>
          <b-container>
            <div
              class="d-flex w-100 justify-content-between align-items-center mb-3"
            >
              <h4 class="font-open-sans-bold">Daftar Pengguna</h4>
            </div>

            <b-spinner
              v-if="loading"
              variant="primary"
              label="Spinning"
            ></b-spinner>

            <div v-else-if="emptyResults">
              Belum ada pengguna yang terarsip
            </div>

            <div v-else>
              <b-row class="w-100 justify-content-between ">
                <!-- Filter / Search -->
                <b-col cols="5">
                  <b-form-group class="mb-3">
                    <b-input-group>
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Cari Pengguna"
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button variant="primary" @click="filter = ''">
                          <font-awesome-icon icon="search" class="text-white" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <!-- Add Teacher -->
                <b-col cols="5" class="text-right mb-3">
                  <b-button
                    size=""
                    variant="primary"
                    class=""
                    @click="getArchive"
                  >
                    <font-awesome-icon icon="sync"></font-awesome-icon>
                  </b-button>
                </b-col>
              </b-row>
              <!-- Table -->
              <b-table
                striped
                responsive
                :fields="fields"
                :items="archive"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                class="font-roboto"
              >
                <template #cell(index)="row">
                  {{ row.index + 1 }}
                </template>
                <template #cell(firstname)="{item}">
                  {{ item.firstname }} {{ item.lastname }}
                </template>
                <template #cell(roles)="{ item }">
                  {{ formatRole(item.roles) }}
                </template>
                <template #cell(deleted_by)="{ item }">
                  {{ item.deleted_by ? item.deleted_by : "-"}}
                </template>
                <!-- Restore Buttons -->
                <template #cell(actions)="row">
                  <b-link
                    v-b-tooltip.hover
                    title="Kembalikan Pengguna"
                    class="text-secondary"
                    @click="restoreUser(row.item)"
                  >
                    <font-awesome-icon icon="trash-restore" class="" />
                  </b-link>
                </template>
              </b-table>
              <b-row class="justify-content-between">
                <b-col cols="4">
                  <b-form-group
                    label="Per page"
                    label-for="per-page-select"
                    label-cols-sm="6"
                    label-cols-md="4"
                    label-cols-lg="3"
                    label-align-sm="right"
                    class="mb-0"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-pagination
                    class="ml-auto"
                    align="right"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="my-table"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-container>
        </b-card>
      </div>
    </div>
    <restoreUserModal
      title="Kembalikan Pengguna"
      :userData="userData"
      @reload-table="getArchive"
    />
  </div>
</template>

<script>
import BannerSmall from "@/components/BannerSmall.vue";
import RestoreUserModal from "@/components/users/RestoreUserModal.vue";

export default {
  components: { BannerSmall, RestoreUserModal },

  data() {
    return {
      archive: [],
      fields: [
        {
          key: "index",
          label: "No.",
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "firstname",
          label: "Nama Lengkap",
          sortable: true,
        },
        {
          key: "roles",
          label: "Role",
          sortable: true,
        },
        {
          key: "deleted_by",
          label: "Deleted By",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      userData: {},
      loading: true,
      emptyResults: false,
    };
  },

  computed: {
    rows() {
      return this.archive.length;
    },
  },

  mounted() {
    this.getArchive();
  },

  methods: {
    restoreUser(user) {
      this.userData = user;
      this.$bvModal.show("restoreUserModal");
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    formatRole(role) {
      switch (role) {
        case "admin":
          return "Admin System";
        case "courseleader":
          return "Course Leader";
        case "teacher":
          return "Pengajar";
        default:
          return "Peserta";
      }
    },

    async getArchive() {
      this.loading = true;
      this.archive = [];

      try {
        const response = await this.axios.get(
          process.env.VUE_APP_API_URL + "/api/user/archived"
        );

        if (response.data.success) {
          this.emptyResults = false;

          const data = response.data.users;
          this.archive = Object.keys(data).map((i) => data[i]);
        } else {
          this.emptyResults = true;
        }

        this.loading = false;
      } catch (errors) {
        console.error(errors);
      }
    },
  },
};
</script>
