<template>
  <b-modal id="delete-skill-modal" title="Hapus Keterampilan" hide-footer>
    <div class="text-center">
      <p class="mb-0">Apakah Anda yakin untuk menghapus keterampilan</p>
      <p class="font-weight-bold">{{ computedSkillData.name }}?</p>
      <b-button variant="danger" @click="deleteSkill"
        ><b-spinner small v-if="loading"></b-spinner
        ><span v-else>Hapus</span></b-button
      >
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ["skillData"],

  data() {
    return {
      loading: false,
      toastCount: 0,
    };
  },

  computed: {
    computedSkillData() {
      return this.skillData;
    },
  },

  methods: {
    async deleteSkill() {
      this.loading = true;

      const formData = {
        skill_id: this.skillData.id,
      };

      try {
        const response = await this.axios.delete(
          process.env.VUE_APP_API_URL + "/api/skills/delete",
          {
            data: formData,
          }
        );

        const data = response.data;
        const status = data.success ? "Success" : "Failed";
        const variant = data.success ? "success" : "danger";
        const message = data.message;

        this.$bvModal.hide("delete-skill-modal");

        // Show Toast
        this.toastCount++;
        this.$bvToast.toast(message, {
          title: status,
          autoHideDelay: 5000,
          variant: variant,
        });

        if (data.success) {
          // Refresh table
          this.$emit("refresh-skills");
        }
      } catch (errors) {
        console.error(errors);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
