<template lang="">
  <b-container>
    <div class="d-flex w-100 justify-content-between align-items-center mb-4">
      <div class="d-flex align-items-center">
        <h4 class="font-open-sans-bold mb-0 mr-2">{{ assessmentItem.name }}</h4>
      </div>
      <div>
        <b-button
          v-if="!loading"
          variant="primary"
          class="mr-2"
          v-b-modal.add-score-modal
          ><font-awesome-icon icon="user-plus" class="mr-1"></font-awesome-icon>
          Tambah Nilai</b-button
        >
        <b-button variant="primary" @click="goBack">Kembali</b-button>
      </div>
    </div>

    <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>

    <div v-else-if="emptyResults">
      <p>Belum ada nilai peserta yang ditambahkan.</p>
    </div>

    <div v-else>
      <b-table
        striped
        responsive
        :fields="fields"
        :items="grades"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        class="font-roboto"
      >
        <template #cell(index)="row">
          {{ row.index + 1 }}
        </template>

        <!-- Edit and Delete Buttons -->
        <template #cell(score)="{ item }">
          <div>
            <b-row
              v-if="editingItem == item.id"
              align-h="center"
              align-v="center"
              class="d-flex"
            >
              <b-col cols="4"
                ><b-form-input
                  v-focus
                  type="number"
                  number
                  v-model="item.score"
                  @keyup.enter="saveScore(item)"
                >
                </b-form-input
              ></b-col>

              <b-link
                v-b-tooltip.hover
                title="Batal"
                @click="cancelEdit"
                class="mr-3"
              >
                <font-awesome-icon
                  icon="times"
                  class=" mb-0 text-secondary"
                ></font-awesome-icon>
              </b-link>
              <b-link
                v-b-tooltip.hover
                title="Hapus Nilai"
                @click="deleteScoreWarning(item)"
                class="mr-3"
              >
                <font-awesome-icon
                  icon="trash"
                  class=" mb-0 text-secondary"
                ></font-awesome-icon>
              </b-link>
              <b-link
                v-b-tooltip.hover
                title="Simpan Nilai"
                @click="saveScore(item)"
              >
                <font-awesome-icon
                  icon="check"
                  class=" mb-0 text-success"
                ></font-awesome-icon>
              </b-link>
            </b-row>

            <b-link
              v-else
              v-b-tooltip.hover
              title="Edit Nilai"
              class="hover-pointer"
              @click="editScore(item.id)"
            >
              {{ item.score }}
            </b-link>
          </div>
        </template>
      </b-table>
    </div>
    <AddScoreModal
      :students="students"
      :evaluationId="evaluationId"
      @reload-scores="getData"
    />
    <DeleteScoreModal :studentScore="studentScore" @reload-scores="getData" />
  </b-container>
</template>
<script>
import AddScoreModal from "@/components/courses/certificates/AddScoreModal.vue";
import DeleteScoreModal from "@/components/courses/certificates/DeleteScoreModal.vue";

export default {
  components: { AddScoreModal, DeleteScoreModal },

  props: ["isEvaluationGradeView", "assessmentItem"],

  data() {
    return {
      grades: [],
      students: [],
      studentScore: {},
      fields: [
        {
          key: "index",
          label: "No.",
        },
        {
          key: "user.email",
          label: "Email",
          sortable: true,
        },
        {
          key: "user",
          label: "Nama Lengkap",
          sortable: true,
          formatter: (value) => {
            return `${value?.firstname} ${value?.lastname}`;
          },
        },
        {
          key: "score",
          label: "Nilai",
        },
      ],
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      loading: false,
      emptyResults: false,
      editingItem: 0,
      toastCount: 0,
    };
  },

  computed: {
    evaluationId() {
      return this.assessmentItem.id;
    },
  },

  mounted() {
    this.getData();
  },

  directives: {
    focus: {
      inserted: function(el) {
        el.focus();
      },
    },
  },

  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    goBack() {
      this.$emit("update:isEvaluationGradeView", false);
    },

    editScore(item) {
      this.editingItem = item;
    },

    cancelEdit() {
      this.editingItem = 0;
    },

    getGrades() {
      const requestBody = {
        evaluation_id: this.assessmentItem.id,
      };

      return this.axios.post(
        process.env.VUE_APP_API_URL + "/api/assesment/evaluation/result",
        requestBody
      );
    },

    getStudents() {
      const requestBody = {
        course_id: Number(this.$route.params.id),
      };

      return this.axios.post(
        process.env.VUE_APP_API_URL + "/api/course/student",
        requestBody
      );
    },

    getData() {
      this.loading = true;

      Promise.all([this.getGrades(), this.getStudents()])
        .then((results) => {
          const grades = results[0].data;
          const students = results[1].data;

          if (grades.success && grades.data[0].result.length) {
            this.grades = grades.data[0].result;
            this.emptyResults = false;
          } else {
            this.emptyResults = true;
          }

          if (students.success) {
            this.students = [];

            students.students.forEach((student) => {
              this.students.push({
                code: student.user_id,
                label: `${student.user.firstname} ${student.user.lastname} (${student.user.email})`,
              });
            });
          }
        })
        .catch((errors) => console.error(errors))
        .finally(() => {
          this.editingItem = 0;
          this.loading = false;
        });
    },

    deleteScoreWarning(item) {
      this.studentScore = item;
      this.$bvModal.show("delete-score-modal");
    },

    async saveScore(item) {
      this.loading = true;
      this.editingItem = 0;

      const requestBody = {
        evaluation_id: item.evaluation_id,
        user_id: item.user.user_id,
        score: item.score,
      };

      try {
        const response = await this.axios.post(
          process.env.VUE_APP_API_URL + "/api/assesment/evaluation/add_score",
          requestBody
        );

        const data = response.data;

        const status = data.success ? "Success" : "Failed";
        const variant = data.success ? "success" : "danger";
        const message = data.success
          ? "Nilai peserta diupdate."
          : "Nilai peserta gagal diupdate.";

        // Show Toast
        this.toastCount++;
        this.$bvToast.toast(message, {
          title: status,
          autoHideDelay: 5000,
          variant: variant,
        });

        this.getData();
      } catch (errors) {
        console.error(errors);
      }
    },
  },
};
</script>
