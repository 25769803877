<template>
  <b-modal id="addCourseleaderModal" title="Tambah Course Leader" hide-footer>
    <b-form @submit.stop.prevent="onSubmit">
      <v-select
        v-model="courseleader"
        :options="unregisteredCourseleaders"
        transition=""
        class="mb-3"
      >
        <template #search="{attributes, events}">
          <input
            class="vs__search"
            :required="!courseleader"
            v-bind="attributes"
            v-on="events"
          />
        </template>
      </v-select>

      <b-button type="submit" variant="primary" class="float-right"
        ><b-spinner small v-if="loading"></b-spinner><span v-else>Tambah</span>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  name: "AddCourseleaderModal",
  props: ["title", "unregisteredCourseleaders"],

  data() {
    return {
      courseleader: "",
      courseleaders: [],
      loading: false,
      toastCount: 0,
    };
  },

  methods: {
    onSubmit() {
      this.loading = true;

      try {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            this.loading = false;

            return;
          }

          this.submitData();
        });
      } catch (errors) {
        console.log(errors);
      }
    },

    async submitData() {
      try {
        const formData = {
          course_id: parseInt(this.$route.params.id),
          user_id: this.courseleader.code,
        };

        const response = await this.axios.post(
          process.env.VUE_APP_API_URL + "/api/course/add/courseleader",
          formData
        );

        const data = response.data;
        const status = data.success ? "Success" : "Failed";
        const variant = data.success ? "success" : "danger";
        const message = data.message;

        this.$bvModal.hide("addCourseleaderModal");

        // Show Toast
        this.toastCount++;
        this.$bvToast.toast(message, {
          title: status,
          autoHideDelay: 5000,
          variant: variant,
        });

        if (data.success) {
          this.courseleader = "";
          // Reload courseleaders table
          this.$emit("reload-table");
        }
      } catch (errors) {
        console.error(errors);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
