<template>
  <b-modal id="delete-score-modal" title="Hapus Nilai Peserta">
    <div class="text-center">
      <p>
        Apakah Anda yakin untuk menghapus nilai
        <span class="h5 d-block font-weight-bold my-1">{{ studentEmail }}</span>
        dari penilaian?
      </p>
    </div>
    <template #modal-footer>
      <b-button variant="danger" @click="deleteScore" class="float-right"
        ><b-spinner small v-if="loading"></b-spinner
        ><span v-else>Hapus</span></b-button
      >
    </template>
  </b-modal>
</template>

<script>
export default {
  props: ["studentScore"],

  data() {
    return {
      loading: false,
      toastCount: 0,
    };
  },

  computed: {
    studentEmail() {
      return this.studentScore?.user?.email;
    },
  },

  methods: {
    async deleteScore() {
      this.loading = true;

      const item = this.studentScore;

      const requestBody = {
        evaluation_id: item.evaluation_id,
        user_id: item.user.user_id,
      };

      try {
        const response = await this.axios.delete(
          process.env.VUE_APP_API_URL +
            "/api/assesment/evaluation/delete_score/",
          {
            data: requestBody,
          }
        );

        const data = response.data;

        const status = data.success ? "Success" : "Failed";
        const variant = data.success ? "success" : "danger";
        const message = data.success
          ? "Nilai peserta dihapus."
          : "Nilai peserta gagal dihapus.";

        this.loading = false;
        this.$bvModal.hide("delete-score-modal");

        // Show Toast
        this.toastCount++;
        this.$bvToast.toast(message, {
          title: status,
          autoHideDelay: 5000,
          variant: variant,
        });

        this.$emit("reload-scores");
      } catch (errors) {
        console.error(errors);
      }
    },
  },
};
</script>
