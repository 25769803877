<template>
  <b-container>
    <div class="d-flex w-100 justify-content-between align-items-center mb-3">
      <h4 class="font-open-sans-bold">Daftar Pengajar</h4>
      <h5 v-if="!loading" class="font-open-sans-bold mr-4">
        <ICountUp :endVal="totalRows" />
        Pengajar
      </h5>
    </div>

    <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>

    <div v-else-if="emptyResults">
      <p>Belum ada pengajar yang terdaftar</p>
      <b-button
        size="sm"
        variant="primary"
        class="mr-2"
        v-b-modal.addTeacherModal
      >
        <span class="font-weight-bold font-open-sans mr-2"
          >Tambah Pengajar
        </span>
        <font-awesome-icon icon="plus"></font-awesome-icon>
      </b-button>
    </div>

    <div v-else>
      <b-row class="w-100 justify-content-between ">
        <!-- Filter / Search -->
        <b-col cols="5">
          <b-form-group class="mb-3">
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Cari Pengajar"
              ></b-form-input>

              <b-input-group-append>
                <b-button variant="primary" @click="filter = ''">
                  <font-awesome-icon icon="search" class="text-white" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- Add Teacher -->
        <b-col cols="5" class="text-right mb-3">
          <b-button
            size="sm"
            variant="primary"
            class="mr-2"
            v-b-modal.addTeacherModal
          >
            <span class="font-weight-bold font-open-sans mr-2"
              >Tambah Pengajar
            </span>
            <font-awesome-icon icon="plus"></font-awesome-icon>
          </b-button>
          <b-button size="sm" variant="primary" class="" @click="getData">
            <font-awesome-icon icon="sync"></font-awesome-icon>
          </b-button>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table
        striped
        responsive
        :fields="fields"
        :items="teachers"
        :per-page="perPage"
        :current-page="currentPage"
        :total-rows="totalRows"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        class="font-roboto"
      >
        <template #cell(index)="row">
          {{ row.index + 1 }}
        </template>

        <template #cell(firstname)="{item}">
          {{ item.firstname }} {{ item.lastname }}
        </template>

        <!-- Edit and Delete Buttons -->
        <template #cell(actions)="row">
          <b-link
            v-b-tooltip.hover
            title="Keluarkan Pengajar"
            class="text-secondary"
            @click="removeTeacher(row.item)"
          >
            <font-awesome-icon icon="trash" class="" />
          </b-link>
        </template>
      </b-table>
      <b-row class="justify-content-between">
        <b-col cols="4">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="5"
            label-cols-lg="4"
            label-align-sm="right"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-pagination
            class="ml-auto"
            align="right"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
    <addTeacherModal
      :unregisteredTeachers="unregisteredTeachers"
      title="Tambah Pengajar"
      @reload-table="getData"
    />
    <removeTeacherModal
      title="Keluarkan Pengajar"
      :userData="userData"
      @reload-table="getData"
    />
  </b-container>
</template>

<script>
import AddTeacherModal from "@/components/courses/members/AddTeacherModal.vue";
import RemoveTeacherModal from "@/components/courses/members/RemoveTeacherModal.vue";

export default {
  components: {
    AddTeacherModal,
    RemoveTeacherModal,
  },

  data() {
    return {
      value: "",
      teachers: [],
      unregisteredTeachers: [],
      fields: [
        {
          key: "no",
          label: "No.",
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "firstname",
          label: "Nama Lengkap",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      userData: {},
      loading: true,
      emptyResults: false,
    };
  },

  mounted() {
    this.getData();
  },

  watch: {
    $route() {
      this.getData();
    },
  },

  methods: {
    editUser(user) {
      this.userData = user;
      this.userData.role = "student";
      this.$bvModal.show("editUserModal");
    },

    removeTeacher(user) {
      this.userData = user;
      this.userData.role = "student";
      this.$bvModal.show("removeTeacherModal");
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getTeachers(courseId) {
      // Empty table before fetching new data
      this.teachers = [];

      return this.axios.post(
        process.env.VUE_APP_API_URL + "/api/course/teacher",
        courseId
      );
    },

    getUnregisteredTeachers(courseId) {
      // Refresh list of unregistered teachers
      this.unregisteredTeachers = [];

      return this.axios.post(
        process.env.VUE_APP_API_URL + "/api/course/teacher/unregistered",
        courseId
      );
    },

    getData() {
      this.loading = true;

      const courseId = {
        course_id: parseInt(this.$route.params.id),
      };

      Promise.all([
        this.getTeachers(courseId),
        this.getUnregisteredTeachers(courseId),
      ])
        .then((results) => {
          const teachers = results[0].data;
          const unregisteredTeachers = results[1].data;

          if (teachers.success) {
            this.emptyResults = false;

            const data = teachers.teachers;

            data.forEach((teacher, idx) => {
              teacher.user.no = idx+1
              this.teachers.push(teacher.user);
            });
            this.totalRows = this.teachers.length;
          } else {
            this.emptyResults = true;
            this.totalRows = 0;
          }

          unregisteredTeachers.teachers.forEach((teacher) => {
            this.unregisteredTeachers.push({
              code: teacher.id,
              label: `${teacher.firstname} ${teacher.lastname} (${teacher.email})`,
            });
          });

          this.loading = false;
        })
        .catch((errors) => console.error(errors));
    },
  },
};
</script>
