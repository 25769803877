<template>
  <b-modal
    id="editCategoryModal"
    title="Edit Kategori Pelatihan"
    ref="editCategoryModal"
    hide-footer
  >
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group id="name-group" label="Judul Kategori:" label-for="name">
        <b-form-input
          id="name"
          name="name"
          v-model="categoryData.name"
          placeholder=""
          v-validate="'required'"
          :state="validateState('name')"
        ></b-form-input>
        <b-form-invalid-feedback>
          Judul kategori wajib diisi.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="code-group" label="Judul Kategori:" label-for="code">
        <b-form-input
          id="code"
          name="code"
          v-model="categoryData.code"
          placeholder=""
          v-validate="'required'"
          :state="validateState('code')"
        ></b-form-input>
        <b-form-invalid-feedback>
          Kode kategori wajib diisi.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-button type="submit" variant="primary" class="float-right"
        ><b-spinner small v-if="loading"></b-spinner><span v-else>Submit</span>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  name: "EditCategoryModal",
  props: ["categoryData"],

  data() {
    return {
      password: "",
      passwordConfirm: "",
      loading: false,
      toastCount: 0,
      emits: ["refresh-categories"],
    };
  },

  methods: {
    async submitData() {
      const formData = {
        category_id: this.categoryData.id,
        name: this.categoryData.name,
        code: this.categoryData.code,
      };

      try {
        const response = await this.axios.put(
          process.env.VUE_APP_API_URL + "/api/category/update",
          formData
        );

        const data = response.data;
        const status = data.success ? "Success" : "Failed";
        const variant = data.success ? "success" : "danger";
        const message = data.message;

        this.loading = false;
        this.$bvModal.hide("editCategoryModal");

        // Show Toast
        this.toastCount++;
        this.$bvToast.toast(message, {
          title: status,
          autoHideDelay: 5000,
          variant: variant,
        });

        if (data.success) {
          // Refresh table
          this.$emit("refresh-categories");
        }
      } catch (errors) {
        console.error(errors);
      }
    },

    onSubmit() {
      this.loading = true;

      try {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            this.loading = false;

            return;
          }

          this.submitData();
        });
      } catch (errors) {
        console.log(errors);
      }
    },
  },
};
</script>
