<template>
  <div class="banner-small">
    <b-modal v-model="openModals"
      title="Hasil Import Peserta"
      hide-footer size="lg" header-border-variant="none">
      <div class="row">
        <div class="col-6">
          <b-form-group class="mb-3">
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Cari Peserta"
              ></b-form-input>

              <b-input-group-append>
                <b-button variant="primary" @click="filter = ''">
                  <font-awesome-icon icon="search" class="text-white" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
        <div class="col-12 mb-4">
          <b-table
            striped
            responsive
            :fields="fieldsImport"
            :items="siadikSyncResult"
            :per-page="perPage"
            :current-page="currentPage"
            :total-rows="totalRowsImport"
            :filter="filter"
            class="font-roboto"
          >
            <template #cell(success)="{item}">
              <b-badge :variant="item.success ? 'success' : 'danger'" class="mr-3">{{ item.success ? 'Berhasil' : 'Tidak berhasil' }}</b-badge>
            </template>
          </b-table>
          <div class="col-4 px-0">
            <b-pagination
              class="ml-auto"
              align="right"
              v-model="currentPage"
              :total-rows="totalRowsImport"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </b-modal>
    <div class="container h-100">
      <b-row align-v="center" class="h-100">
        <b-col cols="6" class="text-left">
          <h1 class="text-left mb-2 font-montserrat-bold text-primary">
            {{ title }}
          </h1>
          <b-card class="mb-2 br-left-primary" v-if="$route.name == 'ManageMembers' || $route.name == 'ManageCertificates'">
            <div class="row">
              <div class="col-6">
                <h3>Info Siadik</h3>
              </div>
              <div class="col-6 text-right">
                <p class="mb-0">Last Integration</p>
                <span style="color:grey;font-weight:bold;" v-if="info">{{ info.last_integration | moment("DD MMM YYYY") }}</span>
              </div>
            </div>
            <div class="row" v-if="info && info.group_program_siadik">
              <div class="col-4">
                <span>Group Program Siadik</span>
              </div>
              <div class="col-8">
                <span class="fs-info">: {{info.group_program_siadik}}</span>
              </div>
            </div>
            <div class="row" v-if="info && info.program_siadik">
              <div class="col-4">
                <span>Program Siadik</span>
              </div>
              <div class="col-8">
                <span class="fs-info">: {{info.program_siadik}}</span>
              </div>
            </div>
            <div class="row" v-if="info && info.program_jadwal_siadik">
              <div class="col-4">
                <span>Program Jadwal Siadik</span>
              </div>
              <div class="col-8">
                <span class="fs-info">: {{info.program_jadwal_siadik}}</span>
              </div>
            </div>
            <div class="row" v-if="info && info.program_kelas_siadik">
              <div class="col-4">
                <span>Program Kelas Siadik</span>
              </div>
              <div class="col-8">
                <span class="fs-info">: {{info.program_kelas_siadik}}</span>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-6 text-left">
                <b-button
                  v-if="siadikSyncResult.length > 0" @click="showImportResult"
                  variant="primary"
                  size="sm"
                  class="font-open-sans-bold"
                >
                  <font-awesome-icon
                      icon="exclamation-circle"
                      class="small mr-2"
                    ></font-awesome-icon>
                  <span>Lihat Hasil Import</span>
                </b-button>
              </div>
              <div class="col-6 text-right">
                <b-button
                  variant="outline-primary"
                  v-if="info && info.group_program_siadik"
                  @click="sinkronKelas"
                  size="sm"
                  class="font-open-sans-bold"
                >
                  <b-spinner
                    v-if="loadingKelas"
                    style="width: 20px; height: 20px;"
                    label="Spinning"
                    class="d-block mx-auto text-white"
                  ></b-spinner>
                  <span v-else style="font-size:14px;">Sync Siadik</span>
                </b-button>
              </div>
            </div>
            <b-link
              v-if="backButton"
              :to="backButtonHref"
              class="d-flex align-items-center"
              ><font-awesome-icon
                icon="chevron-left"
                class="small mr-2"
              ></font-awesome-icon
              ><span> Kembali ke {{ backButtonTitle }}</span></b-link
            >
          </b-card>
          <template  v-else>
            <b-link
              v-if="backButton"
              :to="backButtonHref"
              class="d-flex align-items-center"
              ><font-awesome-icon
                icon="chevron-left"
                class="small mr-2"
              ></font-awesome-icon
              ><span> Kembali ke {{ backButtonTitle }}</span>
            </b-link>
          </template>
        </b-col>
        <b-col cols="6 text-right">
          <span v-if="info && info.email_groups">Email : {{info.email_groups}}</span>
          <b-button v-else-if="buttonAddGroupEmail"
            @click="registerEmailGroups"
            variant="primary"
            size="sm"
            class="font-open-sans-bold mr-3"
          >
            <b-spinner
              v-if="isLoading"
              style="width: 20px; height: 20px;"
              label="Spinning"
              class="d-block mx-auto text-white"
            ></b-spinner>
            <span v-else>Add Email Groups</span>
          </b-button>
          <b-button
            v-if="buttonText"
            variant="primary"
            :to="location"
            size="sm"
            class="font-open-sans-bold"
          >
            {{ buttonText }}
            <font-awesome-icon
              v-if="buttonIcon"
              :icon="buttonIcon"
              class="ml-1"
            ></font-awesome-icon>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openModals:false,
      siadikSyncResult: [],
      isLoading: false,
      loadingKelas: false,
      totalRowsImport: 1,
      currentPage: 1,
      perPage: 10,
      filter: null,
      studentsImport: [],
      fieldsImport: [
        {
          key: "no",
          label: "No.",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "success",
          label: "Result",
        },
        {
          key: "status",
          label: "Message",
        },
      ],
    }
  },
  name: "BannerSmall",
  props: [
    "info",
    "title",
    "button",
    "buttonText",
    "buttonIcon",
    "location",
    "backButton",
    "backButtonTitle",
    "backButtonHref",
    "buttonAddGroupEmail"
  ],
  methods: {
    showImportResult() {
      this.openModals = true
    },
    async sinkronKelas() {
      let userEmails = []
      this.siadikSyncResult = []
      this.loadingKelas = true
      let _res = await this.getStudentClass();
      if(_res.data.success) {
        _res.data.students.forEach(e => {
          userEmails.push(e.user.email)
        });
      }
      const payload = {
        id_kelas: this.info.program_kelas_siadik,
        email: userEmails
      }
      try {
        const res = await this.axios.post(
          process.env.VUE_APP_API_SIADIK + `/api/siadik/sinkron/user/kelas`, payload
        );
        if(res.status == 200) {
           res.data.forEach((e,index) => {
            e.no = index+1
          });
          this.siadikSyncResult = res.data
          this.totalRowsImport = res.data.length
          await this.axios.post(
            process.env.VUE_APP_API_URL + `/api/course/sinchronization/last`, {
              id_kelas_siadik: this.info.program_kelas_siadik
            }
          );
        }
      } catch (e) {
        console.log(e)
      }
      this.loadingKelas = false
    },
    async registerEmailGroups() {
      this.isLoading = true
      try {
        const res = await this.axios.post(
          process.env.VUE_APP_API_URL + `/api/course/addemailgroup`, {
            course_id: parseInt(this.info.real_id)
          }
        );
        if(res.status == 200) {
          let rest = await this.axios.post(
            process.env.VUE_APP_API_URL + `/api/course/addmembergroup`, {
              course_id: parseInt(this.info.real_id)
            }
          );
          if(rest.status == 200) {
            this.$bvToast.toast("Email berhasil dibuat", {
              title: "Success",
              autoHideDelay: 5000,
              variant: "success",
            });
            location.reload();
          }
        }
      } catch (error) {
        this.isLoading = false
      }
      this.isLoading = false
    },
    getStudentClass() {
      return this.axios.post(
          process.env.VUE_APP_API_URL + "/api/course/student",
          {
            course_id : parseInt(this.$route.params.id)
          }
      );
    }
  }
};
</script>
<style>
.br-left-primary {
  border-left: 3px solid #255495;
  box-shadow: 0 2px 8px 2px rgb(0 0 0 / 8%);
  font-size: 14px;
}
.fs-info {
  font-weight: 500;
  color: #255495;
}
</style>