<template>
  <b-modal id="setup-assessment-modal" title="Setup Penilaian" hide-footer>
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group label="Total Bobot:" label-for="weight" label-cols="8">
        <b-form-input
          id="weight"
          name="weight"
          type="number"
          v-model="computedWeight"
          v-validate="'required'"
          :state="validateState('weight')"
        ></b-form-input>
        <b-form-invalid-feedback>
          Total Bobot Penilaian wajib diisi.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label="Nilai Minimum Sertifikasi:"
        label-for="weight"
        label-cols="8"
      >
        <b-form-input
          id="min_score"
          name="min_score"
          type="number"
          v-model="computedMinScore"
          v-validate="'required'"
          :state="validateState('min_score')"
        ></b-form-input>
        <b-form-invalid-feedback>
          Nilai Minimum Sertifikasi wajib diisi.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-button type="submit" variant="primary" class="float-right mt-3"
        ><b-spinner small v-if="loading"></b-spinner
        ><span v-else>Setup Penilaian</span>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      form: {
        enable_certificate: true,
        is_requirement: true,
        is_visible: true,
      },
      loading: false,
      toastCount: 0,
    };
  },

  props: ["weight", "certificate_min_score"],

  computed: {
    computedWeight: {
      get() {
        return this.weight;
      },

      set(newWeight) {
        this.$emit("update:weight", newWeight);
      },
    },

    computedMinScore: {
      get() {
        return this.certificate_min_score;
      },

      set(newMinScore) {
        this.$emit("update:certificate_min_score", newMinScore);
      },
    },
  },

  methods: {
    onSubmit() {
      this.loading = true;
      window.location.reload();
      try {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            this.loading = false;

            return;
          }

          this.setupAssessment();
        });
      } catch (errors) {
        console.log(errors);
      }
    },

    async setupAssessment() {
      try {
        const requestBody = {
          course_id: parseInt(this.$route.params.id),
          weight: Number(this.weight),
          minimum_score_pass: Number(this.certificate_min_score),
          ...this.form,
        };

        const response = await this.axios.post(
          process.env.VUE_APP_API_URL + "/api/assesment/setup",
          requestBody
        );

        const data = response.data;
        const status = data.success ? "Success" : "Failed";
        const variant = data.success ? "success" : "danger";
        const message = data.success
          ? "Penilaian berhasil disetup."
          : "Penilaian gagal disetup.";

        this.loading = false;
        this.$bvModal.hide("setup-assessment-modal");

        // Show Toast
        this.toastCount++;
        this.$bvToast.toast(message, {
          title: status,
          autoHideDelay: 5000,
          variant: variant,
        });

        if (data.success) {
          this.$emit("reload-assessments");
        }
      } catch (errors) {
        console.error(errors);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
