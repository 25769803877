<template>
  <div>
    <header class="p-3 p-sm-5 text-left font-weight-bold" style="background: #e9ecef !important">
      <b-container>
        <h1>Edit Profil</h1>
      </b-container>
    </header>

    <section class="p-3 p-sm-5">
      <b-container>
        <b-row>
          <b-col cols="6" sm="3" md="3" lg="3">
            <b-aspect aspect="1:1" class="position-relative">
              <b-img
                ref="avatarImage"
                :src="avatar"
                class="position-absolute w-100 h-100 fit-cover"
                rounded="circle"
              />
              <b-button
                class="position-stretched w-100 h-100 rounded-circle opacity-0 opacity-hover-3"
              >
                <font-awesome-icon
                  icon="camera"
                  size="4x"
                />
              </b-button>
              <b-overlay
                :show="photoLoading"
                no-wrap
                rounded="circle"
              ></b-overlay>
            </b-aspect>
            <div class="p-3 d-flex align-items-center justify-content-center">
              <div class="fileUpload btn btn-primary">
                <span>Change Picture</span>
                <input class="upload " type="file" @change="changePhoto" />
              </div>
            </div>
          </b-col>
          <b-col cols="12" sm="12" md="9" lg="9" class="text-left">
            <div v-if="!editProfileMode">
              <div class="text-muted">{{userInfo.username}}</div>
              <div style="font-size: 3rem !important;">
                {{ userInfo.firstName }} {{ userInfo.lastName }}
              </div>
              <div>
                <b-link :href="`mailto:${userInfo.mail}`" target="_blank" class="font-weight-bold">
                  <font-awesome-icon
                    icon="envelope"
                    size="1x"/> {{userInfo.mail}}
                </b-link>
              </div>
              <div class="mb-3">
                <b-link :href="`#`" target="_blank">
                  <font-awesome-icon
                    icon="birthday-cake"
                    size="1x"/><span class="ml-2">{{ userInfo.birthday | moment("DD MMMM YYYY") }}</span>
                </b-link>
              </div>
              <b-button @click="enterEditMode" class="font-weight-bold">Edit Profile </b-button>
            </div>

            <b-form
              v-else
              @submit.prevent="changeProfile"
              class="position-relative"
            >
              <b-alert v-model="errorProfile" variant="danger" dismissible>
                {{ errorProfileMessage }}
              </b-alert>

              <b-row>
                <b-col cols="6">
                  <b-form-group label="Nama Depan:">
                    <b-form-input v-model="profileForm.firstName" required />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Nama Belakang:">
                    <b-form-input v-model="profileForm.lastName" required />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group label="Email:">
                <b-form-input
                  type="email"
                  v-model="profileForm.email"
                  required
                />
              </b-form-group>
              <b-form-group label="Tanggal lahir:">
                <b-form-datepicker  v-model="profileForm.birthday" class="mb-2"></b-form-datepicker>
              </b-form-group>

              <b-button @click="exitEditMode" class="mr-3">Batal</b-button>
              <b-button variant="primary" type="submit">Simpan</b-button>

              <b-overlay :show="profileLoading" no-wrap />
            </b-form>
            <hr />
            <h2>Ganti Password</h2>
            <b-form @submit.prevent="changePassword" class="position-relative">
              <b-alert v-model="successPassword" variant="success" dismissible>
                {{ successPasswordMessage }}
              </b-alert>

              <b-alert v-model="errorPassword" variant="danger" dismissible>
                {{ errorPasswordMessage }}
              </b-alert>

              <b-form-group label="Password Lama:">
                <b-form-input
                  type="password"
                  v-model="passwordForm.oldPassword"
                  required
                />
              </b-form-group>
              <b-form-group label="Password Baru:">
                <b-form-input
                  type="password"
                  v-model="passwordForm.newPassword"
                  required
                />
              </b-form-group>
              <b-form-group label="Konfirmasi Password Baru:">
                <b-form-input
                  type="password"
                  v-model="passwordForm.confirmPassword"
                  required
                />
                <b-form-invalid-feedback :state="passwordConfirmed">
                  Konfirmasi password tidak sama
                </b-form-invalid-feedback>
                <b-form-valid-feedback :state="passwordConfirmed">
                  Konfirmasi password sama
                </b-form-valid-feedback>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                :disabled="!passwordConfirmed"
              >
                Simpan Perubahan
              </b-button>
              <hr>
              <b-overlay :show="passwordLoading" no-wrap />
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>
<style scoped>
.pointer {
  cursor: pointer;
}
.fileUpload {
  position: relative;
  overflow: hidden;
  margin: 10px;
}
.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
</style>
<script>
export default {
  data() {
    return {
      openModal: false,
      avatar: '',
      editProfileMode: false,
      validated: false,
      successPassword: false,
      successPasswordMessage: 'Success',
      errorPassword: false,
      errorPasswordMessage: 'Error',
      passwordLoading: false,
      passwordForm: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },

      errorProfile: false,
      errorProfileMessage: 'Error',
      profileLoading: false,
      profileForm: {
        firstName: '',
        lastName: '',
        email: '',
        birthday: ''
      },
      photoLoading: false,
    }
  },
  async mounted() {
    this.enterEditMode()
    await this.fetchUserAvatar()
  },
  computed: {
    passwordConfirmed() {
      let { newPassword,confirmPassword } = this.passwordForm
      return confirmPassword != '' ? newPassword == confirmPassword : null
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    enterEditMode() {
      this.profileForm.firstName = this.userInfo.firstName
      this.profileForm.lastName = this.userInfo.lastName
      this.profileForm.email = this.userInfo.mail
      this.profileForm.birthday = this.userInfo.birthday
      this.editProfileMode = true
    },
    exitEditMode() {
      this.editProfileMode = false
    },
    async fetchUserAvatar() {
      let photo = await this.axios.get(
          process.env.VUE_APP_API_URL + `/api/photo/googleavatar/${this.userInfo.user_id}`
        );
      this.avatar = photo.data.thumbnail_link
    },
    async changePhoto(event) {
      try {
        this.photoLoading = true
        let file = event.target.files[0];
        if (file) {
          let formData = new FormData()
          formData.append('file', file)
          await this.$http.post(process.env.VUE_APP_API_URL +'/api/photo/update', formData)
          this.getUserInfo();
          this.fetchUserAvatar()
          this.$bvToast.toast(`Berhasil mengubah Foto Profil`, {
            title: "Success",
            autoHideDelay: 5000,
            variant: "success",
          });
        }
      } catch (err) {
        this.$fail(`Gagal mengubah Foto Profil`)
      } finally {
        this.photoLoading = false
      }
    },
    async getUserInfo() {
      try {
        const user = await this.axios.get(
          process.env.VUE_APP_API_URL + "/api/me"
        );
        if(user.status == 200) {
          this.searchSiadikUser()
        }
        this.$store.commit("setUserInfo", user.data.user);
      } catch (errors) {
        console.error(errors);
      }
    },
    async changeProfile() {
      try {
        this.profileLoading = true
        this.errorProfile = false
        await this.axios.put(process.env.VUE_APP_API_URL + `/api/profile/update`, {
          email: this.profileForm.email,
          firstname: this.profileForm.firstName,
          lastname: this.profileForm.lastName,
          birthday: this.profileForm.birthday
        });
        this.getUserInfo();
        this.editProfileMode = false
        this.$bvToast.toast(`Berhasil mengubah Data Profil`, {
          title: "Success",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (err) {
        this.errorProfile = true
        this.errorProfileMessage = err.response.data.message ?? 'Error'
        this.$bvToast.toast(`Gagal mengubah Data Profil`, {
          title: "Error",
          autoHideDelay: 5000,
          variant: "danger",
        });
      } finally {
        this.profileLoading = false
      }
    },
    async changePassword() {
      try {
        this.passwordLoading = true
        this.errorPassword = false
        this.successPassword = false
        let response = await this.$axios.post(process.env.VUE_APP_API_URL +'/api/profile/changepassword', {
          old_password: this.passwordForm.oldPassword,
          new_password: this.passwordForm.newPassword,
          confirm_password: this.passwordForm.confirmPassword,
        })
        if (!response.data.status) {
          throw { response: response }
        }
        this.passwordForm.oldPassword = ''
        this.passwordForm.newPassword = ''
        this.passwordForm.confirmPassword = ''
        this.successPassword = true
        this.successPasswordMessage = response.data.message ?? 'Success'
         this.$bvToast.toast(`Berhasil mengubah Password`, {
          title: "Success",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (err) {
        this.errorPassword = true
        this.errorPasswordMessage = err.response.data.message ?? 'Error'
        this.$bvToast.toast(`Gagal mengubah Password`, {
          title: "Error",
          autoHideDelay: 5000,
          variant: "danger",
        });
      } finally {
        this.passwordLoading = false
      }
    }
  },
}
</script>
