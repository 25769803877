<template>
  <header class="sticky-top">
    <b-navbar
      :sticky="true"
      toggleable="lg"
      type="light"
      variant="light"
      class="shadow-sm px-4 w-100"
    >
      <b-navbar-brand to="/">
        <img src="@/assets/img/logo_lppi.jpeg" height="50" alt="logo" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="mr-auto">
          <b-nav-item href="#" class="mr-lg-3">
            <b-dropdown id="dropdown-1" class="m-md-2" variant="primary">
              <template #button-content>
                <span class="font-montserrat-bold">Kelas LPPI</span>
              </template>
              <b-dropdown-item
                v-for="category in courseCategories"
                :key="category.id"
                :to="`/categories/${category.id}/courses`"
                >{{ category.name }}</b-dropdown-item
              >
            </b-dropdown>
          </b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav
          v-if="this.isAuthenticated"
          class="ml-auto d-flex align-items-center text-secondary "
        >
          <h5 class="mb-0">
            <b-badge :variant="badgeColor" class="mr-3">{{ roleName }}</b-badge>
          </h5>
          <span class="font-montserrat-bold mr-3"
            >Halo, {{ userInfo.firstName }} {{ userInfo.lastName }}</span
          >
          <b-dropdown
            size="lg"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            right
            class="font-open-sans"
          >
            <template #button-content>
              <b-img
                v-if="avatar"
                :src="avatar"
                alt="avatar"
                height="40px"
                width="40px"
                rounded="circle"
              />
              <font-awesome-icon v-else icon="user-circle" size="2x" />
            </template>
            <b-dropdown-item href="#">
              <router-link tag="div" :to="{name:'MyProfile'}" class="d-flex align-items-center">
                <b-img
                  v-if="avatar"
                  :src="avatar"
                  alt="avatar"
                  height="40px"
                  width="40px"
                  rounded="circle"
                  class="mr-3"
                />
                <font-awesome-icon
                  v-else
                  icon="user-circle"
                  size="2x"
                  class="mr-3"
                />
                <div>
                  <small class="d-block font-open-sans-bold"
                    >{{ userInfo.firstName }} {{ userInfo.lastName }}</small
                  >
                  <small>{{ userInfo.email }}</small>
                </div>
              </router-link>
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item v-if="isAdmin" to="/users"
              ><small>Manajemen Pengguna</small>
            </b-dropdown-item>
            <b-dropdown-item to="/courses"
              ><small>Manajemen Pelatihan</small>
            </b-dropdown-item>
            <b-dropdown-item to="/skills"
              ><small>Manajemen Keterampilan</small>
            </b-dropdown-item>
            <b-dropdown-item href="https://drive.google.com/file/d/1fItzNibvHyLZHI6bpHwp2S5hgwJrtR0T/view" target="_blank"
              ><small>Tutorial</small>
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.logout-modal
              ><small>Logout</small>
            </b-dropdown-item>
          </b-dropdown>
        </b-navbar-nav>
        <!-- Fallback Login Button -->
        <b-navbar-nav
          v-else
          class="ml-auto d-flex align-items-center text-secondary "
        >
          <b-button variant="primary" to="/login">
            <span class="font-montserrat-bold">Login</span>
          </b-button>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <LogoutModal />
  </header>
</template>

<script>
import LogoutModal from "@/components/LogoutModal.vue";

export default {
  name: "Header",
  components: { LogoutModal },
  data() {
    return {
      data: {},
      user: "",
      profilePicture: "",
      categories: {},
      isAuthenticated: false
    };
  },

  computed: {
    courseCategories() {
      return this.$store.state.courseCategories;
    },

    userInfo() {
      return this.$store.state.userInfo;
    },

    avatar() {
      return this.$store.state.avatar ?? false;
    },

    isAdmin() {
      return this.$store.getters.isAdmin;
    },

    badgeColor() {
      if (this.$store.getters.isAdmin) return "primary";
      else return "secondary";
    },

    roleName() {
      if(this.userInfo.status == '3') {
        return "ADMIN DIVISI"
      } else if(this.userInfo.status == '2') {
        return "COURSE LEADER"
      } else {
        return this.$store.getters.isAdmin ? "ADMIN SYSTEM" : "COURSE LEADER" ;
      }
    },
  },

  mounted() {
    if (this.$store.state.isAuthenticated) {
      this.isAuthenticated = true;
      this.getUserInfo();
      this.getCategories();
    }
  },
  methods: {
    async getUserInfo() {
      try {
        const user = await this.axios.get(
          process.env.VUE_APP_API_URL + "/api/me"
        );
        this.$store.commit("setUserInfo", user.data.user);

        const avatar = await this.axios.get(
          process.env.VUE_APP_API_URL +
            "/api/photo/googleavatar/" +
            user.data.user.id
        );

        if (avatar.data.status) {
          this.$store.commit("setAvatar", avatar.data.thumbnail_link);
        } else {
          this.$store.commit("setAvatar", false);
        }
      } catch (errors) {
        console.error(errors);
      }
    },

    async getCategories() {
      try {
        const response = await this.axios.get(
          process.env.VUE_APP_API_URL + "/api/categories"
        );

        this.$store.commit("setCourseCategories", response.data.categories);
      } catch (errors) {
        console.error(errors);
      }
    },
  },
};
</script>
