<template lang="">
  <div>
    <BannerSmall
      :info="course"
      :title="courseTitle"
      backButton="true"
      backButtonTitle="Daftar Pelatihan"
      :buttonAddGroupEmail="true"
      backButtonHref="/courses"
    />

    <div class="bg-primary">
      <b-container class="py-5">
        <div v-if="courseNotFound">
          <h5 class="text-white">Pelatihan tidak ditemukan.</h5>
        </div>
        <b-row v-else no-gutters>
          <b-col cols="12" sm="12" md="12" lg="3">
            <b-list-group style="border-radius: 5px 0 0 5px !important">
              <b-list-group-item
                v-for="nav in sidebarNavs"
                :key="nav.text"
                :to="toLink(nav.to)"
                active-class="active"
                class="border border-right-0 border-white course-sidenav"
              >
                {{ nav.text }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="12" sm="12" md="12" lg="9" class="bg-white p-4 rounded-right rounded-bottom">
            <router-view />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import BannerSmall from "@/components/BannerSmall.vue";

export default {
  components: { BannerSmall },
  props: ["courseId"],
  data() {
    return {
      course:{},
      courseTitle: "",
      courseNotFound: false,
      sidebarNavs: [
        {
          text: "Pengguna Pelatihan",
          to: "ManageMembers",
        },
        {
          text: "Nilai dan Sertifikat",
          to: "ManageCertificates",
        },
      ],
    };
  },

  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },

  mounted() {
    this.getCourseInfo();
  },

  watch: {
    $route() {
      this.getCourseInfo();
    },
  },

  methods: {
    toLink(link) {
      return {
        name: link,
        params: { id: this.courseId },
      };
    },

    async getCourseInfo() {
      try {
        const response = await this.axios.get(
          process.env.VUE_APP_API_URL + `/api/course/${this.courseId}/info`
        );

        this.course = response.data.course;

        if (Array.isArray(this.course)) {
          this.courseNotFound = true;
          this.courseTitle = "Pelatihan tidak ditemukan.";
        } else {
          if (!this.isAdmin) this.verifyCourseAccess();
          this.courseTitle = this.course.name;
          this.courseNotFound = false;
        }
      } catch (errors) {
        console.error(errors);
      }
    },

    async verifyCourseAccess() {
      try {
        const response = await this.axios.get(
          process.env.VUE_APP_API_URL + `/api/course/${this.courseId}/access`
        );

        const hasAccess = response.data.success;

        if (!hasAccess) this.$router.push("/unauthenticated");
      } catch (errors) {
        console.error(errors);
      }
    },
  },
};
</script>
