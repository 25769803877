<template lang="">
  <b-container class="py-5">
    <b-img
      id="unauthenticated"
      src="@/assets/img/401.jpg"
      alt="401 Error"
      fluid
      class="mb-5"
    ></b-img>
    <h2 class="font-montserrat-bold">401 Error</h2>
    <p>Maaf, anda tidak memiliki akses untuk melihat halaman ini.</p>
    <b-link href="/">Kembali ke halaman utama.</b-link>
  </b-container>
</template>
<script>
export default {
  //
};
</script>

<style>
#unauthenticated {
  height: 20rem;
}
</style>
