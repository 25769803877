<template lang="">
  <div>
    <BannerSmall
      :title="categoryData.name"
      button="true"
      buttonText="Daftar Kategori"
      location="/categories"
    />

    <div class="bg-primary">
      <b-container class="py-5">
        <div class="d-flex justify-content-center">
          <b-button
            variant="light"
            size="lg"
            class="text-center font-open-sans font-weight-bold mb-5"
            v-b-modal.createCourseModal
          >
            Buat Pelatihan Baru
            <font-awesome-icon icon="plus" class="ml-2"></font-awesome-icon>
          </b-button>
        </div>
        <b-spinner
          v-if="loading"
          label="Spinning"
          class="d-block mx-auto text-white"
        ></b-spinner>

        <div v-else-if="emptyResults">
          Belum ada pelatihan pada kategori.
        </div>

        <b-row v-else align-v="stretch">
          <b-col
            cols="6"
            sm="6"
            md="4"
            lg="3"
            v-for="course in courses"
            :key="course.real_id"
            class="mb-5"
          >
            <b-card
              :img-src="require('@/assets/img/lppi-simple-thumbnail.png')"
              img-alt="Image"
              img-top
              tag="article"
              style="max-width: 17rem;"
              class="h-100 font-open-sans position-relative"
            >
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                right
                class="position-absolute"
                style="top: 10px; right: 10px"
              >
                <template #button-content>
                  <font-awesome-icon icon="ellipsis-v"></font-awesome-icon>
                </template>
                <b-dropdown-item @click="manageMembers(course)">
                  Kelola Pengguna
                </b-dropdown-item>
                <b-dropdown-item @click="editCourse(course)">
                  Edit Pelatihan
                </b-dropdown-item>
                <b-dropdown-item @click="archiveCourse(course)">
                  Arsip Pelatihan
                </b-dropdown-item>
              </b-dropdown>
              <b-badge variant="primary" v-if="course.private" class="mr-3">Private</b-badge>
              <b-card-text class="font-weight-bold">
                <p class="mb-1">{{ course.title }}</p>
                <span class="badge-course" v-if="categoryData.name">{{ categoryData.name }}</span>
              </b-card-text>
              <div
                class="d-flex align-items-center mb-2"
                v-for="(teacher, index) in course.teachers.slice(0, 2)"
                :key="index"
              >
                <font-awesome-icon
                  icon="user-circle"
                  size="lg"
                  class="mr-2"
                ></font-awesome-icon>
                <span class="small"
                  >{{ teacher.firstname }} {{ teacher.lastname }}</span
                >
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <createCourseModal
      :categories="categories"
      :categoryCode="categoryData.code"
      @refresh-courses="getCourses"
    />
    <editCourseModal
      :categories="categories"
      :courseData="courseData"
      @refresh-courses="getCourses"
    />
    <deleteCourseModal :courseData="courseData" @refresh-courses="getCourses" />
  </div>
</template>
<style scoped>
.badge-course {
  color: #fff;
  background-color: #6c757d;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
<script>
import BannerSmall from "@/components/BannerSmall.vue";
import CreateCourseModal from "@/components/courses/CreateCourseModal.vue";
import EditCourseModal from "@/components/courses/EditCourseModal.vue";
import DeleteCourseModal from "@/components/courses/DeleteCourseModal.vue";

export default {
  components: {
    BannerSmall,
    CreateCourseModal,
    EditCourseModal,
    DeleteCourseModal,
  },

  props: ["categoryId"],

  data() {
    return {
      courses: [],
      categoryData: "",
      categories: [{ value: "", text: "Tanpa Kategori" }],
      courseData: {},
      loading: false,
      emptyResults: false,
    };
  },

  mounted() {
    this.getCourses();
    this.getCategories();
    this.getCategoryInfo();
  },

  watch: {
    $route() {
      this.getCourses();
      this.getCategories();
      this.getCategoryInfo();
    },
  },

  methods: {
    manageMembers(course) {
      this.$router.push({
        name: "ManageMembers",
        params: {
          id: course.id,
        },
      });
    },

    editCourse(course) {
      this.courseData = course;
      this.$bvModal.show("editCourseModal");
    },

    archiveCourse(course) {
      this.courseData = course;
      this.$bvModal.show("deleteCourseModal");
    },

    getCategories() {
      const categories = this.$store.state.courseCategories;

      categories.forEach((category) => {
        this.categories.push({ value: category.code, text: category.name });
      });
    },

    async getCategoryInfo() {
      const response = await this.axios.get(
        process.env.VUE_APP_API_URL + `/api/category/${this.categoryId}`
      );

      this.categoryData = response.data.category;
    },

    async getCourses() {
      this.loading = true;

      const response = await this.axios.get(
        process.env.VUE_APP_API_URL + `/api/category/${this.categoryId}/courses`
      );

      if (response.data) {
        this.emptyResults = false;

        const data = response.data.courses;
        this.courses = data;
      } else {
        this.emptyResults = true;
      }

      this.loading = false;
    },
  },
};
</script>

<style lang=""></style>
