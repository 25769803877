<template>
  <div class="bg-primary">
    <b-container class="py-5">
      <b-row align-v="stretch">
        <b-col v-if="isAdmin" sm="12" md="4" lg="4" class="my-3">
          <BigCard
            icon="user"
            heading="Manajemen User"
            subheading="Kelola pengguna pada Portal LMS LPPI Classroom+"
            buttonText="Lihat Daftar Pengguna"
            location="/users"
          />
        </b-col>
        <b-col sm="12" md="4" lg="4" class="my-3">
          <BigCard
            icon="chalkboard-teacher"
            heading="Manajemen Pelatihan"
            subheading="Kelola pelatihan pada Portal LMS LPPI Classroom+"
            buttonText="Lihat Daftar Pelatihan"
            location="/courses"
          />
        </b-col>
        <b-col sm="12" md="4" lg="4" class="my-3">
          <BigCard
            icon="award"
            heading="Manajemen Keterampilan"
            subheading="Kelola keterampilan pada Portal LMS LPPI Classroom+"
            buttonText="Lihat Daftar Keterampilan"
            location="/skills"
          />
        </b-col>
        <b-col v-if="isAdmin" sm="12" md="4" lg="4" class="my-3">
          <BigCard
            icon="cogs"
            heading="Manajemen Setting"
            subheading="Kelola pengguna pada Portal LMS LPPI Classroom+"
            buttonText="Lihat Daftar Settingan"
            location="/settings/divisi"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BigCard from "@/components/home/BigCard.vue";

export default {
  name: "Navigation",
  components: {
    BigCard,
  },

  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
};
</script>
