<template>
  <div>
    <BannerSmall
      title="Arsip Pelatihan"
      button="true"
      buttonText="Daftar Pelatihan"
      location="/courses"
    />

    <div class="bg-primary">
      <b-container class="py-5">
        <b-spinner
          v-if="loading"
          label="Spinning"
          class="d-block mx-auto text-white"
        ></b-spinner>

        <div v-else-if="emptyResults">
          Belum ada pelatihan yang diarsip
        </div>

        <b-row v-else align-v="stretch">
          <div class="col-1">
              <b-form-select
              id="per-page-select"
              v-model="perPage"
              @change="toTheStudentPage"
              :options="pageOptions"
              ></b-form-select>
          </div>
          <b-col cols="11" md="11" lg="11">
            <div class="row">
              <div class="col-4">
                <b-form-group class="mb-3">
                  <b-input-group>
                      <b-form-input
                      v-model="search"
                      type="search"
                      @input="debounceInput"
                      placeholder="Cari.."
                      ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </div>
            </div>
          </b-col>
          <b-col
            cols="3"
            v-for="course in courses"
            :key="course.real_id"
            class="mb-5"
          >
            <b-card
              :img-src="require('@/assets/img/lppi-simple-thumbnail.png')"
              img-alt="Image"
              img-top
              tag="article"
              style="max-width: 17rem;"
              class="h-100 font-open-sans position-relative"
            >
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                right
                class="position-absolute"
                style="top: 10px; right: 10px"
              >
                <template #button-content>
                  <font-awesome-icon icon="ellipsis-v"></font-awesome-icon>
                </template>
                <b-dropdown-item @click="restoreCourse(course)">
                  Kembalikan Pelatihan
                </b-dropdown-item>
              </b-dropdown>
              <b-badge variant="primary" v-if="course.private" class="mr-3">Private</b-badge>
              <b-card-text class="font-weight-bold text-left">
                <p class="text-center ">{{ course.title }}</p>
                <div class="small">
                  <p class="font-weight-bold mb-0">Dihapus Oleh:</p>
                  <p>{{ course.deleted_by }}</p>
                  <p class="font-weight-bold mb-0">Tanggal Hapus:</p>
                  {{ course.deleted_at | moment("LLL") }}
                </div>
              </b-card-text>
              <!-- <div
                class="d-flex align-items-center mb-2"
                v-for="(teacher, index) in course.teachers.slice(0, 2)"
                :key="index"
              >
                <font-awesome-icon
                  icon="user-circle"
                  size="lg"
                  class="mr-2"
                ></font-awesome-icon>
                <span class="small"
                  >{{ teacher.firstname }} {{ teacher.lastname }}</span
                >
              </div> -->
            </b-card>
          </b-col>
        </b-row>
        <b-row class="justify-content-between">
          <b-col cols="4"></b-col>
          <b-col cols="4">
              <b-pagination
                  class="ml-auto"
                  align="right"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  @page-click="toTheCourse"
                  aria-controls="my-table"
              ></b-pagination>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <restoreCourseModal
      :courseData="courseData"
      @refresh-courses="getArchivedCourses"
    />
  </div>
</template>

<script>
import _ from "lodash"
import BannerSmall from "@/components/BannerSmall.vue";
import RestoreCourseModal from "@/components/courses/RestoreCourseModal.vue";

export default {
  components: { BannerSmall, RestoreCourseModal },

  data() {
    return {
      search:"",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      courses: [],
      courseData: {},
      loading: false,
      emptyResults: false,
    };
  },

  mounted() {
    this.$moment.locale("id");
    this.getArchivedCourses();
  },

  methods: {
    toTheStudentPage(e) {
      this.perPage = e;
      this.getArchivedCourses()
    },
    debounceInput: _.debounce(function () {
        this.getArchivedCourses()
    }, 500),
    toTheCourse(e, page) {
        this.currentPage = page;
        this.getArchivedCourses()
    },
    categoryName(course) {
      return course?.category?.name;
    },

    restoreCourse(course) {
      this.courseData = course;
      this.$bvModal.show("restoreCourseModal");
    },

    async getArchivedCourses() {
      this.loading = true;
      try {
        const response = await this.axios.get(
          `${process.env.VUE_APP_API_URL}/api/course/archived/ssp?page=${this.currentPage}&pageLength=${this.perPage}&search=${this.search}`
        );

        if (response.data) {
          this.emptyResults = false;
          const data = response.data.courses;
          this.courses = data;
          this.totalRows = response.data.total
        } else {
          this.emptyResults = true;
        }

        this.loading = false;
      } catch (errors) {
        console.error(errors);
      }
    },
  },
};
</script>
