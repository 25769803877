<template>
  <b-card align="center" class="pt-5 shadow-sm h-100">
    <font-awesome-icon :icon="icon" size="4x" class="text-primary mb-4" />
    <h5 class="font-open-sans-bold">{{ heading }}</h5>
    <p class="font-open-sans small">
      {{ subheading }}
    </p>
    <template #footer>
      <b-button
        size="md"
        variant="primary"
        class="font-open-sans"
        :to="location"
        >{{ buttonText }}</b-button
      >
    </template>
  </b-card>
</template>

<script>
export default {
  name: "BigCard",
  props: ["icon", "heading", "subheading", "buttonText", "location"],
};
</script>
