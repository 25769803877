<template>
  <b-container>
    <b-modal id="ImportResult"
      title="Hasil Import Peserta"
      hide-footer size="lg" header-border-variant="none">
      <div class="row">
        <div class="col-6">
          <b-form-group class="mb-3">
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Cari Peserta"
              ></b-form-input>

              <b-input-group-append>
                <b-button variant="primary" @click="filter = ''">
                  <font-awesome-icon icon="search" class="text-white" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
        <div class="col-12 mb-4">
          <b-table
            striped
            responsive
            :fields="fieldsImport"
            :items="studentsImport"
            :per-page="perPage"
            :current-page="currentPageImport"
            :total-rows="totalRowsImport"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            class="font-roboto"
          >
            <template #cell(success)="{item}">
              <b-badge :variant="item.success ? 'success' : 'danger'" class="mr-3">{{ item.success ? 'Berhasil' : 'Tidak berhasil' }}</b-badge>
            </template>
          </b-table>
          <b-col cols="4">
            <b-pagination
              class="ml-auto"
              align="right"
              v-model="currentPageImport"
              :total-rows="totalRowsImport"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </b-col>
        </div>
      </div>
    </b-modal>
    <b-modal id="UploadStudent" title="Upload Peserta" hide-footer>
      <div class="row">
        <div class="col-9 mb-4">
          <!-- accept=".csv,.xlsx,.xls" -->
          <b-form-group label="Upload file:" label-for="" class="mb-0">
            <b-form-file
              v-model="filePeserta"
              @change="onChangeFilter"
              accept=".xls"
              :state="Boolean(filePeserta)"
              placeholder="Choose an file or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
          </b-form-group>
        </div>
        <div class="col-3" style="padding-top:30px;">
          <b-button variant="primary" @click="uploadPeserta"><b-spinner small v-if="loadingUpload"></b-spinner>Upload</b-button> 
        </div>
      </div>
      <a href="/files/template_uploads.xls">Download Template <font-awesome-icon icon="download"></font-awesome-icon></a>
    </b-modal>
    <div class="row my-3">
      <div class="col-6 text-left">
        <h4 class="font-open-sans-bold">Daftar Peserta</h4>
      </div>
      <div class="col-6 text-right">
        <h5 v-if="!loading" class="font-open-sans-bold ">
          <ICountUp :endVal="totalRows" />
          Peserta
        </h5>
        <b-button
          v-if="studentsImport.length > 0"
          @click="showImportResult"
          variant="primary"
          size="sm"
          class="font-open-sans-bold"
        >
          <font-awesome-icon
              icon="exclamation-circle"
              class="small mr-2"
            ></font-awesome-icon>
          <span>Lihat Hasil Import</span>
        </b-button>
      </div>
    </div>
    <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
    <div v-else-if="emptyResults">
      <p>Belum ada peserta yang terdaftar.</p>
      <b-button size="" variant="primary" class="mr-3" @click="uploadStudent">
        <font-awesome-icon icon="upload"></font-awesome-icon> Import
      </b-button>
      <b-button
        size=""
        variant="primary"
        class="mr-2"
        v-b-modal.addStudentModal
      >
        <span class="font-weight-bold font-open-sans mr-2"
          >Tambah
        </span>
        <font-awesome-icon icon="plus"></font-awesome-icon>
      </b-button>
    </div>

    <div v-else>
      <b-row class="w-100 justify-content-between ">
        <!-- Filter / Search -->
        <b-col cols="5">
          <b-form-group class="mb-3">
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Cari Peserta"
              ></b-form-input>

              <b-input-group-append>
                <b-button variant="primary" @click="filter = ''">
                  <font-awesome-icon icon="search" class="text-white" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- Add Student -->
        <b-col cols="5" class="text-right mb-3">
          <b-button size="" variant="primary" class="mr-3" @click="uploadStudent">
            <font-awesome-icon icon="upload"></font-awesome-icon> Import
          </b-button>
          <b-button
            size=""
            variant="primary"
            class="mr-2"
            v-b-modal.addStudentModal
          >
            <span class="font-weight-bold font-open-sans mr-2"
              >Tambah
            </span>
            <font-awesome-icon icon="plus"></font-awesome-icon>
          </b-button>
          <b-button size="" variant="primary" class="" @click="getData">
            <font-awesome-icon icon="sync"></font-awesome-icon>
          </b-button>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table
        striped
        responsive
        :fields="fields"
        :items="students"
        :per-page="perPage"
        :current-page="currentPage"
        :total-rows="totalRows"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        class="font-roboto"
      >
        <template #cell(index)="row">
          {{ row.index + 1 }}
        </template>

        <template #cell(firstname)="{item}">
          {{ item.firstname }} {{ item.lastname }}
        </template>

        <!-- Edit and Delete Buttons -->
        <template #cell(actions)="row">
          <b-link
            v-b-tooltip.hover
            title="Keluarkan Peserta"
            class="text-secondary"
            @click="removeStudent(row.item)"
          >
            <font-awesome-icon icon="trash" class="" />
          </b-link>
        </template>
      </b-table>
      <b-row class="justify-content-between">
        <b-col cols="4">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="5"
            label-cols-lg="4"
            label-align-sm="right"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-pagination
            class="ml-auto"
            align="right"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
    <addStudentModal
      :unregisteredStudents="unregisteredStudents"
      title="Tambah Peserta"
      @reload-table="getData"
    />
    <removeStudentModal
      title="Keluarkan Peserta"
      :userData="userData"
      @reload-table="getData"
    />
  </b-container>
</template>

<script>
let XLSX = require("xlsx");
import AddStudentModal from "@/components/courses/members/AddStudentModal.vue";
import RemoveStudentModal from "@/components/courses/members/RemoveStudentModal.vue";

export default {
  components: {
    AddStudentModal,
    RemoveStudentModal,
  },
  computed: {
    studentsImport() {
      return this.$store.getters.getImportedStudent;
    },
  },
  data() {
    return {
      loadingUpload:false,
      filePeserta: null,
      filesReal: null,
      value: "",
      students: [],
      unregisteredStudents: [],
      fieldsImport: [
        {
          key: "no",
          label: "No.",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "success",
          label: "Result",
        },
        {
          key: "message",
          label: "Message",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No.",
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "firstname",
          label: "Nama Lengkap",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      totalRows: 1,
      totalRowsImport: 1,
      currentPage: 1,
      currentPageImport:1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      userData: {},
      loading: true,
      emptyResults: false,
    };
  },

  mounted() {
    this.$store.commit("setImportedStudents", []);
    this.getData();
  },

  watch: {
    $route() {
      this.getData();
    },
  },

  methods: {
    processExcel(datas) {
      var workbook = XLSX.read(datas, {
        type: 'binary'
      });

      var data = this.to_json(workbook);
      return data
    },
    to_json(workbook) {
      let rowObject = {};
      workbook.SheetNames.forEach(function(sheetName) {
        rowObject = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[sheetName]
        );
      });
      return rowObject
    },
    onChangeFilter(evt) {
      var f = evt.target.files[0];
      if (f) {
        var r = new FileReader();
        r.onload = async e => {
          let resultJson = this.processExcel(e.target.result);
          const keys = Object.keys(resultJson[0]);
        
          const commaSeparatedString = [keys.join(",") , resultJson.map(row => keys.map(key => row[key]).join(",")).join("\n")].join("\n")
          const csvBlob = new Blob([commaSeparatedString])
          this.filesReal = csvBlob;
        }
        r.readAsBinaryString(f);
      } else {
        console.log("Failed to load file");
      }
    },
    showImportResult() {
      this.$bvModal.show("ImportResult");
    },
    async uploadPeserta() {
      this.loadingUpload = true
      try {
        let formData = new FormData()
        formData.append('csv', this.filesReal)
        formData.append('course_id', this.$route.params.id)
        let res = await this.axios.post(
          process.env.VUE_APP_API_URL + "/api/course/add/student/import",
          formData
        );
        if(res.status == 200) {
          this.getData()
          res.data.forEach((e,index) => {
            e.no = index+1
          });
          this.totalRowsImport = res.data.length
          this.$store.commit("setImportedStudents",res.data);
          this.$bvToast.toast("Berhasil mengimport file, silahkan lihat hasilnya", {
            title: "Success",
            autoHideDelay: 5000,
            variant: "success",
          });
          this.$bvModal.hide("UploadStudent");
        }
      } catch (error) {
        console.log(error.response)
        this.$bvToast.toast(error.response.data.detail, {
          title: "Error",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
      this.loadingUpload = false
    },
    uploadStudent() {
      this.$bvModal.show("UploadStudent");
    },
    removeStudent(user) {
      this.userData = user;
      this.userData.role = "student";
      this.$bvModal.show("removeStudentModal");
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getStudents(courseId) {
      // Empty table before fetching new data
      this.students = [];

      return this.axios.post(
        process.env.VUE_APP_API_URL + "/api/course/student",
        courseId
      );
    },

    getUnregisteredStudents(courseId) {
      // Refresh list of unregistered students
      this.unregisteredStudents = [];

      return this.axios.post(
        process.env.VUE_APP_API_URL + "/api/course/student/unregistered",
        courseId
      );
    },

    getData() {
      this.loading = true;

      const courseId = {
        course_id: parseInt(this.$route.params.id),
      };

      Promise.all([
        this.getStudents(courseId),
        this.getUnregisteredStudents(courseId),
      ])
        .then((results) => {
          const students = results[0].data;
          const unregisteredStudents = results[1].data;

          if (students.success) {
            this.emptyResults = false;

            const data = students.students;
            data.forEach((student) => {
              this.students.push(student.user);
            });
            this.students.forEach((e,index) => {
              e.no = index+1
            })
            this.totalRows = this.students.length;
          } else {
            this.emptyResults = true;
            this.totalRows = 0;
          }

          unregisteredStudents.students.forEach((student) => {
            this.unregisteredStudents.push({
              code: student.id,
              label: `${student.firstname} ${student.lastname} (${student.email})`,
            });
          });

          this.loading = false;
        })
        .catch((errors) => console.error(errors));
    },
  },
};
</script>
