<template>
  <b-modal id="addStudentModal" title="Tambah Peserta" hide-footer no-close-on-backdrop>
    <b-form @submit.stop.prevent="onSubmit">
      <v-select v-model="student" :options="unregisteredStudents" transition="" class="mb-3">
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!student" v-bind="attributes" v-on="events" />
        </template>
      </v-select>

      <b-button type="submit" variant="primary" class="float-right"><b-spinner small v-if="loading"></b-spinner><span
          v-else>Tambah</span>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  name: "AddStudentModal",
  props: ["title", "unregisteredStudents"],

  data() {
    return {
      student: "",
      loading: false,
      toastCount: 0,
    };
  },

  methods: {
    onSubmit() {
      this.loading = true;

      try {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            this.loading = false;

            return;
          }

          this.submitData();
        });
      } catch (errors) {
        console.log(errors);
      }
    },

    async submitData() {
      this.loading = true;
      const formData = {
        course_id: parseInt(this.$route.params.id),
        user_id: this.student.code,
      };
      await this.axios.post(
        process.env.VUE_APP_API_URL + "/api/course/add/student",
        formData
      ).then(res => {
        console.log(res)
        this.$bvModal.hide("addStudentModal");
        this.student = "";
        this.$emit("reload-table");
      }).catch(err => {
        this.$bvModal.hide("addStudentModal");
        if (err.response) {
          if (err.response.status == 500) {
            this.toastCount++;
            this.$bvToast.toast("Berhasil ditambahkan, tapi email tidak terdeteksi sebagai email aktif", {
              title: 'Warning',
              autoHideDelay: 5000,
              variant: 'warning',
            });
          }
        } else if (err.request) {
          this.toastCount++;
          this.$bvToast.toast(err.request, {
            title: 'Failed',
            autoHideDelay: 5000,
            variant: 'danger',
          });
        } else {
          this.toastCount++;
          this.$bvToast.toast("Unexpected error", {
            title: 'Failed',
            autoHideDelay: 5000,
            variant: 'danger',
          });
        }
        this.student = "";
        this.$emit("reload-table");
      });
      this.loading = false;
      // try {
      //   const formData = {
      //     course_id: parseInt(this.$route.params.id),
      //     user_id: this.student.code,
      //   };

      //   const response = await this.axios.post(
      //     process.env.VUE_APP_API_URL + "/api/course/add/student",
      //     formData
      //   );

      //   const data = response.data;
      //   const status = data.success ? "Success" : "Failed";
      //   const variant = data.success ? "success" : "danger";
      //   const message = data.message;

      //   this.$bvModal.hide("addStudentModal");

      //   // Show Toast
      //   this.toastCount++;
      //   this.$bvToast.toast(message, {
      //     title: status,
      //     autoHideDelay: 5000,
      //     variant: variant,
      //   });

      //   if (data.success) {
      //     // Reload students table
      //     this.student = "";
      //     this.$emit("reload-table");
      //   }
      // } catch (e) {
      //   console.error(e.response);
      // } finally {
      //   this.loading = false;
      // }
    },
  },
};
</script>
