<template>
  <b-modal id="editUserModal" ref="editUserModal" :title="title" hide-footer no-close-on-backdrop>
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group
        id="firstname-group"
        label="Nama Depan:"
        label-for="firstname"
      >
        <b-form-input
          id="firstname"
          name="firstname"
          v-model="userX.firstname"
          placeholder=""
          v-validate="'required'"
          :state="validateState('firstname')"
        ></b-form-input>
        <b-form-invalid-feedback>
          Nama depan wajib diisi.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="lastname-group"
        label="Nama Belakang:"
        label-for="lastname"
      >
        <b-form-input
          id="lastname"
          name="lastname"
          v-model="userX.lastname"
          placeholder=""
          v-validate="'required'"
          :state="validateState('lastname')"
        ></b-form-input>
        <b-form-invalid-feedback>
          Nama belakang wajib diisi.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="email-group" label="Email:" label-for="email">
        <b-form-input
          id="email"
          name="email"
          v-model="userX.email"
          placeholder=""
          v-validate="'required|email'"
          :state="validateState('email')"
        ></b-form-input>
        <b-form-invalid-feedback>
          Email wajib diisi menggunakan email yang valid.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="password-group"
        label="Ubah Password (opsional, minimal 8 karakter):"
        label-for="password"
      >
        <b-input-group>
          <b-form-input
            type="password"
            id="password"
            name="password"
            v-model="password"
            placeholder=""
            v-validate="'min:8'"
            :state="validateState('password')"
            ref="password"
          ></b-form-input>
          <b-input-group-append @click="togglePasswordVisibility">
            <b-input-group-text class="hover-pointer">
              <font-awesome-icon icon="eye"></font-awesome-icon>
            </b-input-group-text>
          </b-input-group-append>
          <b-form-invalid-feedback>
            Password wajib diisi dengan minimal 8 karakter.
          </b-form-invalid-feedback>
        </b-input-group>
      </b-form-group>

      <b-form-group
        id="password-confirm-group"
        label="Konfirmasi Password"
        label-for="password-confirm"
      >
        <b-input-group>
          <b-form-input
            type="password"
            id="password-confirm"
            name="password-confirm"
            v-model="passwordConfirm"
            placeholder=""
            v-validate="password ? 'required|confirmed:password' : ''"
            :state="validateState('password-confirm')"
          ></b-form-input>
          <b-input-group-append @click="togglePasswordConfirmVisibility">
            <b-input-group-text class="hover-pointer">
              <font-awesome-icon icon="eye"></font-awesome-icon>
            </b-input-group-text>
          </b-input-group-append>
          <b-form-invalid-feedback>
            Konfirmasi password tidak sama.
          </b-form-invalid-feedback>
        </b-input-group>
      </b-form-group>

      <b-form-group id="role-group" label="Role:" label-for="role">
        <b-form-select
          id="role"
          name="role"
          v-model="userX.role"
          :options="roles"
          v-validate="'required'"
          :state="validateState('role')"
          @input="changeData"
        ></b-form-select>
        <b-form-invalid-feedback>
          Role harus diisi
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group v-if="formRole == 'admindivisi' || formRole == 'courseleader'" label="Divisi:" label-for="role">
        <v-select
          v-model="divisiId"
          :disabled="userInfo.status == '3' && formRole == 'courseleader'"
          :options="divisiOptions"
          class="mb-3"
          label="nama_divisi"
          :reduce="e=> e.id"
        />
      </b-form-group>
      <b-button type="submit" variant="primary" class="float-right"
        ><b-spinner small v-if="loading"></b-spinner><span v-else>Submit</span>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  name: "EditUserModal",
  props: ["title", "role", "userData"],
  data() {
    return {
      formRole: '',
      divisiOptions:[],
      roles: [
        { value: "admin", text: "Admin System" },
        { value: "admindivisi", text: "Admin Divisi" },
        { value: "teacher", text: "Pengajar" },
        { value: "courseleader", text: "Course Leader (CL)" },
        { value: "student", text: "Peserta" },
      ],
      divisiId: null,
      userX:{},
      password: "",
      passwordConfirm: "",
      loading: false,
      toastCount: 0,
    };
  },
  async mounted() {
    try {
        const res = await this.axios.get(
            process.env.VUE_APP_API_URL + "/api/divisi/list"
        );
        if(res.status == 200) {
            this.divisiOptions = res.data.data;
        }
    } catch (errors) {
        console.error(errors);
    }
  },
  computed: {
     userInfo() {
      return this.$store.state.userInfo;
    },
  },
  watch: {
    userData() {
      console.log(this.userData)
      this.userX.email = this.userData.email
      this.userX.firstname = this.userData.firstname
      this.userX.lastname = this.userData.lastname
      this.userX.role = this.userData.status == 3 ? "admindivisi" : this.userData.role
      this.divisiId = this.userData.hr_dept_id
      this.formRole =  this.userData.status == 3 ? "admindivisi" : this.userData.role
      this.disableThingRole(this.formRole)
    }
  },
  methods: {
    disableThingRole(val) {
      if(val == "courseleader" && this.userInfo.status == "3") {
        this.divisiId = this.userInfo.hr_dept_id ? parseInt(this.userInfo.hr_dept_id) : null
      }
    },
    changeData(e) {
      this.formRole = e
    },
    togglePasswordVisibility() {
      const passwordField = document.querySelector("#password");

      if (passwordField.getAttribute("type") === "password")
        passwordField.setAttribute("type", "text");
      else passwordField.setAttribute("type", "password");
    },

    togglePasswordConfirmVisibility() {
      const passwordConfirmField = document.querySelector("#password-confirm");

      if (passwordConfirmField.getAttribute("type") === "password")
        passwordConfirmField.setAttribute("type", "text");
      else passwordConfirmField.setAttribute("type", "password");
    },

    async submitData() {
      const formData = {
        user_id: this.userData.id,
        firstname: this.userX.firstname.trim(),
        lastname: this.userX.lastname.trim(),
        email: this.userX.email,
        password: this.password,
        role: this.userX.role,
        id_divisi : this.formRole == 'admindivisi' || this.formRole == 'courseleader' ? this.divisiId : null
      };

      try {
        const response = await this.axios.put(
          process.env.VUE_APP_API_URL + "/api/user/update",
          formData
        );

        const data = response.data;
        const status = data.success ? "Success" : "Failed";
        const variant = data.success ? "success" : "danger";
        const message = data.message;

        this.loading = false;
        this.$bvModal.hide("editUserModal");

        // Show Toast
        this.toastCount++;
        this.$bvToast.toast(message, {
          title: status,
          autoHideDelay: 5000,
          variant: variant,
        });

        if (data.success) {
          // Refresh table
          this.$emit("reload-table");
        }
      } catch (errors) {
        console.error(errors);
      }
    },

    onSubmit() {
      this.loading = true;

      try {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            this.loading = false;

            return;
          }

          this.submitData();
        });
      } catch (errors) {
        console.log(errors);
      }
    },
  },
};
</script>
