<template>
  <div>
    <BannerSmall
      title="Manajemen User"
      button="true"
      buttonText="Arsip"
      location="/users/archive"
      backButton="true"
      backButtonTitle="Halaman Utama"
      backButtonHref="/"
    />
    <div class="bg-primary">
      <div class="container py-5">
        <b-card>
          <b-tabs content-class="mt-3">
            <b-tab title="Peserta" active lazy><Students /></b-tab>
            <b-tab title="Pengajar" lazy><Teachers /></b-tab>
            <b-tab title="Course Leader (CL)" v-if="isAdmin || userInfo.status == '3'" lazy>
              <CourseLeaders />
            </b-tab>
            <b-tab title="Admin System" v-if="isAdmin" lazy><Admins /></b-tab>
            <b-tab title="Admin Divisi" v-if="isAdmin" lazy><AdminDivisi /></b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import BannerSmall from "@/components/BannerSmall.vue";
import Teachers from "@/components/users/Teachers.vue";
import Students from "@/components/users/Students.vue";
import CourseLeaders from "@/components/users/CourseLeaders.vue";
import Admins from "@/components/users/Admins.vue";
import AdminDivisi from "@/components/users/AdminDivisi.vue";

export default {
  name: "Users",
  components: {
    AdminDivisi,
    BannerSmall,
    Teachers,
    CourseLeaders,
    Students,
    Admins,
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    isCl() {
      return this.$store.getters.isCl;
    },
    isAD() {
      return this.$store.getters.isAD;
    },
    isAdmin() {
      return this.$store.getters.realAdmin;
    },
  },
};
</script>
