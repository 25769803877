<template>
<b-modal
  id="editCourseModal"
  title="Edit Pelatihan"
  ref="editCourseModal"
  hide-footer
  size="lg"
  >
   <b-tabs active content-class="mt-3">
    <b-tab title="Pelatihan" lazy>
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group id="title-group" label="Judul Pelatihan:" label-for="title">
          <b-form-input
            id="title"
            name="title"
            v-model="courseData.title"
            placeholder=""
            v-validate="'required'"
            :state="validateState('title')"
          ></b-form-input>
          <b-form-invalid-feedback>
            Judul pelatihan wajib diisi.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="category-group" label="Kategori:" label-for="category">
          <b-form-select
            id="category"
            name="category"
            v-model="courseData.category_code"
            :options="categories"
          ></b-form-select>
          <b-form-invalid-feedback> </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Banner:" label-for="">
          <img v-if="courseData.thumbnail_url" :src="courseData.thumbnail_url" alt="" class="my-2" width="100">
          <b-form-file
            v-model="fileBanner"
            accept="image/*"
            :state="Boolean(fileBanner)"
            placeholder="Choose an imange to change image"
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-form-group>
        <div class="row my-3">
          <div class="col-6">
            <b-form-checkbox v-model="courseData.privasi" name="check-button" switch><span style="cursor:pointer;font-weight:bold;">Private</span></b-form-checkbox>
          </div>
          <div class="col-6">
            <b-form-checkbox v-model="isNotif" name="check-button" switch><span style="cursor:pointer;font-weight:bold;">Notification</span></b-form-checkbox>
          </div>
        </div>
        <b-button type="submit" variant="primary" class="float-right"
        ><b-spinner small v-if="loading"></b-spinner><span v-else>Submit</span>
        </b-button>
      </b-form>
    </b-tab>
    <b-tab title="SIADIK" lazy>
      <div class="row">
        <div class="col-12">
          <label for="">Group SIADIK</label>
          <v-select
            :loading="loadingGroup"
            v-model="kodeGroupSiadik"
            :options="groupsSiadik"
            class="mb-3 siadik-group"
            label="nama_group_program"
            :reduce="e=> e.kd_group_program"
            placeholder="Pilih Group"
            @input="selectGroup"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="">Program SIADIK</label>
          <v-select
            :loading="loadingProgram"
            v-model="kodeProgramSiadik"
            :options="programsSiadik"
            class="mb-3 siadik-group"
            label="nama_program"
            :reduce="e=> e.kd_program"
            placeholder="Pilih Program"
            @input="selectJadwal"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="">Jadwal SIADIK</label>
          <v-select
            :loading="loadingJadwal"
            v-model="kodeJadwalSiadik"
            :options="jadwalSiadik"
            class="mb-3 siadik-group"
            label="nama_angkatan"
            :reduce="e=> e.kd_dt_jadwal"
            placeholder="Pilih Jadwal"
            @input="selectKelas"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="">Kelas SIADIK</label>
          <v-select
            :loading="loadingKelas"
            v-model="kodeKelas"
            :options="kelasSiadik"
            class="mb-3 siadik-group"
            label="nama_kelas"
            :reduce="e=> e.kd_dt_kelas"
            placeholder="Pilih Kelas"
          />
        </div>
      </div>
      <b-button type="button" variant="primary" class="float-right" @click="integrasiSiadik">
        <b-spinner small v-if="loadingSiadik"></b-spinner><span v-else>Integrasi Siadik</span>
      </b-button>
    </b-tab>
  </b-tabs>
</b-modal>
</template>

<script>
export default {
  name: "EditCourseModal",
  props: ["courseData", "categories"],

  data() {
    return {
      siadik:false,
      fileBanner: null,
      password: "",
      passwordConfirm: "",
      loading: false,
      toastCount: 0,
      loadingSiadik: false,
      loadingGroup:false,
      loadingProgram:false,
      loadingJadwal:false,
      loadingKelas:false,
      kodeKelas: null,
      kodeGroupSiadik: null,
      kodeProgramSiadik: null,
      kodeJadwalSiadik: null,
      groupsSiadik: [],
      programsSiadik: [],
      jadwalSiadik: [],
      kelasSiadik: [],
      isNotif: null
    };
  },
  async mounted() {
    try {
      const res = await this.axios.get(
        process.env.VUE_APP_API_SIADIK + "/api/siadik/program/group/0",
      );
      res.data.data.forEach(e => {
        e.nama_group_program = `(${e.kd_group_program}) ${e.nama_group_program}`
      });
      this.groupsSiadik = res.data.data;
    } catch (e) {
        console.log(e)
    }

  },
  watch: {
    courseData(val) {
      this.isNotif = val.notif ? true : false;
      if(this.courseData.integrate_siadik) {
          this.kodeGroupSiadik = this.courseData.integrate_siadik.group_program_siadik_id
          if(this.kodeGroupSiadik) {
            this.siadik = true;
            this.selectGroup(this.kodeGroupSiadik)
          }
          this.kodeProgramSiadik = this.courseData.integrate_siadik.program_siadik_id
          if(this.kodeProgramSiadik) {
            this.selectJadwal(this.kodeProgramSiadik)
          }

          this.kodeJadwalSiadik = this.courseData.integrate_siadik.program_jadwal_siadik_id
          if(this.kodeJadwalSiadik) {
            this.selectKelas(this.kodeJadwalSiadik)
          }
          this.kodeKelas = parseInt(this.courseData.integrate_siadik.Program_kelas_siadik_id)
      } else {
        this.resetSiadik()
      }
    }
  },
  methods: {
    async selectKelas(e) {
      this.loadingKelas = true
      try {
        const res = await this.axios.get(
          process.env.VUE_APP_API_SIADIK + `/api/siadik/program/kelas/${e}`,
        );
        res.data.data.forEach(e => {
          e.nama_kelas = `(${e.kd_dt_kelas}) ${e.nama_kelas}`
        });
        this.kelasSiadik = res.data.data;
      } catch (e) {
          console.log(e)
      }
      this.loadingKelas = false
    },
    async selectJadwal(e) {
      if(!e) {
        this.kelasSiadik = []
        this.jadwalSiadik = []
        this.kodeKelas = null
        this.kodeJadwalSiadik = null
      }
      this.loadingJadwal = true
      try {
        const res = await this.axios.get(
          process.env.VUE_APP_API_SIADIK + `/api/siadik/program/jadwal/${e}`,
        );
         res.data.data.forEach(e => {
          e.nama_angkatan = `(${e.kd_dt_jadwal}) ${e.nama_angkatan}`
        });
        this.jadwalSiadik = res.data.data;
      } catch (e) {
          console.log(e)
      }
      this.loadingJadwal = false
    },
    resetSiadik() {
      this.siadik = false
      this.jadwalSiadik = []
      this.kelasSiadik = []
      this.programsSiadik = []
      this.kodeKelas = null
      this.kodeGroupSiadik = null
      this.kodeProgramSiadik = null
      this.kodeJadwalSiadik = null
    },
    async selectGroup(e) {
      if(!e) {
        this.resetSiadik()
      }
      this.loadingProgram = true
      try {
        const res = await this.axios.get(
          process.env.VUE_APP_API_SIADIK + `/api/siadik/program/program/${e}`,
        );
        res.data.data.forEach(e => {
          e.nama_program = `(${e.kd_program}) ${e.nama_program}`
        });
        this.programsSiadik = res.data.data;
      } catch (e) {
          console.log(e)
      }
      this.loadingProgram = false
    },
    async integrasiSiadik() {
      if(!this.kodeGroupSiadik || !this.kodeProgramSiadik || !this.kodeJadwalSiadik || !this.kodeKelas) {
        this.$bvToast.toast("Silahkan lengkapi data anda sebelum integrasi siadik", {
          title: "Failed",
          autoHideDelay: 10000,
          variant: "danger",
        });
        return;
      }
      this.loadingSiadik = true;
      const payload = {
        course_id: this.courseData.id,
        group_program_siadik_id: this.kodeGroupSiadik,
        program_siadik_id: this.kodeProgramSiadik,
        program_jadwal_siadik_id: this.kodeJadwalSiadik,
        program_kelas_siadik_id: this.kodeKelas
      }
      try {
        let res = await this.axios.post(
          process.env.VUE_APP_API_URL + "/api/course/integrate/siadik",
          payload
        );
        this.$bvToast.toast(res.data.message, {
          title: res.data.success ? "Success" : "Failed",
          autoHideDelay: 10000,
          variant: res.data.success ? "success" : "danger",
        });
        this.loadingSiadik = false;
      } catch (e) {
         this.loadingSiadik = false;
        console.log(e)
      }
    },
    async submitData() {
      const formData = {
        course_id: this.courseData.id,
        title: this.courseData.title,
        category_code: this.courseData.category_code,
        access_type: 1,
        allow_unsubscribe: false,
        expiration_date: null,
        private: this.courseData.privasi
      };

      try {
        const response = await this.axios.put(
          process.env.VUE_APP_API_URL + "/api/course/update",
          formData
        );
        let formDatas = new FormData()
        formDatas.append('course_id', this.courseData.id)
        formDatas.append('image', this.fileBanner)
        this.axios.post(
          process.env.VUE_APP_API_URL + "/api/course/image",
          formDatas
        );
        this.axios.put(
          process.env.VUE_APP_API_URL + "/api/course/notification",
          {
            course_id: this.courseData.id,
            value: this.isNotif ? 1 : 0
          }
        );

        const data = response.data;
        const status = data.success ? "Success" : "Failed";
        const variant = data.success ? "success" : "danger";
        const message = data.message;

        this.loading = false;
        this.$bvModal.hide("editCourseModal");

        // Show Toast
        this.toastCount++;
        this.$bvToast.toast(message, {
          title: status,
          autoHideDelay: 5000,
          variant: variant,
        });

        // Refresh courses
        this.$emit("refresh-courses");
      } catch (errors) {
        console.error(errors);
      }
    },

    onSubmit() {
      this.loading = true;

      try {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            this.loading = false;

            return;
          }

          this.submitData();
        });
      } catch (errors) {
        console.log(errors);
      }
    },
  },
};
</script>
<style>
#editCourseModal {
  overflow: scroll !important; /* Show scrollbars */
}
.siadik-group > .vs__dropdown-menu{
  max-height: 230px !important;
}
</style>
