<template>
  <b-container>
    <b-modal id="confirm-modal" title="Confirm" v-model="openModal">
      <p class="mb-0">Pastikan nama lengkap peserta sudah benar !!</p>
      <template #modal-footer>
        <div class="float-right">
          <b-button
            @click="openModal = false"
            variant="secondary"
            class="mr-2"
          >
            Cancel
          </b-button>
          <b-button variant="success" @click="generateCertificate(idAssessment)">
            Continue
          </b-button>
        </div>
      </template>
    </b-modal>
    <div class="d-flex w-100 justify-content-between align-items-center mb-3">
      <div class="d-flex align-items-center">
        <h4 class="font-open-sans-bold mb-0 mr-2">Daftar Nilai</h4>
        <b-link
          v-b-tooltip.hover
          title="Mengenai Skema Penilaian"
          class="text-secondary mr-3"
          v-b-modal.assesssment-info-modal
        >
          <font-awesome-icon icon="question-circle"></font-awesome-icon>
        </b-link>
      </div>
      <div v-if="!loading && !emptyResults" class="d-flex font-open-sans">
        <b-card class="shadow-sm mr-3 ">
          Nilai Minimum Sertifikasi:
          <span class="font-weight-bold">
            {{ assessment.certificate_min_score }}</span
          >
        </b-card>
      </div>
    </div>
    <div v-if="loading" variant="primary" class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
        <div>
            <p> Mohon tunggu sebentar system sedang menghitung nilai akhir</p>
        </div>
    </div>
    <div v-else-if="emptyResults">
      <p>Belum ada nilai peserta saat ini.</p>
    </div>

    <div v-else>
      <b-row class="w-100 justify-content-between ">
        <b-col cols="5">
          <b-form-group class="mb-3">
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Cari Peserta"
              ></b-form-input>

              <b-input-group-append>
                <b-button variant="primary" @click="filter = ''">
                  <font-awesome-icon icon="search" class="text-white" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="primary"
            class="font-open-sans mr-3"
            @click="confirmModal(assessment.id)"
          >
            <b-spinner small v-if="generateLoading"></b-spinner>
            <span v-else>Generate Sertifikat</span>
          </b-button>
          <b-button variant="primary" @click="getAssessment">
            <font-awesome-icon icon="sync"></font-awesome-icon>
          </b-button>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table
        striped
        responsive
        :fields="fields"
        :items="grades"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        class="font-roboto"
      >
        <template #cell(index)="row">
          {{ row.index + 1 }}
        </template>

        <template #cell(user)="{item}">
          <div>
            {{ item.user.first_name }} {{ item.user.last_name }}
          </div>
        </template>
        <!-- Dynamic assessment item column fields -->
        <template
          v-for="(field, index) in assessmentItems"
          v-slot:[`head(${field.name})`]="{ label }"
        >
          <div :key="index">
            {{ label }}
          </div>
        </template>

        <template
          v-for="(field,id) in assessmentItems"
          v-slot:[`cell(${field.name})`]="{ item }"
        >
          <!-- {{ getScore(item.components, field) }} -->
          <div :key="id">
            <p class="font-weight-bold">{{ getScore(item.components, field).calScore }}</p>
            <p>({{ getScore(item.components, field).score }})</p>
          </div>
        </template>

        <template #head(Total)="{ label }">
          <div>
            {{ label }}
          </div>
        </template>

        <template #cell(Total)="{ item }">
         <p class="font-weight-bold"> {{ roundTotal(item.Total) }} </p>
        </template>

        <template #cell(sertifikasi)="{ item }">
          <h5>
            <font-awesome-icon
              v-if="roundTotal(item.components.total) >= assessment.certificate_min_score"
              icon="check"
              class="text-success"
            >
            </font-awesome-icon>
          </h5>
        </template>
      </b-table>
      <b-row class="justify-content-between">
        <b-col cols="4">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="5"
            label-cols-lg="4"
            label-align-sm="right"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-pagination
            class="ml-auto"
            align="right"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
    <AssessmentInfoModal />
  </b-container>
</template>

<script>
import AssessmentInfoModal from "@/components/courses/certificates/AssessmentInfoModal";

export default {
  components: { AssessmentInfoModal },

  data() {
    return {
      assessment: {},
      grades: [],
      fields: [
        {
          key: "no",
          label: "No.",
        },
        {
          key: "user.username",
          label: "Email",
          sortable: true,
        },
        {
          key: "user",
          label: "Nama Lengkap",
          sortable: true,
        },
        {
          key: "components.total",
          label: "Nilai Akhir",
          sortable: true,
        },
        {
          key: "sertifikasi",
          label: "Sertifikasi",
        },
      ],
      assessmentItems: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      userData: {},
      loading: false,
      generateLoading: false,
      emptyResults: false,
      openModal:false,
      idAssessment: null
    };
  },

  props: ["courseId"],

  computed: {
    rows() {
      return this.grades.length;
    },
  },

  mounted() {
    this.getAssessment();
  },

  methods: {
    confirmModal(id) {
      this.idAssessment = id;
      this.openModal = true
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    roundTotal(total) {
      return Math.round(total * 10) / 10;
    },

    getScore(components, field) {
      let compo = []
      Object.entries(components).map((key) => {
        if(key[0] != "total") {
          compo.push(key[1])
        }
      });
      const score = compo.find((component) => component.name == field.name);
      if(score.score) {
        let pureScore = score.score.replace(/ /g,'')
        let initScore = pureScore.split("/")
        let findPercent = initScore[1].split('(').pop().split('%)')[0];
        return {score:initScore[0], calScore: (parseInt(findPercent) * parseInt(field.weight)) / 100 }
      }
      return {score : 0 , calScore: 0}
    },

    async getAssessment() {
      this.loading = true;

      // Empty table before fetching new data
      this.grades = [];
      this.assessmentItems = [];

      try {
        const response = await this.axios.get(
          process.env.VUE_APP_API_URL + `/api/course/${this.courseId}/assesment`
        );

        const data = response.data;

        if (data.success) {
          this.emptyResults = false;
          this.assessment = data.assesment;

          // Get assessment items for table headers
          data.assesment.components.forEach((assessmentItem) => {
            this.assessmentItems.push({name: assessmentItem.name, weight: assessmentItem.weight});

            this.fields.splice(3, 0, {
              key: assessmentItem.name,
              label: `${assessmentItem.name} (${assessmentItem.weight})`,
            });
          });

          this.getGrades(data.assesment.id);
        } else {
          this.emptyResults = true;
          this.loading = false;
        }
      } catch (errors) {
        this.emptyResults = true;
        this.loading = false;
        console.error(errors);
      }
    },

    async getGrades(assessmentId) {
      try {
        const requestBody = {
          course_id: this.courseId,
          asessment_id: assessmentId,
        };

        const response = await this.axios.post(
          process.env.VUE_APP_API_URL + `/api/assesment/result`,
          requestBody
        );

        const data = response.data?.results;

        if (data.length) {
          data.forEach((e,index) => {
            e.no = index+1
          });
          this.grades = data;

          this.emptyResults = false;
        } else {
          this.emptyResults = true;
        }
      } catch (errors) {
        console.error(errors);
      } finally {
        this.loading = false;
      }
    },

    async generateCertificate(assessmentId) {
      this.generateLoading = true;

      try {
        const requestBody = {
          course_id: this.courseId,
          asessment_id: assessmentId,
        };

        const response = await this.axios.post(
          process.env.VUE_APP_API_URL + `/api/assesment/generate_certificate`,
          requestBody
        );

        const data = response.data;

        const status = data.success ? "Success" : "Failed";
        const variant = data.success ? "success" : "danger";
        const message = data.success
          ? "Sertifikat pelatihan berhasil digenerate"
          : "Sertifikat pelatihan gagal digenerate";

        // Show Toast
        this.toastCount++;
        this.$bvToast.toast(message, {
          title: status,
          autoHideDelay: 5000,
          variant: variant,
        });
      } catch (errors) {
        console.error(errors);
      } finally {
        this.generateLoading = false;
      }
      this.openModal = false
    },
  },
};
</script>
