<template>
  <b-modal id="create-assessment-modal" title="Buat Penilaian Baru" hide-footer>
    <b-form @submit.stop.prevent="onSubmit">
      <!-- Evaluation Form Start -->
      <b-form-group label="Tipe Penilaian:" label-for="type" class="mb-4">
        <b-form-select
          id="type"
          name="type"
          v-model="assessmentType"
          :options="assessmentTypes"
          v-validate="'required'"
          :state="validateState('type')"
        ></b-form-select>
        <b-form-text v-if="isEvaluationItem">
          Penilaian dimana pengajar dapat menambahkan nilai secara manual kepada
          para siswa.
        </b-form-text>
        <b-form-text v-else>
          Penilaian yang mengambil nilai siswa dari latihan dan tugas yang
          terdapat dalam alur pembelajaran.
        </b-form-text>
        <b-form-invalid-feedback>
          Tipe penilaian wajib diisi.
        </b-form-invalid-feedback>
      </b-form-group>
      <div v-if="isEvaluationItem">
        <b-form-group label="Nama Penilaian:" label-for="name">
          <b-form-input
            id="name"
            name="name"
            v-model="form.evaluation.name"
            placeholder=""
            v-validate="'required'"
            :state="validateState('name')"
          ></b-form-input>
          <b-form-invalid-feedback>
            Nama penilaian wajib diisi.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Deskripsi Penilaian (opsional):"
          label-for="description"
        >
          <b-form-textarea
            id="description"
            name="description"
            v-model="form.evaluation.description"
            placeholder=""
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="Nilai Maksimum:" label-for="max-score">
          <b-form-input
            id="max-score"
            name="max-score"
            type="number"
            number
            v-model="form.evaluation.max_score"
            placeholder=""
            v-validate="'required'"
            :state="validateState('max-score')"
          ></b-form-input>
          <b-form-invalid-feedback>
            Nilai Maksimum wajib diisi.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <!-- Evaluation Form End -->

      <!-- Link Form Start -->
      <div v-else>
        <b-form-group
        label="Tipe Link :"
          label-for="link_type"
          class="mb-4"
        >
        <b-form-select
            id="link_type"
            name="link_type"
            v-model="form.link.link_type"
            :options="form.link.link_types"
            v-validate="'required'"
            :state="validateState('link_type')"
          ></b-form-select>
          <b-form-invalid-feedback>
            Tipe item wajib diisi.
          </b-form-invalid-feedback>
        </b-form-group>
         <b-form-group v-if="isLinkTypeUjian"
          label="Ujian :"
          label-for="ujians"
          class="mb-4"
        >
          <b-form-select
            id="ujians"
            name="ujians"
            v-model="form.link.link_id"
            :options="ujians"
            v-validate="'required'"
            :state="validateState('ujians')"
          ></b-form-select>
          <b-form-invalid-feedback>
            Tipe item wajib diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-if="isLinkTypeTugas"
          label="Tugas :"
          label-for="tugass"
          class="mb-4"
        >
          <b-form-select
            id="tugass"
            name="tugass"
            v-model="form.link.link_id"
            :options="tugass"
            v-validate="'required'"
            :state="validateState('tugass')"
          ></b-form-select>
          <b-form-invalid-feedback>
            Tipe item wajib diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-if="isLinkTypeLearningpath"
          label="Alur Pembelajaran :"
          label-for="learningpaths"
          class="mb-4"
        >
          <b-form-select
            id="learningpaths"
            name="learningpaths"
            v-model="form.link.link_id"
            :options="learningpaths"
            v-validate="'required'"
            :state="validateState('learningpaths')"
          ></b-form-select>
          <b-form-invalid-feedback>
            Tipe item wajib diisi.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <!-- Link Form End -->
    
      <b-form-group label="Bobot Penilaian:" label-for="weight">
        <b-form-input
          id="weight"
          name="weight"
          type="number"
          number
          v-model="form.weight"
          placeholder=""
          v-validate="'required|min_value:1'"
          :state="validateState('weight')"
        ></b-form-input>
        <b-form-text v-if="!veeErrors.first('weight')">
          Jumlah Bobot akan menjadi: {{ computedWeight + form.weight }}
        </b-form-text>
        <b-form-invalid-feedback>
          Bobot Penilaian tidak boleh kosong.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-button type="submit" variant="primary" class="float-right"
        ><b-spinner small v-if="loading"></b-spinner><span v-else>Submit</span>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  name: "create-assessment-modal",

  props: ["computedWeight", "learningpaths", "ujians","tugass"],

  data() {
    return {
      form: {
        evaluation: {
          course_id: Number(this.$route.params.id),
          name: "",
          description: "",
          max_score: 100,
        },
        link: {
          course_id: Number(this.$route.params.id),
          link_type: "",
          link_types: [{ value : 1, text: "Ujian"},
                  { value: 3, text: "Tugas"},
                  { value: 4, text: "Alur Pembelajaran"}],
          link_id: 0,
        },
        weight: 10,
      },
      assessmentType: "evaluation",
      assessmentTypes: [
        { value: "evaluation", text: "Klasikal" },
        { value: "link", text: "Link" },
      ],
      learningpath: 1,
      ujian: 2,
      tugas: 3,
      loading: false,
      toastCount: 0,
    };
  },

  computed: {
    isEvaluationItem() {
      if (this.assessmentType == "evaluation") return true;
      return false;
    },
    isLinkTypeLearningpath(){
      if (this.form.link.link_type == 4) return true;
      return false;
    },
    isLinkTypeUjian(){
      if (this.form.link.link_type == 1) return true;
      return false;
    },
    isLinkTypeTugas(){
      if (this.form.link.link_type == 3) return true;
      return false;
    }
  },

  methods: {
    onSubmit() {
      this.loading = true;

      try {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            this.loading = false;

            return;
          }

          this.submitData();
        });
      } catch (errors) {
        console.log(errors);
      }
    },

    async submitData() {
      const endpoint = this.isEvaluationItem
        ? "/api/assesment/create/evaluation"
        : "/api/assesment/create/link";

      const requestBody = this.isEvaluationItem
        ? this.form.evaluation
        : this.form.link;

      requestBody["weight"] = this.form.weight;

      try {
        const response = await this.axios.post(
          process.env.VUE_APP_API_URL + endpoint,
          requestBody
        );

        const data = response.data;
        const status = data.success ? "Success" : "Failed";
        const variant = data.success ? "success" : "danger";
        const message = data.success
          ? "Penilaian dibuat."
          : "Penilaian gagal dibuat.";

        this.loading = false;
        this.$bvModal.hide("create-assessment-modal");

        // Show Toast
        this.toastCount++;
        this.$bvToast.toast(message, {
          title: status,
          autoHideDelay: 5000,
          variant: variant,
        });

        // Reset form
        if (this.isEvaluationItem) {
          this.form.evaluation.name = "";
          this.form.evaluation.description = "";
          this.form.evaluation.weight = 10;
          this.form.evaluation.max_score = 100;
        } else {
          this.form.link.link_type = 0;
          this.form.link.link_id = 0;
          this.form.link.weight = 0;
        }

        this.$emit("reload-assessments");
      } catch (errors) {
        console.error(errors);
      }
    },
  },

  resetForm() {},
};
</script>
