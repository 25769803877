<template>
  <b-container>
    <div class="d-flex w-100 justify-content-between align-items-center mb-3">
      <h4 class="font-open-sans-bold">Daftar Sertifikat</h4>
      <h5 v-if="!loading" class="font-open-sans-bold mr-4">
        <ICountUp :endVal="rows" />
        Sertifikat
      </h5>
    </div>

    <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>

    <div v-else-if="emptyResults">
      <p>Belum ada peserta yang mendapatkan sertifikat.</p>
    </div>

    <div v-else>
      <b-row class="w-100 justify-content-between ">
        <!-- Filter / Search -->
        <b-col cols="5">
          <b-form-group class="mb-3">
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Cari Peserta"
              ></b-form-input>

              <b-input-group-append>
                <b-button variant="primary" @click="filter = ''">
                  <font-awesome-icon icon="search" class="text-white" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table
        striped
        responsive
        :fields="fields"
        :items="certificates"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        class="font-roboto"
      >
        <template #cell(index)="row">
          {{ row.index + 1 }}
        </template>
        <template #cell(firstname)="{item}">
          {{ item.user.firstname }} {{ item.user.lastname }}
        </template>
        <!-- View Certificate Button -->
        <template #cell(actions)="row">
          <b-link
            v-b-tooltip.hover
            title="Lihat Sertifikat"
            class="text-secondary"
            :href="row.item.path_certificate"
            target="_blank"
          >
            <font-awesome-icon icon="eye" class="" />
          </b-link>
        </template>
      </b-table>
      <b-row class="justify-content-between">
        <b-col cols="4">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="5"
            label-cols-lg="4"
            label-align-sm="right"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-pagination
            class="ml-auto"
            align="right"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      certificates: [],
      fields: [
        {
          key: "no",
          label: "No.",
        },
        {
          key: "user.email",
          label: "Email",
          sortable: true,
        },
        {
          key: "firstname",
          label: "Nama Lengkap",
          sortable: true,
        },
        {
          key: "score_certificate",
          label: "Nilai",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Tanggal Terbit",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      userData: {},
      loading: false,
      emptyResults: false,
    };
  },

  props: ["courseId"],

  computed: {
    rows() {
      return this.certificates.length;
    },
  },

  mounted() {
    this.getCertificates();
  },

  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async getCertificates() {
      this.loading = true;

      // Empty table before fetching new data
      this.certificates = [];

      try {
        const response = await this.axios.get(
          process.env.VUE_APP_API_URL +
            `/api/certificate/course/${this.courseId}`
        );

        const data = response.data;

        if (data.success) {
          this.certificates = data.certificates;
          this.certificates.forEach((e,index) => {
            e.no = index+1
          });
        } else {
          this.emptyResults = true;
        }
      } catch (errors) {
        console.error(errors);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
