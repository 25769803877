<template lang="">
  <div>
    <b-card
      style="max-width: 15rem;"
      class="h-100 font-open-sans position-relative"
    >
      <template #header>
        <b-img src="@/assets/img/badge-100.svg" fluid></b-img>
      </template>
      <b-dropdown
        v-if="!disableActions"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
        right
        class="position-absolute"
        style="top: 10px; right: 10px"
      >
        <template #button-content>
          <font-awesome-icon icon="ellipsis-v"></font-awesome-icon>
        </template>
        <b-dropdown-item v-if="!disableEdit" @click="editSkill(skill)">
          Edit Keterampilan
        </b-dropdown-item>
        <b-dropdown-item @click="deleteSkill(skill)">
          Hapus Keterampilan
        </b-dropdown-item>
      </b-dropdown>
      <b-card-text class="font-weight-bold">
        <b-link @click="showSkill(skill)" class="mb-1">{{ skill.name }}</b-link>
      </b-card-text>
    </b-card>
  </div>
</template>
<script>
export default {
  props: ["skill", "disableActions", "disableEdit"],

  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },

  methods: {
    showSkill(skill) {
      this.$emit("show-skill", skill);
    },

    editSkill(skill) {
      this.$emit("edit-skill", skill);
    },

    deleteSkill(skill) {
      this.$emit("delete-skill", skill);
    },
  },
};
</script>
