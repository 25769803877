<template>
  <b-container>
    <div class="d-flex w-100 justify-content-between align-items-center mb-3">
      <h4 class="font-open-sans-bold">Daftar Pengajar</h4>
      <h5 v-if="!loading" class="font-open-sans-bold mr-4">
        <ICountUp :endVal="totalRows" />
        Pengajar
      </h5>
    </div>

    <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>

    <!-- <div v-else-if="emptyResults">
      <p>Belum ada pengajar yang terdaftar.</p>
      <b-button size="sm" variant="primary" class="mr-2" v-b-modal.addUserModal>
        <span class="font-weight-bold font-open-sans mr-2">Tambah Pengajar
        </span>
        <font-awesome-icon icon="plus"></font-awesome-icon>
      </b-button>
    </div> -->

    <div v-else>
      <b-row>
        <div class="col-2">
          <b-form-select id="per-page-select" v-model="perPage" @change="toTheStudentPage"
            :options="pageOptions"></b-form-select>
        </div>
        <b-col cols="12" md="4" lg="4">
          <b-form-group class="mb-3">
            <b-input-group>
              <b-form-input v-model="search" type="search" @input="debounceInput"
                placeholder="Cari Pengajar"></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6" class="text-right mb-3">
          <b-button size="sm" variant="primary" class="mr-2" v-b-modal.addUserModal>
            <span class="font-weight-bold font-open-sans mr-2">Tambah Pengajar
            </span>
            <font-awesome-icon icon="plus"></font-awesome-icon>
          </b-button>
          <b-button size="sm" variant="primary" class="" @click="getTeachers">
            <font-awesome-icon icon="sync"></font-awesome-icon>
          </b-button>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table v-if="!emptyResults" striped responsive :fields="fields" :items="teachers" :per-page="perPage"
        :current-page="totalRows ? 1 : currentPage" :filter="filter" :filter-included-fields="filterOn"
        @filtered="onFiltered" class="font-roboto">
        <template #cell(index)="row">
          {{ row.index + 1 }}
        </template>

        <template #cell(firstname)="{ item }">
          {{ item.firstname }} {{ item.lastname }}
        </template>

        <!-- Edit and Delete Buttons -->
        <template #cell(actions)="row">
          <b-link v-b-tooltip.hover title="Edit Pengajar" class="text-secondary" @click="editUser(row.item)">
            <font-awesome-icon icon="edit" class="mr-3" />
          </b-link>
          <b-link v-b-tooltip.hover title="Arsip Pengajar" class="text-secondary" @click="deleteUser(row.item)">
            <font-awesome-icon icon="trash" class="" />
          </b-link>
        </template>
      </b-table>
      <div v-if="emptyResults">
        <p>Belum ada pengajar yang terdaftar.</p>
        <b-button size="sm" variant="primary" class="mr-2" v-b-modal.addUserModal>
          <span class="font-weight-bold font-open-sans mr-2">Tambah Pengajar
          </span>
          <font-awesome-icon icon="plus"></font-awesome-icon>
        </b-button>
      </div>
      <b-row v-if="!emptyResults" class="justify-content-between">
        <b-col cols="4">
          <!-- <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
            ></b-form-select>
          </b-form-group> -->
        </b-col>
        <b-col cols="4">
          <b-pagination class="ml-auto" align="right" v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
            @page-click="toTheCourse" aria-controls="my-table"></b-pagination>
        </b-col>
      </b-row>
    </div>
    <addUserModal title="Tambah Pengajar" role="teacher" @reload-table="getTeachers" />
    <editUserModal title="Edit Pengajar" role="teacher" :userData="userData" @reload-table="getTeachers" />
    <deleteUserModal title="Arsip Pengajar" role="teacher" :userData="userData" @reload-table="getTeachers" />
  </b-container>
</template>

<script>
import _ from "lodash"
import AddUserModal from "@/components/users/AddUserModal.vue";
import EditUserModal from "@/components/users/EditUserModal.vue";
import DeleteUserModal from "@/components/users/DeleteUserModal.vue";

export default {
  components: {
    AddUserModal,
    EditUserModal,
    DeleteUserModal,
  },

  data() {
    return {
      teachers: [],
      fields: [
        {
          key: "no",
          label: "No.",
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "firstname",
          label: "Nama Lengkap",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      userData: {},
      loading: true,
      emptyResults: false,
      search: ""
    };
  },
  mounted() {
    this.getTeachers();
  },
  methods: {
    toTheStudentPage(e) {
      this.perPage = e;
      this.getTeachers()
    },
    debounceInput: _.debounce(function () {
      this.getTeachers()
    }, 500),
    toTheCourse(e, page) {
      this.currentPage = page;
      this.getTeachers()
    },
    editUser(user) {
      this.userData = user;
      this.userData.role = "teacher";
      this.$bvModal.show("editUserModal");
    },

    deleteUser(user) {
      this.userData = user;
      this.userData.role = "teacher";
      this.$bvModal.show("deleteUserModal");
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async getTeachers() {
      this.loading = true;
      this.teachers = [];

      try {
        const response = await this.axios.get(
          `${process.env.VUE_APP_API_URL}/api/user/teachersonly/ssp?page=${this.currentPage}&pageLength=${this.perPage}&search=${this.search}`
        );

        if (response.data.success) {
          this.emptyResults = false;

          const data = response.data.teachers;
          this.teachers = Object.keys(data).map((i) => data[i]);
          let no = (this.currentPage - 1) * this.perPage
          this.teachers.forEach((e, index) => {
            this.currentPage == 1 ?
              e.no = index + 1 :
              e.no = no + index + 1
          });
          this.totalRows = response.data.total
        } else {
          this.emptyResults = true;
          this.totalRows = 0;
        }

        this.loading = false;
      } catch (errors) {
        console.error(errors);
      }
    },
  },
};
</script>
