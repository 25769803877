<template>
  <b-modal id="edit-skill-modal" title="Edit Keterampilan" hide-footer>
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group label="Nama Keterampilan:" label-for="name">
        <b-form-input
          id="name"
          name="name"
          v-model="computedSkillData.name"
          placeholder=""
          v-validate="'required'"
          :state="validateState('name')"
        ></b-form-input>
        <b-form-invalid-feedback>
          Nama keterampilan wajib diisi.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label="Deskripsi Keterampilan (opsional):"
        label-for="description"
      >
        <b-form-textarea
          id="textarea"
          v-model="computedSkillData.description"
          placeholder=""
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>

      <b-button type="submit" variant="primary" class="float-right"
        ><b-spinner small v-if="loading"></b-spinner><span v-else>Submit</span>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      toastCount: 0,
    };
  },

  props: ["skillData"],

  computed: {
    computedSkillData() {
      return this.skillData;
    },
  },

  methods: {
    onSubmit() {
      this.loading = true;

      try {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            this.loading = false;

            return;
          }

          this.submitData();
        });
      } catch (errors) {
        console.log(errors);
      }
    },

    async submitData() {
      const formData = new FormData();

      for (const input in this.computedSkillData) {
        formData.append(input, this.computedSkillData[input]);
      }

      try {
        const response = await this.axios.post(
          process.env.VUE_APP_API_URL + "/api/skills/update",
          formData
        );

        const data = response.data;

        const status = data.success ? "Success" : "Failed";
        const variant = data.success ? "success" : "danger";
        const message = data.message;

        this.$bvModal.hide("edit-skill-modal");

        // Show Toast
        this.toastCount++;
        this.$bvToast.toast(message, {
          title: status,
          autoHideDelay: 5000,
          variant: variant,
        });
      } catch (errors) {
        console.error(errors);
      } finally {
        this.loading = false;
        // Refresh skills list
        this.$emit("refresh-skills");
      }
    },
  },

  resetForm() {},
};
</script>
