import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isAuthenticated: false,
    token: "",
    refreshToken: "",
    userInfo: {},
    userRole: "",
    avatar: "",
    courseCategories: [],
    importedStudents: []
  },
  plugins: [createPersistedState()],
  mutations: {
    setAuthentication(state, status) {
      state.isAuthenticated = status;
    },

    setUserInfo(state, user) {
      state.userInfo = user;
    },

    setUserRole(state, role) {
      state.userRole = role;
    },

    setAvatar(state, avatar) {
      state.avatar = avatar;
    },

    setToken(state, token) {
      state.token = token;
    },

    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
    },

    setCourseCategories(state, categories) {
      state.courseCategories = categories;
    },
    setImportedStudents(state, data) {
      state.importedStudents = data;
    },
  },
  getters: {
    realAdmin: (state) => {
      if (state.userRole == "admin") return true;
      return false;
    },
    isAdmin: (state) => {
      if (state.userRole == "admin" || state.userRole == "courseleader" || state.userRole == "admindivisi") return true;
      return false;
    },
    isCl: (state) => {
      if (state.userRole == "courseleader") return true;
      return false;
    },
    isAD: (state) => {
      if (state.userRole == "admindivisi") return true;
      return false;
    },
    userId: (state) => {
      return state.userInfo.user_id;
    },
    getImportedStudent: (state) => {
      return state.importedStudents;
    },
  },
  actions: {
    async newRefreshToken({ commit, state }) {
      delete axios.defaults.headers.common["Authorization"];

      const url = process.env.VUE_APP_API_URL;

      const requestData = {
        refresh_token: state.refreshToken,
      };

      const response = await axios.post(`${url}/refresh_token`, requestData);

      const data = response.data;

      commit("setToken", data.token);
      commit("setRefreshToken", data.refresh_token);

      localStorage.setItem("token", data.token);

      location.reload();
    },
  },
});
