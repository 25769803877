<template lang="">
  <b-modal id="logout-modal" title="Logout">
    <p class="mb-0">Apakah anda yakin untuk keluar?</p>
    <template #modal-footer>
      <div class="float-right">
        <b-button
          @click="$bvModal.hide('logout-modal')"
          variant="secondary"
          class="mr-2"
        >
          Cancel
        </b-button>
        <b-button variant="danger" @click="logout">
          Keluar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
export default {
  methods: {
    logout() {
      localStorage.clear();

      location.reload();
    },
  },
};
</script>
