<template>
  <b-modal id="edit-assessment-modal" title="Edit Penilaian" hide-footer>
    <b-form @submit.stop.prevent="onSubmit">
      <!-- Evaluation Item Form -->
      <div v-if="isEvaluationItem">
        <b-form-group label="Nama Penilaian:" label-for="name">
          <b-form-input
            id="name"
            name="name"
            v-model="assessmentItem.name"
            placeholder=""
            v-validate="'required'"
            :state="validateState('name')"
          ></b-form-input>
          <b-form-invalid-feedback>
            Nama penilaian wajib diisi.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Deskripsi Penilaian (opsional):"
          label-for="description"
        >
          <b-form-textarea
            id="description"
            name="description"
            v-model="assessmentItem.description"
            placeholder=""
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="Nilai Maksimum:" label-for="max-score">
          <b-form-input
            id="max-score"
            name="max-score"
            type="number"
            number
            v-model="assessmentItem.max_score"
            placeholder=""
            v-validate="'required'"
            :state="validateState('max-score')"
          ></b-form-input>
          <b-form-invalid-feedback>
            Nilai Maksimum wajib diisi.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <!-- Evaluation Item Form End -->

      <b-form-group label="Bobot Penilaian:" label-for="weight">
        <b-form-input
          id="weight"
          name="weight"
          type="number"
          number
          v-model="assessmentItem.weight"
          placeholder=""
          v-validate="'required|min_value:1'"
          :state="validateState('weight')"
        ></b-form-input>
        <b-form-text v-if="!veeErrors.first('weight')">
          Jumlah Bobot akan menjadi:
          {{ computedWeight }}
        </b-form-text>
        <b-form-invalid-feedback>
          Bobot Penilaian tidak boleh kosong.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-button type="submit" variant="primary" class="float-right"
        ><b-spinner small v-if="loading"></b-spinner><span v-else>Submit</span>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  name: "edit-assessment-modal",

  props: ["assessmentItem", "computedWeight"],

  data() {
    return {
      loading: false,
      toastCount: 0,
    };
  },

  computed: {
    isEvaluationItem() {
      return this.assessmentItem.type == "E" ? true : false;
    },
  },

  methods: {
    onSubmit() {
      this.loading = true;

      try {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            this.loading = false;

            return;
          }

          this.submitData();
        });
      } catch (errors) {
        console.log(errors);
      }
    },

    async submitData() {
      const itemId = this.assessmentItem.id;

      const endpoint = this.isEvaluationItem
        ? `/api/assesment/evaluation/${itemId}/update`
        : `/api/assesment/link/${itemId}/update`;

      const requestBody = this.isEvaluationItem
        ? {
            name: this.assessmentItem.name,
            description: this.assessmentItem.description,
            max_score: Number(this.assessmentItem.max_score),
          }
        : {};

      requestBody["course_id"] = Number(this.$route.params.id);
      requestBody["weight"] = Number(this.assessmentItem.weight);

      try {
        const response = await this.axios.put(
          process.env.VUE_APP_API_URL + endpoint,
          requestBody
        );

        const data = response.data;
        const status = data.success ? "Success" : "Failed";
        const variant = data.success ? "success" : "danger";
        const message = data.success
          ? "Penilaian diupdate."
          : "Penilaian gagal diupdate.";

        this.loading = false;
        this.$bvModal.hide("edit-assessment-modal");

        // Show Toast
        this.toastCount++;
        this.$bvToast.toast(message, {
          title: status,
          autoHideDelay: 5000,
          variant: variant,
        });

        this.$emit("reload-assessments");
      } catch (errors) {
        console.error(errors);
      }
    },
  },

  resetForm() {},
};
</script>
