<template>
  <b-modal id="removeStudentModal" ref="removeStudentModal" :title="title" hide-footer>
    <div class="text-center">
      <p class="mb-0">Apakah Anda yakin untuk mengeluarkan</p>
      <p class="font-weight-bold mb-0">{{ userData.email }}?</p>
      <p>dari pelatihan?</p>
      <b-button variant="danger" @click="removeStudent"><b-spinner small v-if="loading"></b-spinner><span
          v-else>Keluarkan</span></b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "RemoveStudentModal",
  props: ["title", "role", "userData"],

  data() {
    return {
      loading: false,
      toastCount: 0,
    };
  },

  methods: {
    async removeStudent() {
      this.loading = true;

      const formData = {
        course_id: parseInt(this.$route.params.id),
        user_id: this.userData.id,
      };
      await this.axios.delete(
        process.env.VUE_APP_API_URL + "/api/course/delete/siswa",
        {
          data: formData,
        }
      ).then(res => {
        console.log(res)
        this.$bvModal.hide("removeStudentModal");
        this.student = "";
        this.$emit("reload-table");
      }).catch(err => {
        this.$bvModal.hide("removeStudentModal");
        if (err.response) {
          if (err.response.status == 500) {
            this.toastCount++;
            this.$bvToast.toast("Berhasil dikeluarkan, tapi email tidak terdeteksi sebagai email aktif", {
              title: 'Warning',
              autoHideDelay: 5000,
              variant: 'warning',
            });
          }
        } else if (err.request) {
          this.toastCount++;
          this.$bvToast.toast(err.request, {
            title: 'Failed',
            autoHideDelay: 5000,
            variant: 'danger',
          });
        } else {
          this.toastCount++;
          this.$bvToast.toast("Unexpected error", {
            title: 'Failed',
            autoHideDelay: 5000,
            variant: 'danger',
          });
        }
        this.student = "";
        this.$emit("reload-table");
      });

      this.loading = false;
      // try {
      //   const response = await this.axios.delete(
      //     process.env.VUE_APP_API_URL + "/api/course/delete/siswa",
      //     {
      //       data: formData,
      //     }
      //   );

      //   const data = response.data;
      //   const status = data.success ? "Success" : "Failed";
      //   const variant = data.success ? "success" : "danger";
      //   const message = data.message;

      //   this.loading = false;
      //   this.$bvModal.hide("removeStudentModal");

      //   // Show Toast
      //   this.toastCount++;
      //   this.$bvToast.toast(message, {
      //     title: status,
      //     autoHideDelay: 5000,
      //     variant: variant,
      //   });

      //   if (data.success) {
      //     // Refresh table
      //     this.$emit("reload-table");
      //   }
      // } catch (errors) {
      //   console.error(errors);
      // }
    },
  },
};
</script>
