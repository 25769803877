<template lang="">
  <b-modal id="skill-modal" size="sm" centered hide-header hide-footer>
    <div class="text-right">
      <b-link @click="$bvModal.hide('skill-modal')" class="text-secondary">
        <font-awesome-icon icon="times"></font-awesome-icon>
      </b-link>
    </div>
    <div class="text-center">
      <b-img src="@/assets/img/badge-100.svg" fluid></b-img>
      <h4 class="font-open-sans my-4">{{ skill.name }}</h4>
      <p>{{ skill.description }}</p>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: ["skill"],
};
</script>
