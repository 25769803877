import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/fontawesome";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import VeeValidate from "vee-validate";
import VueFuse from "vue-fuse";
import moment from "moment";
import "moment/locale/id";
import VueMoment from "vue-moment";
import ICountUp from "vue-countup-v2";
import vSelect from "vue-select";

Vue.config.productionTip = false;

// Axios configurations
// Set global request headers
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");

axios.defaults.headers.post["Content-Type"] = "application/json";

// Logged out response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (401 === error.response.status) {
      if(!error.response.config.url.includes("api-siadik-lppi.dugi-academy.id")) {
        store.dispatch("newRefreshToken");
      }
    } else {
      return Promise.reject(error);
    }
  }
);

Vue.use(VueAxios, axios);

moment.locale("id");
Vue.use(VueMoment, {
  moment,
});

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields",
  errorBagName: "veeErrors",
});

Vue.component("v-select", vSelect);
Vue.component("ICountUp", ICountUp);
Vue.component("VueFuse", VueFuse);

Vue.filter("str_limit", function(value, size) {
  if (!value) return "";
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + "...";
});

Vue.mixin({
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
  },
});

new Vue({
  router,
  render: (h) => h(App),
  store: store,
}).$mount("#app");
