<template>
  <b-container>

    <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>

    <div v-else-if="emptyResults">
      <p>Template Sertifikat belum terbuat</p>
      <b-button size="" variant="primary" class="mr-2" @click="generateCert">
        <span class="font-weight-bold font-open-sans mr-2"
          >Generate Template
        </span>
        <b-spinner small v-if="loadingTemp" style="vertical-align:middle"></b-spinner>
        <font-awesome-icon v-else icon="plus"></font-awesome-icon>
      </b-button>
    </div>

    <div v-else @click="toUrlEmbeded()" style="cursor:pointer;">
      <div class="row">
        <div class="col-6 offset-6 text-right">
          <b-alert show variant="info" class="text-left">
            <p class="mb-0"><b>{{noCert}}</b> : Nomor Sertifikat</p>
            <p class="mb-0"><b>{{customerName}}</b> : Nama Customer</p>
            <p class="mb-0"><b>{{title}}</b> : Nama pelatihan</p>
          </b-alert>
        </div>
      </div>
      <iframe :src="embededFile" frameborder="0" width="482" height="300" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
    </div>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      noCert : "{{nocert}}",
      customerName : "{{customerName}}",
      title : "{{title}}",
      embededFile: "",
      userData: {},
      loading: false,
      loadingTemp: false,
      emptyResults: false,
    };
  },

  props: ["courseId"],
  computed: {
    rows() {
      return this.certificates.length;
    },
  },

  mounted() {
    this.getCertificates();
  },

  methods: {
    async generateCert() {
      this.loadingTemp = true
      try {
        let res = await this.axios.post(
          process.env.VUE_APP_API_URL + "/api/course/create/template/cert",
          {
            course_id: parseInt(this.$route.params.id)
          }
        );
        if (res.data.success) {
          this.$bvToast.toast("Template berhasil di generate", {
            title: "Success",
            autoHideDelay: 5000,
            variant: "success",
          });
          this.getCertificates();
        } else {
          this.emptyResults = true;
        }
      } catch (errors) {
        console.error(errors);
      } finally {
        this.loadingTemp = false
      }
    },
    toUrlEmbeded() {
      window.open(this.embededFile, '_blank');
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getCertificates() {
      this.loading = true;
      this.emptyResults = false
      // Empty table before fetching new data
      this.certificates = [];

      try {
        const response = await this.axios.get(
          process.env.VUE_APP_API_URL +
            `/api/course/${this.courseId}/certificate`
        );

        const data = response.data;

        if (data.success) {
          this.embededFile = data.template
        } else {
          this.emptyResults = true;
        }
      } catch (errors) {
        console.error(errors);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
