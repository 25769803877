<template>
  <b-modal id="create-skill-modal" title="Buat Keterampilan Baru" hide-footer>
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group label="Nama Keterampilan:" label-for="name">
        <b-form-input
          id="name"
          name="name"
          v-model="form.name"
          placeholder=""
          v-validate="'required'"
          :state="validateState('name')"
        ></b-form-input>
        <b-form-invalid-feedback>
          Nama keterampilan wajib diisi.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label="Deskripsi Keterampilan (opsional):"
        label-for="description"
      >
        <b-form-textarea
          id="textarea"
          v-model="form.description"
          placeholder=""
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>

      <b-button type="submit" variant="primary" class="float-right"
        ><b-spinner small v-if="loading"></b-spinner><span v-else>Submit</span>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        short_code: "",
        description: "",
        status: 1,
        icon: "",
        parent_id: 1,
      },
      loading: false,
      toastCount: 0,
    };
  },

  methods: {
    onSubmit() {
      this.loading = true;

      try {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            this.loading = false;

            return;
          }

          this.submitData();
        });
      } catch (errors) {
        console.log(errors);
      }
    },

    async submitData() {
      const formData = new FormData();

      for (const input in this.form) {
        formData.append(input, this.form[input]);
      }

      try {
        const response = await this.axios.post(
          process.env.VUE_APP_API_URL + "/api/skills/create",
          formData
        );

        const data = response.data;

        const status = data.success ? "Success" : "Failed";
        const variant = data.success ? "success" : "danger";
        const message = data.message;

        this.$bvModal.hide("create-skill-modal");

        // Show Toast
        this.toastCount++;
        this.$bvToast.toast(message, {
          title: status,
          autoHideDelay: 5000,
          variant: variant,
        });
      } catch (errors) {
        console.error(errors);
      } finally {
        this.loading = false;

        // Reset form
        this.form.name = "";
        this.form.description = "";
        this.form.parentId = 1;

        // Refresh skills list
        this.$emit("refresh-skills");
      }
    },
  },

  resetForm() {},
};
</script>
