<template>
  <b-modal
    id="restoreCourseModal"
    ref="restoreCourseModal"
    title="Arsip Pelatihan"
    hide-footer
  >
    <div class="text-center">
      <p class="mb-0">Apakah Anda yakin untuk mengembalikan pelatihan</p>
      <p class="font-weight-bold">{{ courseData.title }}?</p>
      <b-button variant="primary" @click="restoreCourse"
        ><b-spinner small v-if="loading"></b-spinner
        ><span v-else>Kembalikan</span></b-button
      >
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "RestoreCourseModal",
  props: ["courseData"],

  data() {
    return {
      loading: false,
      toastCount: 0,
    };
  },

  methods: {
    async restoreCourse() {
      this.loading = true;

      const formData = {
        course_id: this.courseData.id,
      };

      try {
        const response = await this.axios.put(
          process.env.VUE_APP_API_URL + "/api/course/restore",
          formData
        );

        const data = response.data;
        const status = data.success ? "Success" : "Failed";
        const variant = data.success ? "success" : "danger";
        const message = data.message;

        this.loading = false;
        this.$bvModal.hide("restoreCourseModal");

        // Show Toast
        this.toastCount++;
        this.$bvToast.toast(message, {
          title: status,
          autoHideDelay: 5000,
          variant: variant,
        });

        if (data.success) {
          // Refresh table
          this.$emit("refresh-courses");
        }
      } catch (errors) {
        console.error(errors);
      }
    },
  },
};
</script>
