<template>
  <b-modal id="createCourseModal" title="Buat Pelatihan Baru" hide-footer size="lg">
    <b-tabs active content-class="mt-3">
      <b-tab title="Pelatihan" active lazy>
        <b-form @submit.stop.prevent="onSubmit">
          <b-form-group label="Nama Pelatihan:" label-for="title">
            <b-form-input
              id="title"
              name="title"
              v-model="form.title"
              placeholder=""
              v-validate="'required'"
              :state="validateState('title')"
            ></b-form-input>
            <b-form-invalid-feedback>
              Nama pelatihan wajib diisi.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Kategori:" label-for="category">
            <b-form-select
              id="category"
              name="category"
              v-model="form.category_code"
              :options="categories"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="Banner:" label-for="">
            <b-form-file
              v-model="fileBanner"
              accept="image/*"
              :state="Boolean(fileBanner)"
              placeholder="Choose an imange or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
          </b-form-group>
          <div class="row my-3">
            <div class="col-6">
              <b-form-checkbox v-model="form.private" name="check-button" switch><span style="cursor:pointer;font-weight:bold;">Private</span></b-form-checkbox>
            </div>
            <div class="col-6">
              <b-form-checkbox v-model="form.notif" name="check-button" switch><span style="cursor:pointer;font-weight:bold;">Notification</span></b-form-checkbox>
            </div>
          </div>
          <b-button type="submit" variant="primary" class="float-right">
            <b-spinner small v-if="loading"></b-spinner><span v-else>Submit</span>
          </b-button>
        </b-form>
      </b-tab>
      <b-tab title="SIADIK" lazy>
        <div class="row">
          <div class="col-12">
            <label for="">Group SIADIK</label>
            <v-select
              :loading="loadingGroup"
              v-model="kodeGroupSiadik"
              :options="groupsSiadik"
              class="mb-3 siadik-group"
              label="nama_group_program"
              :reduce="e=> e.kd_group_program"
              placeholder="Pilih Group"
              @input="selectGroup"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label for="">Program SIADIK</label>
            <v-select
              :loading="loadingProgram"
              v-model="kodeProgramSiadik"
              :options="programsSiadik"
              class="mb-3 siadik-group"
              label="nama_program"
              :reduce="e=> e.kd_program"
              placeholder="Pilih Program"
              @input="selectJadwal"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label for="">Jadwal SIADIK</label>
            <v-select
              :loading="loadingJadwal"
              v-model="kodeJadwalSiadik"
              :options="jadwalSiadik"
              class="mb-3 siadik-group"
              label="nama_angkatan"
              :reduce="e=> e.kd_dt_jadwal"
              placeholder="Pilih Jadwal"
              @input="selectKelas"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label for="">Kelas SIADIK</label>
            <v-select
              :loading="loadingKelas"
              v-model="kodeKelas"
              :options="kelasSiadik"
              class="mb-3 siadik-group"
              label="nama_kelas"
              :reduce="e=> e.kd_dt_kelas"
              placeholder="Pilih Kelas"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-8">
             <b-alert v-if="!selectedId" class="p-1" show><span style="font-size:14px;"><i>Simpan terlebih dahulu kelas anda untuk integrasi dengan data SIADIK</i></span></b-alert>
          </div>
          <div class="col-4">
            <b-button type="button" variant="primary" class="float-right" :disabled="!selectedId" @click="integrasiSiadik">
              <b-spinner small v-if="loading"></b-spinner><span v-else>Integrasi Siadik</span>
            </b-button>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
export default {
  name: "CreateCourseModal",
  props: ["categories", "categoryCode"],

  data() {
    return {
      siadik:false,
      fileBanner: [],
      form: {
        notif: false,
        private: false,
        title: "",
        category_code: "",
        access_type: 1,
        allow_unsubscribe: false,
        expiration_date: null,
      },
      selectedId: null,
      loadingGroup:false,
      loadingProgram:false,
      loadingJadwal:false,
      loadingKelas:false,
      loading: false,
      toastCount: 0,
      kodeKelas: null,
      kodeGroupSiadik: null,
      kodeProgramSiadik: null,
      kodeJadwalSiadik: null,
      groupsSiadik: [],
      programsSiadik: [],
      jadwalSiadik: [],
      kelasSiadik: [],
      //Label
      labelGroup: "",
      labelProgram: "",
      labelJadwal: "",
      labelKelas: ""
    };
  },
  async mounted() {
    try {
      const res = await this.axios.get(
        process.env.VUE_APP_API_SIADIK + "/api/siadik/program/group/0",
      );
      res.data.data.forEach(e => {
        e.nama_group_program = `(${e.kd_group_program}) ${e.nama_group_program}`
      });
      this.groupsSiadik =res.data.data
    } catch (e) {
        console.log(e)
    }
  },
  methods: {
    async selectKelas(e) {
      this.loadingKelas = true
      try {
        const res = await this.axios.get(
          process.env.VUE_APP_API_SIADIK + `/api/siadik/program/kelas/${e}`,
        );
        res.data.data.forEach(e => {
          e.nama_kelas = `(${e.kd_dt_kelas}) ${e.nama_kelas}`
        });
        this.kelasSiadik = res.data.data;
      } catch (e) {
          console.log(e)
      }
      this.loadingKelas = false
    },
    async selectJadwal(e) {
      if(!e) {
        this.kelasSiadik = []
        this.jadwalSiadik = []
        this.kodeKelas = null
        this.kodeJadwalSiadik = null
      }
      this.loadingJadwal = true
      try {
        const res = await this.axios.get(
          process.env.VUE_APP_API_SIADIK + `/api/siadik/program/jadwal/${e}`,
        );
        res.data.data.forEach(e => {
          e.nama_angkatan = `(${e.kd_dt_jadwal}) ${e.nama_angkatan}`
        });
        this.jadwalSiadik = res.data.data
      } catch (e) {
          console.log(e)
      }
      this.loadingJadwal = false
    },
    async selectGroup(e) {
      if(!e) {
        this.jadwalSiadik = []
        this.kelasSiadik = []
        this.programsSiadik = []
        this.kodeKelas = null
        this.kodeGroupSiadik = null
        this.kodeProgramSiadik = null
        this.kodeJadwalSiadik = null
      }
      this.loadingProgram = true
      try {
        const res = await this.axios.get(
          process.env.VUE_APP_API_SIADIK + `/api/siadik/program/program/${e}`,
        );
        res.data.data.forEach(e => {
          e.nama_program = `(${e.kd_program}) ${e.nama_program}`
        });
        this.programsSiadik = res.data.data
      } catch (e) {
          console.log(e)
      }
      this.loadingProgram = false
    },
    onSubmit() {
      this.loading = true;

      try {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            this.loading = false;

            return;
          }

          this.submitData();
        });
      } catch (errors) {
        console.log(errors);
      }
    },
    async integrasiSiadik() {
      const payload = {
        course_id: this.selectedId,
        group_program_siadik_id: this.kodeGroupSiadik,
        program_siadik_id: this.kodeProgramSiadik,
        program_jadwal_siadik_id: this.kodeJadwalSiadik,
        program_kelas_siadik_id: this.kodeKelas
      }
      let res = await this.axios.post(
        process.env.VUE_APP_API_URL + "/api/course/integrate/siadik",
        payload
      );
      console.log(res)
      this.$bvToast.toast(res.data.message, {
        title: res.data.success ? "Success" : "Failed",
        autoHideDelay: 10000,
        variant: res.data.success ? "success" : "danger",
      });
    },
    async submitData() {
      try {
        const response = await this.axios.post(
          process.env.VUE_APP_API_URL + "/api/course/create",
          this.form
        );
        const data = response.data;
        if(response.status == 200 && response.data.success) {
          let formData = new FormData()
          this.selectedId = response.data.course.id
          formData.append('course_id', response.data.course.id)
          formData.append('image', this.fileBanner)
          this.axios.post(
            process.env.VUE_APP_API_URL + "/api/course/image",
            formData
          );
          this.axios.put(
            process.env.VUE_APP_API_URL + "/api/course/notification",
            {
              course_id: this.selectedId,
              value: this.form.notif
            }
          );
        }

        const status = data.success ? "Success" : "Failed";
        const variant = data.success ? "success" : "danger";
        const message = data.message;

        this.loading = false;
        this.$bvModal.hide("createCourseModal");

        // Show Toast
        this.toastCount++;
        this.$bvToast.toast(message, {
          title: status,
          autoHideDelay: 5000,
          variant: variant,
        });

        // Reset form
        this.form.title = "";

        // Refresh courses list
        this.$emit("refresh-courses");
      } catch (errors) {
        console.error(errors);
      }
    },
  },
};
</script>
<style>
#createCourseModal {
  overflow: scroll !important; /* Show scrollbars */
}
.siadik-group > .vs__dropdown-menu{
  max-height: 230px !important;
}
</style>
