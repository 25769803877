<template>
  <b-row class="my-5">
    <b-col cols="6" class="text-right">
      <b-img src="@/assets/img/support.svg" fluid></b-img>
    </b-col>
    <b-col cols="6">
      <b-container class="h-100">
        <b-row align-h="center" align-v="center" class="h-100">
          <b-col cols="8" class="text-left">
            <h2 class="font-montserrat-bold mb-4">Admin Login</h2>
            <p class="text-secondary mb-4">
              Selamat Datang di Portal LMS LPPI Classroom+. Mohon login untuk
              melanjutkan.
            </p>
            <b-alert variant="danger" v-model="notAdmin">
              Harap login dengan akun admin.
            </b-alert>
            <b-alert variant="danger" v-model="invalidCredentials">
              Email / password salah. Harap masukkan email & password yang
              benar.
            </b-alert>
            <b-form @submit.stop.prevent="onSubmit">
              <b-form-group label="Alamat Email:" label-for="email">
                <b-form-input
                  id="email"
                  name="email"
                  v-model="form.username"
                  placeholder=""
                  v-validate="'required|email'"
                  :state="validateState('email')"
                  autocomplete="email"
                  autofocus
                ></b-form-input>
                <b-form-invalid-feedback>
                  Email wajib diisi menggunakan email yang valid.
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label="Password (minimal 8 karakter):"
                label-for="password"
              >
                <b-input-group class="mb-4">
                  <b-form-input
                    :type="showPassword ? 'text' : 'password'"
                    id="password"
                    name="password"
                    v-model="form.password"
                    placeholder=""
                    v-validate="'required|min:8'"
                    :state="validateState('password')"
                    ref="password"
                    class="border-right-0"
                  ></b-form-input>
                  <b-input-group-append
                    class="hover-pointer"
                    @click="showPassword = !showPassword"
                  >
                    <b-input-group-text class="bg-white rounded-right">
                      <font-awesome-icon
                        v-if="showPassword"
                        icon="eye-slash"
                      ></font-awesome-icon>
                      <font-awesome-icon v-else icon="eye"></font-awesome-icon>
                    </b-input-group-text>
                  </b-input-group-append>
                  <b-form-invalid-feedback>
                    Password wajib diisi dengan minimal 8 karakter.
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
              <b-button block type="submit" variant="primary" class="mb-2"
                ><b-spinner small v-if="loading"></b-spinner
                ><span v-else>Login</span>
              </b-button>
              <b-link
                href="https://classroom.lppi.or.id/forgetpassword"
                class="small float-right"
                >Lupa Password?</b-link
              >
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      token: "",
      showPassword: false,
      loading: false,
      invalidCredentials: false,
      notAdmin: false,
      toastCount: 0,
    };
  },

  mounted() {
    // console.log(this.$store.state.expiredToken);
  },

  methods: {
    showExpiredNotification() {
      // Show Toast
      this.toastCount++;
      this.$bvToast.toast("Sesi Anda sudah habis. Harap login kembali.", {
        title: "Sesi Habis.",
        autoHideDelay: 5000,
        variant: "danger",
      });
    },

    onSubmit() {
      this.loading = true;

      try {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            this.loading = false;

            return;
          }

          this.login();
        });
      } catch (errors) {
        console.log(errors);
      }
    },

    async login() {
      try {
        delete this.axios.defaults.headers.common["Authorization"];

        const response = await this.axios.post(
          process.env.VUE_APP_API_URL + "/authentication_token",
          this.form
        );

        const data = response.data;

        // Validate logged in user is admin
        if (data.data.role != "admin" && data.data.role != "courseleader" && data.data.role != "admindivisi") {
          this.invalidCredentials = false;
          this.notAdmin = true;
          return;
        }

        this.$store.commit("setAuthentication", true);
        this.$store.commit("setToken", data.token);
        this.$store.commit("setRefreshToken", data.refresh_token);
        this.$store.commit("setUserRole", data.data.role);

        localStorage.setItem("token", data.token);

        location.replace("/");
      } catch (errors) {
        this.notAdmin = false;
        this.invalidCredentials = true;
        console.error(errors);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
