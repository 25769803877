<template>
  <b-modal id="addUserModal" :title="title" hide-footer no-close-on-backdrop>
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group label="Nama Depan:" label-for="firstname">
        <b-form-input
          id="firstname"
          name="firstname"
          v-model="form.firstname"
          placeholder=""
          v-validate="'required'"
          :state="validateState('firstname')"
        ></b-form-input>
        <b-form-invalid-feedback>
          Nama depan wajib diisi.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Nama Belakang:" label-for="lastname">
        <b-form-input
          id="lastname"
          name="lastname"
          v-model="form.lastname"
          placeholder=""
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Email:" label-for="email">
        <b-tooltip v-if="confirmSymbol" :target="`email`" title="Tidak diperbolehkan menggunakan simbol/karakter yang di larang (& = _ ' - + , <> . ;)"></b-tooltip>
        <b-form-input
          id="email"
          name="email"
          v-model="form.email"
          placeholder=""
          pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
          autocomplete="false"
          v-validate="'required|email'"
          :state="validateState('email')"
        ></b-form-input>
        <b-form-invalid-feedback>
          Email wajib diisi menggunakan email yang valid.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Password (minimal 8 karakter):" label-for="password">
        <b-input-group>
          <b-form-input
            :type="showPassword ? 'text' : 'password'"
            id="password"
            name="password"
            v-model="form.password"
            placeholder=""
            v-validate="'required|min:8'"
            :state="validateState('password')"
            ref="password"
          ></b-form-input>
          <b-input-group-append @click="showPassword = !showPassword">
            <b-input-group-text class="hover-pointer rounded-right">
              <font-awesome-icon
                icon="eye-slash"
                v-if="showPassword"
              ></font-awesome-icon>
              <font-awesome-icon icon="eye" v-else></font-awesome-icon>
            </b-input-group-text>
          </b-input-group-append>
          <b-form-invalid-feedback>
            Password wajib diisi dengan minimal 8 karakter.
          </b-form-invalid-feedback>
        </b-input-group>
      </b-form-group>

      <b-form-group label="Konfirmasi Password" label-for="password-confirm">
        <b-input-group>
          <b-form-input
            :type="showConfirmPassword ? 'text' : 'password'"
            id="password-confirm"
            name="password-confirm"
            v-model="form.passwordConfirm"
            placeholder=""
            v-validate="'required|confirmed:password'"
            :state="validateState('password-confirm')"
            data-vv-as="password"
          ></b-form-input>
          <b-input-group-append
            @click="showConfirmPassword = !showConfirmPassword"
          >
            <b-input-group-text class="hover-pointer rounded-right">
              <font-awesome-icon
                icon="eye-slash"
                v-if="showConfirmPassword"
              ></font-awesome-icon>
              <font-awesome-icon icon="eye" v-else></font-awesome-icon>
            </b-input-group-text>
          </b-input-group-append>
          <b-form-invalid-feedback>
            Konfirmasi password tidak sama.
          </b-form-invalid-feedback>
        </b-input-group>
      </b-form-group>

      <b-form-group id="input-group-3" label="Role:" label-for="role">
        <b-form-select
          id="role"
          name="role"
          v-model="form.role"
          :options="roles"
          v-validate="'required'"
          :state="validateState('role')"
        ></b-form-select>
        <b-form-invalid-feedback>
          Role harus diisi
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group v-if="form.role == 'admindivisi' || form.role == 'courseleader'" label="Divisi:" label-for="role">
        <!-- :disabled="userInfo.status == '3' && form.role == 'courseleader' -->
        <v-select
          v-model="form.id_divisi"
          :options="divisiOptions"
          :disabled="userInfo.status == '3'"
          class="mb-3"
          label="nama_divisi"
          :reduce="e=> e.id"
        />
      </b-form-group>
      <b-button type="submit" variant="primary" class="float-right"
        ><b-spinner small v-if="loading"></b-spinner><span v-else>Submit</span>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  name: "AddUserModal",
  props: ["title", "role"],

  data() {
    return {
      realMail: "",
      divisiOptions:[],
      confirmSymbol:false,
      form: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        passwordConfirm: "",
        role: this.role,
        id_divisi: null
      },
      showPassword: false,
      showConfirmPassword: false,
      roles: [
        { value: "admin", text: "Admin System" },
        { value: "admindivisi", text: "Admin Divisi" },
        { value: "teacher", text: "Pengajar" },
        { value: "courseleader", text: "Course Leader (CL)" },
        { value: "student", text: "Peserta" },
      ],
      loading: false,
      toastCount: 0,
    };
  },
  computed: {
     userInfo() {
      return this.$store.state.userInfo;
    },
  },
  async mounted() {
    this.disableThingRole(this.role)
    try {
        const res = await this.axios.get(
            process.env.VUE_APP_API_URL + "/api/divisi/list"
        );
        if(res.status == 200) {
            this.divisiOptions = res.data.data;
        }
    } catch (errors) {
        console.error(errors);
    }
  },
  watch: {
    'form.email' (val) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
      let result = re.test(val);
      if(!result) {
        this.confirmSymbol = true;
      } else {
        this.realMail = val
        this.confirmSymbol = false;
      }
    },
    'form.role' (val) {
      this.disableThingRole(val)
    }
  },
  methods: {
    disableThingRole(val) {
      if(val == "courseleader" && this.userInfo.status == "3") {
        this.form.id_divisi = this.userInfo.hr_dept_id ? parseInt(this.userInfo.hr_dept_id) : null
      }
    },
    mailKeydown(val) {
      //eslint-disable-next-line
      const re = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      let result = re.test(val);
      if(result) {
        val.target.value = this.realMail
      }
    },
    async submitData() {
      try {
        // Trim inputs
        this.form.firstname = this.form.firstname.trim();
        this.form.lastname = this.form.lastname.trim();
        this.form.id_divisi = this.form.role == 'admindivisi' || this.form.role == 'courseleader' ? this.form.id_divisi : null

        const response = await this.axios.post(
          process.env.VUE_APP_API_URL + "/api/user/create",
          this.form
        );

        const data = response.data;
        const status = data.success ? "Success" : "Failed";
        const variant = data.success ? "success" : "danger";
        const message = data.message;

        this.loading = false;
        this.$bvModal.hide("addUserModal");

        // Show Toast
        this.toastCount++;
        this.$bvToast.toast(message, {
          title: status,
          autoHideDelay: 5000,
          variant: variant,
        });

        // Reset form if successful
        if (data.success) {
          this.form.firstname = "";
          this.form.lastname = "";
          this.form.email = "";
          this.form.password = "";
        }

        // Refresh table
        this.$emit("reload-table");
      } catch (errors) {
        console.error(errors);
      }
    },

    onSubmit() {
      this.loading = true;

      try {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            this.loading = false;

            return;
          }

          this.submitData();
        });
      } catch (errors) {
        console.log(errors);
      }
    },
  },

  resetForm() {},
};
</script>
