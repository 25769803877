<template>
  <b-container v-if="!isEvaluationGradeView">
    <div class="d-flex w-100 justify-content-between align-items-center mb-4">
      <div class="d-flex align-items-center">
        <h4 class="font-open-sans-bold mb-0 mr-2">Skema Penilaian</h4>
        <b-link
          v-b-tooltip.hover
          title="Mengenai Skema Penilaian"
          class="text-secondary mr-3"
          v-b-modal.assesssment-info-modal
        >
          <font-awesome-icon icon="question-circle"></font-awesome-icon>
        </b-link>
      </div>

      <div
        v-if="!loading && !assessmentNotSetup"
        class="d-flex align-items-center font-open-sans"
      >
        <b-link
          v-if="!loading"
          v-b-tooltip.hover
          title="Edit Informasi Penilaian"
          class="text-secondary mr-3"
          v-b-modal.setup-assessment-modal
        >
          <font-awesome-icon icon="edit"></font-awesome-icon>
        </b-link>
        <b-card class="shadow-sm mr-3 hover-pointer">
          <b-card-text class="d-flex align-items-center">
            Total Bobot:
            <span class="font-weight-bold ml-1">
              {{ assessment.weight }}
            </span>
          </b-card-text>
        </b-card>
        <b-card class="shadow-sm hover-pointer">
          <b-card-text class="d-flex align-items-center">
            Nilai Minimum Sertifikasi:
            <span class="font-weight-bold ml-1">
              {{ assessment.certificate_min_score }}
            </span>
          </b-card-text>
        </b-card>
      </div>
    </div>

    <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>

    <div v-else-if="assessmentNotSetup">
      <p>Harap setup penilaian terlebih dahulu.</p>
      <b-button
        variant="primary"
        class="font-open-sans"
        v-b-modal.setup-assessment-modal
        >Setup Penilaian</b-button
      >
    </div>

    <div v-else>
      <div v-if="!emptyResults">
        <b-alert :show="weightOver" variant="danger" class="mb-4">
          Jumlah bobot item penilaian lebih besar daripada total bobot yang
          ditentukan. Harap sesuaikan bobot penilaian.
        </b-alert>
        <b-alert :show="weightUnder" variant="danger" class="mb-4">
          Jumlah bobot item penilaian lebih kecil daripada total bobot yang
          ditentukan. Harap sesuaikan bobot penilaian.
        </b-alert>
      </div>

      <b-row class="w-100 justify-content-between" no-gutters>
        <!-- Filter / Search -->
        <b-col cols="5">
          <b-form-group class="mb-3">
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Cari Nama Penilaian"
              ></b-form-input>

              <b-input-group-append>
                <b-button variant="primary" @click="filter = ''">
                  <font-awesome-icon icon="search" class="text-white" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="5" class="text-right mb-3">
          <b-button
            size=""
            variant="primary"
            class="mr-2"
            v-b-modal.create-assessment-modal
          >
            <span class="font-weight-bold font-open-sans mr-2"
              >Tambah Item Penilaian
            </span>
            <font-awesome-icon icon="plus"></font-awesome-icon>
          </b-button>
          <b-button size="" variant="primary" class="" @click="getData">
            <font-awesome-icon icon="sync"></font-awesome-icon>
          </b-button>
        </b-col>
      </b-row>
      <!-- Table -->
      <div v-if="emptyResults" class="my-5">
        <p>Belum ada skema penilaian yang dibuat.</p>
      </div>
      <b-table
        v-else
        striped
        responsive
        :fields="fields"
        :items="assessmentItems"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        select-mode="single"
        selectable
        @row-selected="viewEvaluationGrades"
        class="font-roboto"
      >
        <!-- Format Assessment Type -->
        <template #cell(type)="{ item }">
          <b-badge :variant="badgeColor(item.type)">{{
            formatType(item.type)
          }}</b-badge>
        </template>

        <!-- Edit and Delete Buttons -->
        <template #cell(actions)="{ item }">
          <b-link
            v-b-tooltip.hover
            title="Edit Item Penilaian"
            class="text-secondary"
            @click="editAssessment(item)"
          >
            <font-awesome-icon icon="edit" class="mr-3" />
          </b-link>
          <b-link
            v-b-tooltip.hover
            title="Hapus Item Penilaian"
            class="text-secondary"
            @click="deleteAssessment(item)"
          >
            <font-awesome-icon icon="trash" class="" />
          </b-link>
        </template>
      </b-table>
      <b-pagination
        class=""
        align="right"
        v-model="currentPage"
        :total-rows="assessmentItems.length"
        :per-page="perPage"
      ></b-pagination>
      <div class="w-100 d-flex justify-content-end">
        <span class="px-3 py-2 border rounded shadow-sm">
          Jumlah Bobot:
          <span class="font-weight-bold">{{ computedWeight }}</span>
        </span>
      </div>
    </div>
    <SetupAssessmentModal
      :weight.sync="assessment.weight"
      :certificate_min_score.sync="assessment.certificate_min_score"
      @reload-assessments="getData"
    />
    <CreateAssessmentModal
      :learningpaths="learningpaths"
      :ujians="ujians"
      :tugass="tugass"
      :computedWeight="computedWeight"
      @reload-assessments="getData"
    />
    <EditAssessmentModal
      :assessmentItem="assessmentItem"
      :computedWeight="computedWeight"
      @reload-assessments="getData"
    />
    <DeleteAssessmentModal
      :assessmentItem="assessmentItem"
      :computedWeight="computedWeight"
      @reload-assessments="getData"
    />
    <AssessmentInfoModal />
  </b-container>
  <EvaluationGrades
    v-else
    :isEvaluationGradeView.sync="isEvaluationGradeView"
    :assessmentItem="assessmentItem"
  />
</template>

<script>
import SetupAssessmentModal from "@/components/courses/certificates/SetupAssessmentModal";
import CreateAssessmentModal from "@/components/courses/certificates/CreateAssessmentModal";
import EditAssessmentModal from "@/components/courses/certificates/EditAssessmentModal";
import DeleteAssessmentModal from "@/components/courses/certificates/DeleteAssessmentModal";
import AssessmentInfoModal from "@/components/courses/certificates/AssessmentInfoModal";
import EvaluationGrades from "@/components/courses/certificates/EvaluationGrades";

export default {
  components: {
    SetupAssessmentModal,
    CreateAssessmentModal,
    EditAssessmentModal,
    DeleteAssessmentModal,
    AssessmentInfoModal,
    EvaluationGrades,
  },

  data() {
    return {
      assessment: {},
      assessmentItem: {},
      assessmentItems: [],
      learningpaths: [],
      ujians: [],
      tugass: [],
      //computedWeight: 0,
      fields: [
        {
          key: "type",
          label: "Tipe",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama Penilaian",
          sortable: true,
        },
        {
          key: "weight",
          label: "Bobot",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      loading: false,
      emptyResults: false,
      assessmentNotSetup: false,
      isEvaluationGradeView: false,
      toastCount: 0,
    };
  },

  props: ["courseId"],

  computed: {
    rows() {
      return this.assessmentItems.length;
    },

    weightOver() {
      if (this.computedWeight > this.assessment.weight) return true;
      return false;
    },

    weightUnder() {
      if (this.computedWeight < this.assessment.weight) return true;
      return false;
    },

    computedWeight() {
      let computedWeight = 0;

      this.assessmentItems.forEach((item) => {
        computedWeight += Number(item.weight);
      });

      return computedWeight;
    },
  },

  mounted() {
    this.getData();
  },

  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    formatType(type) {
      return type == "E" ? "Klasikal" : "Link";
    },

    badgeColor(type) {
      return type == "E" ? "success" : "primary";
    },

    viewEvaluationGrades(items) {
      const item = items[0];

      if (item.type == "E") {
        this.assessmentItem = item;
        this.isEvaluationGradeView = true;
      }
    },

    editAssessment(item) {
      this.assessmentItem = item;
      this.$bvModal.show("edit-assessment-modal");
    },

    deleteAssessment(item) {
      this.assessmentItem = item;
      this.$bvModal.show("delete-assessment-modal");
    },

    getAssessments() {
      return this.axios.get(
        process.env.VUE_APP_API_URL + `/api/course/${this.courseId}/assesment`
      );
    },

    getLearningPaths() {
      this.learningpaths = [];

      const requestBody = {
        course_id: this.courseId,
        link_type: 4,
      };

      return this.axios.post(
        process.env.VUE_APP_API_URL + `/api/assesment/link/list`,
        requestBody
      );
    },

     getUjians() {
      this.ujians = [];

      const requestBody = {
        course_id: this.courseId,
        link_type: 1,
      };

      return this.axios.post(
        process.env.VUE_APP_API_URL + `/api/assesment/link/list`,
        requestBody
      );
    },

    getTugass() {
      this.tugass = [];

      const requestBody = {
        course_id: this.courseId,
        link_type: 3,
      };

      return this.axios.post(
        process.env.VUE_APP_API_URL + `/api/assesment/link/list`,
        requestBody
      );
    },

    getData() {
      this.loading = true;

      Promise.all([this.getAssessments(), this.getLearningPaths(), this.getUjians(), this.getTugass()])
        .then((results) => {
          const assessments = results[0].data;
          const learningpaths = results[1].data;
          const ujians = results[2].data;
          const tugass = results[3].data;

          if (assessments.success) {
            this.assessment = assessments.assesment;

            if (assessments.assesment.components.length) {
              this.assessmentItems = assessments.assesment.components;
              this.emptyResults = false;
            } else {
              this.emptyResults = true;
            }
          }

          if (learningpaths.success) {
            learningpaths.links.forEach((learningpath) => {
              this.learningpaths.push({
                value: Number(learningpath.id),
                text: learningpath.name,
              });
            });
          }
          if (ujians.success) {
            ujians.links.forEach((ujian) => {
              this.ujians.push({
                value: Number(ujian.id),
                text: ujian.name,
              });
            });
          }
          if (tugass.success) {
            tugass.links.forEach((tugas) => {
              this.tugass.push({
                value: Number(tugas.id),
                text: tugas.name,
              });
            });
          }

        })
        .catch((errors) => {
          this.assessmentNotSetup = true;
          console.error(errors);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
