<template>
  <b-modal
    id="createCategoryModal"
    title="Buat Kategori Pelatihan Baru"
    hide-footer
  >
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group label="Nama Kategori:" label-for="name">
        <b-form-input
          id="name"
          name="name"
          v-model="form.name"
          placeholder=""
          v-validate="'required'"
          :state="validateState('name')"
        ></b-form-input>
        <b-form-invalid-feedback>
          Nama kategori wajib diisi.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Kode Kategori:" label-for="code">
        <b-form-input
          id="code"
          name="code"
          v-model="form.code"
          placeholder=""
          v-validate="'required'"
          :state="validateState('code')"
        ></b-form-input>
        <b-form-invalid-feedback>
          Kode kategori wajib diisi.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-button type="submit" variant="primary" class="float-right"
        ><b-spinner small v-if="loading"></b-spinner><span v-else>Submit</span>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  name: "CreateCategoryModal",

  data() {
    return {
      form: {
        name: "",
        code: "",
      },
      loading: false,
      toastCount: 0,
      emits: ["refresh-categories"],
    };
  },

  methods: {
    onSubmit() {
      this.loading = true;

      try {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            this.loading = false;

            return;
          }

          this.submitData();
        });
      } catch (errors) {
        console.log(errors);
      }
    },

    async submitData() {
      try {
        const response = await this.axios.post(
          process.env.VUE_APP_API_URL + "/api/category/create",
          this.form
        );

        const data = response.data;
        const status = data.success ? "Success" : "Failed";
        const variant = data.success ? "success" : "danger";
        const message = data.message;

        this.loading = false;
        this.$bvModal.hide("createCategoryModal");

        // Show Toast
        this.toastCount++;
        this.$bvToast.toast(message, {
          title: status,
          autoHideDelay: 5000,
          variant: variant,
        });

        // Reset form if successful
        if (data.success) {
          this.form.title = "";
          this.form.lastname = "";
        }

        // Refresh courses list
        this.$emit("refresh-categories");
      } catch (errors) {
        console.error(errors);
      }
    },
  },

  resetForm() {},
};
</script>
