<template>
  <div>
    <BannerSmall
      title="Kategori Pelatihan"
      button="true"
      buttonText="Daftar Pelatihan"
      location="/courses"
    />
    <div
      class="bg-primary 
    "
    >
      <b-container class="py-5">
        <b-button
          variant="light"
          size="lg"
          class="text-center font-open-sans font-weight-bold mb-5"
          v-b-modal.createCategoryModal
        >
          Buat Kategori Baru
          <font-awesome-icon icon="plus" class="ml-2"></font-awesome-icon>
        </b-button>

        <b-spinner
          v-if="loading"
          label="Spinning"
          class="d-block mx-auto text-white"
        ></b-spinner>

        <div v-else-if="emptyResults">
          Belum ada pelatihan pada portal.
        </div>

        <b-row v-else align-v="stretch">
          <b-col
            cols="6"
            sm="6"
            md="4"
            lg="3"
            v-for="category in categories"
            :key="category.id"
            class="mb-5"
          >
            <b-card
              :img-src="require('@/assets/img/lppi-simple-thumbnail.png')"
              img-alt="Image"
              img-top
              tag="article"
              style="max-width: 17rem;"
              class="h-100 font-open-sans position-relative"
            >
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                right
                class="position-absolute"
                style="top: 10px; right: 10px"
              >
                <template #button-content>
                  <font-awesome-icon icon="ellipsis-v"></font-awesome-icon>
                </template>
                <b-dropdown-item @click="editCategory(category)">
                  Edit Kategori
                </b-dropdown-item>
                <b-dropdown-item @click="deleteCategory(category)">
                  Hapus Kategori
                </b-dropdown-item>
              </b-dropdown>
              <b-card-text class="font-weight-bold">
                <p class="mb-0">{{ category.name }}</p>
              </b-card-text>
              <h5>
                <b-badge class="">{{ category.code }}</b-badge>
              </h5>
              <template #footer>
                <b-button variant="primary" @click="viewCourses(category)">
                  Lihat Kelas
                </b-button>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <createCategoryModal @refresh-categories="getCategories" />
    <editCategoryModal
      :categoryData="categoryData"
      @refresh-categories="getCategories"
    />
    <deleteCategoryModal
      :categoryData="categoryData"
      @refresh-categories="getCategories"
    />
  </div>
</template>
<script>
import BannerSmall from "@/components/BannerSmall.vue";
import EditCategoryModal from "@/components/courses/categories/EditCategoryModal.vue";
import DeleteCategoryModal from "@/components/courses/categories/DeleteCategoryModal.vue";
import CreateCategoryModal from "@/components/courses/categories/CreateCategoryModal.vue";

export default {
  components: {
    BannerSmall,
    CreateCategoryModal,
    EditCategoryModal,
    DeleteCategoryModal,
  },

  data() {
    return {
      categories: this.$store.state.courseCategories,
      categoryData: {},
      loading: false,
      emptyResults: false,
    };
  },

  mounted() {
    this.getCategories();
  },

  methods: {
    editCategory(category) {
      this.categoryData = category;
      this.$bvModal.show("editCategoryModal");
    },

    deleteCategory(category) {
      this.categoryData = category;
      this.$bvModal.show("deleteCategoryModal");
    },

    async viewCourses(category) {
      this.$router.push({
        name: "CoursesByCategory",
        params: {
          id: category.id,
        },
      });
    },

    async getCategories() {
      try {
        this.loading = true;

        const response = await this.axios.get(
          process.env.VUE_APP_API_URL + "/api/categories"
        );

        this.categories = response.data.categories;
        this.$store.commit("setCourseCategories", response.data.categories);

        this.loading = false;
      } catch (errors) {
        this.loading = false;
        console.error(errors);
      }
    },
  },
};
</script>
