<template lang="">
  <b-container>
    <div class="d-flex w-100 justify-content-between align-items-center mb-5">
      <div class="d-flex align-items-center">
        <h4 class="font-open-sans-bold mb-0 mr-2">Daftar Keterampilan</h4>
      </div>
      <b-button v-if="!loading" variant="primary" v-b-modal.add-skill-modal>
        Tambah Keterampilan
      </b-button>
    </div>

    <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>

    <div v-else-if="emptyResults">
      <p>Belum ada keterampilan yang ditambahkan.</p>
    </div>

    <div v-else>
      <b-row align-v="stretch">
        <b-col
          cols="4"
          v-for="(skill, index) in skills"
          :key="index"
          class="mb-5"
        >
          <SkillCard
            :skill="skill"
            disable-edit="true"
            @show-skill="showSkill"
            @delete-skill="removeSkill"
          />
        </b-col>
      </b-row>
    </div>

    <SkillModal :skill="selectedSkill" />
    <AddSkillModal
      :gradebook-id="assessment.id"
      :unlisted-skills="unlistedSkills"
      @reload-skills="getSkills(assessment.id)"
    />
    <RemoveSkillModal
      :gradebook-id="assessment.id"
      :selected-skill="selectedSkill"
      @reload-skills="getSkills(assessment.id)"
    />
  </b-container>
</template>
<script>
import SkillCard from "@/components/skills/SkillCard.vue";
import SkillModal from "@/components/skills/SkillModal.vue";
import AddSkillModal from "@/components/courses/certificates/AddSkillModal.vue";
import RemoveSkillModal from "@/components/courses/certificates/RemoveSkillModal.vue";

export default {
  components: { SkillCard, SkillModal, AddSkillModal, RemoveSkillModal },

  props: ["courseId"],

  data() {
    return {
      assessment: {},
      skills: [],
      unlistedSkills: [],
      selectedSkill: {},
      loading: false,
      emptyResults: false,
    };
  },

  mounted() {
    this.getAssessmentInfo();
  },

  methods: {
    getListedSkills(requestBody) {
      this.skills = [];

      return this.axios.post(
        process.env.VUE_APP_API_URL + "/api/skills/gradebookviewskill",
        requestBody
      );
    },

    getUnlistedSkills(requestBody) {
      this.unlistedSkills = [];

      return this.axios.post(
        process.env.VUE_APP_API_URL + "/api/skills/gradebook/unlisted",
        requestBody
      );
    },

    showSkill(skill) {
      this.selectedSkill = skill;
      this.$bvModal.show("skill-modal");
    },

    removeSkill(skill) {
      this.selectedSkill = skill;
      this.$bvModal.show("remove-skill-modal");
    },

    async getAssessmentInfo() {
      this.loading = true;

      try {
        const response = await this.axios.get(
          process.env.VUE_APP_API_URL + `/api/course/${this.courseId}/assesment`
        );

        const data = response.data;

        if (data.success) {
          this.emptyResults = false;
          this.assessment = data.assesment;

          this.getSkills(data.assesment.id);
        } else {
          this.emptyResults = true;
          this.loading = false;
        }
      } catch (errors) {
        this.emptyResults = true;
        this.loading = false;
        console.error(errors);
      }
    },

    async getSkills(assessmentId) {
      this.loading = true;

      const requestBody = {
        gradebook_id: Number(assessmentId),
      };

      Promise.all([
        this.getListedSkills(requestBody),
        this.getUnlistedSkills(requestBody),
      ])
        .then((results) => {
          const skills = results[0].data;
          const unlistedSkills = results[1].data;

          if (skills.length) {
            skills.forEach((skill) => {
              this.skills.push(skill.skill[0]);
            });
            this.emptyResults = false;
          } else {
            this.emptyResults = true;
          }

          if (unlistedSkills.success) {
            unlistedSkills.skills.forEach((skill) => {
              this.unlistedSkills.push({
                code: skill.id,
                label: skill.name,
              });
            });
          }
        })
        .catch((errors) => console.error(errors))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
