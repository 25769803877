<template>
    <div style="min-height:400px; height:auto;">
        <b-modal id="addUserTestimoni" :title="'Add Divisi'" hide-footer hide-header-close>
            <b-form @submit.stop.prevent="onSubmit">
                <b-form-group label="Nama Divisi:" label-for="namaDivisi">
                    <b-form-input
                    id="namaDivisi"
                    name="namaDivisi"
                    v-model="form.nama_divisi"
                    placeholder=""
                    v-validate="'required'"
                    :state="validateState('namaDivisi')"
                    ></b-form-input>
                    <b-form-invalid-feedback>Nama divisi wajib diisi.</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="Deskripsi:" label-for="deskripsi">
                    <b-form-textarea
                        id="deskripsi"
                        name="deskripsi"
                        v-model="form.deskripsi"
                        placeholder="isi deskripsi"
                        rows="3"
                        max-rows="6"
                        v-validate="'required'"
                        :state="validateState('deskripsi')"
                    ></b-form-textarea>
                    <b-form-invalid-feedback>Deskripsi wajib diisi.</b-form-invalid-feedback>
                </b-form-group>
                <b-button type="submit" variant="primary" class="float-right"
                    ><b-spinner small v-if="loading"></b-spinner><span v-else>Submit</span>
                </b-button>
                <b-button type="submit" class="float-right mr-3" @click="closeAll">Close</b-button>
            </b-form>
        </b-modal>
        <h4 class="mt-2 mb-5">Management Divisi</h4>
        <b-row>
            <div class="col-2">
                <b-form-select
                id="per-page-select"
                v-model="perPage"
                @change="toTheStudentPage"
                :options="pageOptions"
                ></b-form-select>
            </div>
            <b-col cols="12" md="6" lg="6">
                <b-form-group class="mb-3">
                <b-input-group>
                    <b-form-input
                    v-model="search"
                    type="search"
                    @input="debounceInput"
                    placeholder="Cari.."
                    ></b-form-input>
                </b-input-group>
                </b-form-group>
            </b-col>
            <!-- Add Student -->
            <b-col cols="12" md="4" lg="4" class="text-right mb-3">
                <b-button
                    size=""
                    variant="primary"
                    v-b-modal.addUserTestimoni
                >
                    <span class="font-weight-bold font-open-sans mr-2"
                    >Tambah
                    </span>
                    <font-awesome-icon icon="plus"></font-awesome-icon>
                </b-button>
            </b-col>
        </b-row>
        <b-table
            striped
            responsive
            :fields="fields"
            :items="divisi"
            :per-page="perPage"
            :current-page="totalRows ? 1 : currentPage"
            class="font-roboto"
        >
            <template #cell(url_imgTestimoni)="row">
               <img :src="row.item.url_imgTestimoni" alt="" width="200">
            </template>
             <template #cell(aktif)="row">
                <b-form-checkbox v-model="checked[row.item.id]" name="check-button" switch></b-form-checkbox>
            </template>


            <!-- Edit and Delete Buttons -->
            <template #cell(actions)="row">
            <b-link
                v-b-tooltip.hover
                title="Edit Divisi"
                class="text-secondary"
                @click="editDivisi(row.item)"
            >
                <font-awesome-icon icon="edit" class="mr-3" />
            </b-link>
            <b-link
                v-b-tooltip.hover
                title="Delete Divisi"
                class="text-secondary"
                @click="deleteDivisi(row.item)"
            >
                <font-awesome-icon icon="trash" class="" />
            </b-link>
            </template>
        </b-table>
        <b-row class="justify-content-between">
            <b-col cols="4"></b-col>
            <b-col cols="4">
                <b-pagination
                    class="ml-auto"
                    align="right"
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    @page-click="toTheCourse"
                    aria-controls="my-table"
                ></b-pagination>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import _ from "lodash"
export default {
    name: "Divisi",
    data() {
        return {
            loading:false,
            filterOn: [],
            fields: [
                {
                    key: "no",
                    label: "No",
                },
                {
                    key: "nama_divisi",
                    label: "Nama Divisi",
                },
                {
                    key: "deskripsi",
                    label: "Deskripsi",
                },
                {
                    key: "created_at",
                    label: "Dibuat",
                },
                {
                    key: "actions",
                    label: "",
                },
            ],
            editedId: null,
            divisi: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 25, 50, 100],
            filter: null,
            form: {
                nama_divisi: "",
                deskripsi: "",
            },
            search: ""
        }
    },
    mounted() {
        this.initData();
    },
    methods: {
        toTheStudentPage(e) {
            this.perPage = e;
            this.initData()
        },
        debounceInput: _.debounce(function () {
            this.initData()
        }, 500),
        toTheCourse(e, page) {
            this.currentPage = page;
            this.initData()
        },
        closeAll() {
            this.$bvModal.hide("addUserTestimoni");
            this.form.nama_divisi = "";
            this.form.deskripsi = "";
        },
        async initData() {
            try {
                const res = await this.axios.get(
                    `${process.env.VUE_APP_API_URL}/api/divisi/list/ssp?page=${this.currentPage}&pageLength=${this.perPage}&search=${this.search}`
                );
                if(res.status == 200) {
                    this.divisi = res.data.data;
                    this.totalRows = res.data.total;
                    res.data.data.forEach((e,idx) => {
                        e.no = idx+1
                    });
                }
            } catch (errors) {
                console.error(errors);
            }
        },
        async deleteDivisi(item) {
             try {
                const res = await this.axios.delete(
                    process.env.VUE_APP_API_URL + `/api/divisi/delete/${item.id}`
                );
                if(res.status == 200) {
                    const status = res.data.success ? "Success" : "Failed";
                    const variant = res.data.success ? "success" : "danger";
                    const message = "Berhasil menghapus divisi";
                    this.$bvToast.toast(message, {
                        title: status,
                        autoHideDelay: 5000,
                        variant: variant,
                    });
                    this.initData();
                }
            } catch (errors) {
                console.error(errors);
            }
            console.log(item)
        },
        editDivisi(item) {
            this.editedId = item.id
            this.$bvModal.show("addUserTestimoni");
            this.form.nama_divisi = item.nama_divisi
            this.form.deskripsi = item.deskripsi
        },
        onSubmit() {
            try {
            this.$validator.validateAll().then((result) => {
                if (!result) {
                    this.loading = false;
                    return;
                }
                this.submitData();
            });
            } catch (errors) {
                console.log(errors);
            }
        },
        async submitData() {
            this.loading = true
            try {
                let res = null
                const payload = {
                    nama_divisi: this.form.nama_divisi,
                    deskripsi: this.form.deskripsi
                }
                if(this.editedId) {
                    payload.id_divisi = this.editedId
                    res = await this.axios.post(
                        process.env.VUE_APP_API_URL + "/api/divisi/edit",
                        payload
                    );
                } else {
                    res = await this.axios.post(
                        process.env.VUE_APP_API_URL + "/api/divisi/add",
                        payload
                    );
                }
                if(res.status == 200) {
                    const data = res.data;
                    const status = data.success ? "Success" : "Failed";
                    const variant = data.success ? "success" : "danger";
                    const message = data.message;
                    this.$bvToast.toast(message, {
                        title: status,
                        autoHideDelay: 5000,
                        variant: variant,
                    });
                    this.closeAll()
                    this.initData()
                }
            } catch (error) {
                console.log(error)
            }
            this.loading = false;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
    }
}
</script>